import React from 'react';

interface Props {
    size?: number;
}

const GabelEinsLogo = ({size = 1}: Props) => {
    return (
        <svg height={42 * size} width={133 * size} viewBox={'0 0 133 42'} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.7143 9.53872C16.7143 9.17788 16.4218 8.88537 16.061 8.88537C15.7001 8.88537 15.4076
             9.17788 15.4076 9.53872L15.4076 22.6053C15.4076 22.9663 15.115 23.2589 14.7541 23.2589C14.3931 23.2589 14.1005 22.9663 14.1005
             22.6053L14.1005 9.53872C14.1005 9.17788 13.808 8.88537 13.4472 8.88537C13.0864 8.88537 12.7938 9.17788 12.7938 9.53872L12.7938
             22.6055C12.7938 22.9664 12.5013 23.2589 12.1405 23.2589C11.7797 23.2589 11.4872 22.9664 11.4872 22.6055L11.6385 9.5389C11.6385 9.17806
             11.346 8.88555 10.9852 8.88555C10.6243 8.88555 10.3318 9.17806 10.3318 9.5389L9.52717 24.5747L9.5273 25.2781C9.5273 26.09 9.81193
             26.8761 10.3317 27.4998L12.6208 29.4536C13.1541 30.0936 13.4398 30.8829 13.4251 31.7158C13.3958 33.3792 13.3402 36.537 13.2738
             40.3506C5.73543 37.1631 0.445801 29.6993 0.445801 21C0.445801 9.40202 9.84782 0 21.4458 0C22.6963 0 23.9213 0.109303 25.1117
             0.318878C25.3141 5.14746 25.491 9.28087 25.5428 10.4894C25.5532 10.7319 25.5381 10.967 25.4982 11.2064L25.0011 14.1888C24.9694 14.3787
             24.9535 14.5709 24.9535 14.7634L24.9534 16.2879L24.9532 33.3506C24.9532 35.281 26.6414 36.9203 28.2572 35.8641C29.9085 34.7847 30.9996
             32.9194 30.9996 30.7994L31 18.1018V17.005C31 16.9822 30.9998 16.9594 30.9996 16.9366L30.9804 16.3686C30.9804 16.4494 30.9623 16.2805
             30.9613 16.2707C30.8165 14.9002 30.269 13.597 29.3797 12.5299L28.8142 11.8512C28.2742 11.2032 27.9866 10.3895 28.0046 9.54618C28.0405
             7.86582 28.1075 4.72247 28.1835 1.10419C36.4766 3.91167 42.4458 11.7587 42.4458 21C42.4458 32.598 33.0438 42 21.4458 42C19.7225 42
             18.0477 41.7924 16.445 41.4009C16.297 37.1479 16.1713 33.5758 16.107 31.751C16.0769 30.8974 16.3632 30.0829 16.91 29.4267L19.1766
             27.5806C19.6964 26.9569 19.981 26.1708 19.981 25.3589L19.981 23.3486L19.1766 9.53872C19.1766 9.17788 18.8841 8.88537 18.5233
             8.88537C18.1624 8.88537 17.8699 9.17788 17.8699 9.53872L18.0209 22.6056C18.0209 22.9664 17.7284 23.2589 17.3676 23.2589C17.0068
             23.2589 16.7143 22.9664 16.7143 22.6056L16.7143 9.53872Z" fill="#75A140"/>
            <path d="M59.8758 22.9264H64.012V28.1108C63.6342 28.2241 63.1998 28.3185 62.7088 28.394C62.2178 28.4696 61.6512 28.5074 61.009
             28.5074C59.8192 28.5074 58.7521 28.2146 57.8078 27.6291C56.8634 27.0437 56.1269 26.2032 55.598 25.1078C55.0692 23.9935 54.8048 22.7092
             54.8048 21.255C54.8048 20.0273 54.9748 18.9508 55.3147 18.0254C55.6736 17.0999 56.1646 16.335 56.7879 15.7307C57.43 15.1074 58.1572
             14.6353 58.9693 14.3142C59.7814 13.9931 60.6313 13.8326 61.519 13.8326C62.1233 13.8326 62.6238 13.8704 63.0204 13.9459C63.4359 14.0026
             63.842 14.0875 64.2386 14.2009C64.6352 14.3142 65.0885 14.4558 65.5984 14.6258L66.59 12.3594C66.08 12.0573 65.4946 11.8212 64.8335
             11.6512C64.1914 11.4623 63.5681 11.3301 62.9638 11.2546C62.3594 11.179 61.8589 11.1413 61.4623 11.1413C60.0269 11.1413 58.7049 11.3868
             57.4961 11.8778C56.2874 12.3689 55.2298 13.0771 54.3232 14.0026C53.4167 14.9091 52.7179 15.9762 52.2268 17.2038C51.7358 18.4314 51.4902
             19.7913 51.4902 21.2833C51.4902 23.1342 51.868 24.8151 52.6234 26.326C53.3978 27.818 54.5026 29.0079 55.938 29.8955C57.3923 30.7643
             59.1109 31.1987 61.094 31.1987C61.8306 31.1987 62.5483 31.1326 63.2471 31.0004C63.9647 30.8871 64.6352 30.7265 65.2585 30.5188C65.8817
             30.2921 66.42 30.0561 66.8733 29.8105V20.4901H60.1308L59.8758 22.9264Z" fill="#132C43"/>
            <path d="M72.8205 26.7509C72.8205 26.2032 73.0188 25.7027 73.4154 25.2495C73.8121 24.7962 74.3692 24.5695 75.0869
             24.5695H78.1748V27.0342C77.6838 27.5253 77.155 27.9124 76.5884 28.1957C76.0218 28.479 75.4363 28.6207 74.8319 28.6207C74.4164 28.6207
             74.0576 28.5357 73.7554 28.3657C73.4532 28.1769 73.2171 27.9408 73.0471 27.6575C72.8961 27.3742 72.8205 27.072 72.8205 26.7509ZM71.659
             20.5184C72.1878 20.2918 72.8016 20.084 73.5004 19.8951C74.2181 19.6874 74.9358 19.5835 75.6535 19.5835C76.5411 19.5835 77.1738 19.8479
             77.5516 20.3767C77.9293 20.8867 78.1276 21.5477 78.1465 22.3598V22.4448H75.1435C73.6326 22.4637 72.405 22.8414 71.4607 23.578C70.5164
             24.3146 70.0442 25.3722 70.0442 26.7509C70.0442 28.0541 70.4408 29.1117 71.234 29.9239C72.0273 30.736 73.2455 31.142 74.8886
             31.142C75.6063 31.142 76.3051 30.9721 76.985 30.6321C77.6649 30.2921 78.2504 29.8861 78.7414 29.4139L79.223
             30.8021H81.3761V21.6232C81.3761 20.6978 81.1117 19.8574 80.5829 19.1019C80.0729 18.3464 79.3836 17.761 78.5148 17.3455C77.646 16.9111
             76.6734 16.6939 75.5968 16.6939C75.2191 16.6939 74.7186 16.7505 74.0953 16.8639C73.4721 16.9583 72.8583 17.0999 72.2539 17.2888C71.6496
             17.4588 71.1679 17.6665 70.8091 17.9121L71.659 20.5184Z" fill="#132C43"/>
            <path d="M87.0434 30.8021L88.5165 28.734V10.8013L85.3719 11.1129V30.8021H87.0434ZM87.3833 21.3683C88.0066 20.7073 88.6015 20.2162 89.1681
             19.8951C89.7536 19.5741 90.424 19.4135 91.1795 19.4135C91.9727 19.4135 92.6432 19.6118 93.1909 20.0085C93.7386 20.3862 94.1447 20.915
             94.4091 21.5949C94.6924 22.2748 94.834 23.0681 94.834 23.9746C94.834 24.8056 94.6924 25.5611 94.4091 26.241C94.1258 26.9209 93.7103
             27.4592 93.1626 27.8558C92.6149 28.2335 91.9444 28.4224 91.1512 28.4224C90.4335 28.4224 89.7724 28.2807 89.1681 27.9974C88.5826 27.6953
             87.9782 27.1759 87.355 26.4393L86.4201 28.649C86.7789 29.159 87.2322 29.6028 87.7799 29.9805C88.3465 30.3582 88.932 30.651 89.5364
             30.8587C90.1596 31.0665 90.6979 31.1704 91.1512 31.1704C92.6054 31.1704 93.8425 30.8682 94.8624 30.2638C95.9011 29.6594 96.7038 28.819
             97.2704 27.7425C97.837 26.647 98.1203 25.3817 98.1203 23.9463C98.1203 22.4731 97.837 21.1983 97.2704 20.1218C96.7227 19.0264 95.9294
             18.1859 94.8907 17.6004C93.8708 16.9961 92.6526 16.6939 91.2361 16.6939C90.7262 16.6939 90.1785 16.7883 89.593 16.9772C89.0264 17.166
             88.4693 17.4493 87.9216 17.8271C87.3739 18.1859 86.8923 18.6392 86.4768 19.1869L87.3833 21.3683Z" fill="#132C43"/>
            <path d="M113.104 24.7678V24.0879C113.104 22.747 112.887 21.5194 112.453 20.4051C112.037 19.2908 111.376 18.3937 110.47 17.7137C109.563
             17.0338 108.402 16.6939 106.985 16.6939C105.588 16.6939 104.379 16.9961 103.359 17.6004C102.358 18.2048 101.584 19.0547 101.036
             20.1501C100.507 21.2266 100.243 22.492 100.243 23.9463C100.243 25.3817 100.526 26.647 101.093 27.7425C101.659 28.819 102.519 29.6594
             103.671 30.2638C104.823 30.8493 106.239 31.142 107.92 31.142C108.638 31.142 109.299 31.0759 109.903 30.9437C110.508 30.8115 111.065
             30.6227 111.575 30.3771C112.085 30.1127 112.557 29.8389 112.991 29.5556L112.028 27.2325C111.348 27.5914 110.696 27.8841 110.073
             28.1108C109.469 28.3374 108.77 28.4507 107.977 28.4507C106.995 28.4507 106.192 28.2996 105.569 27.9974C104.964 27.6764 104.502 27.242
             104.181 26.6943C103.878 26.1466 103.68 25.5044 103.586 24.7678H113.104ZM103.642 22.6148C103.737 21.9349 103.916 21.3588 104.181
             20.8867C104.464 20.4145 104.832 20.0557 105.285 19.8101C105.758 19.5457 106.286 19.4135 106.872 19.4135C107.476 19.4135 107.986 19.5457
             108.402 19.8101C108.836 20.0557 109.186 20.4145 109.45 20.8867C109.733 21.3588 109.932 21.9349 110.045 22.6148H103.642Z" fill="#132C43"/>
            <path d="M119.501 30.8021V10.8013L116.356 11.1129V30.8021H119.501Z" fill="#132C43"/>
            <path d="M132.655 30.8021V14.7674H130.53L125.062 17.3171L126.366 19.7251L129.454 18.3087V30.8021H132.655Z" fill="#75A140"/>
        </svg>
    );
};

export default GabelEinsLogo;
