import React, {MouseEventHandler, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CheckIcon from '../icons/CheckIcon';
import DeleteIcon from '../icons/DeleteIcon';
import Icon from '../icons/Icon';
import '../style/EditModal.scss';
import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';

interface Props {
    visible?: boolean,
    header?: string,
    children?: ReactNode,
    onConfirm?: MouseEventHandler<HTMLButtonElement>,
    onDelete?: () => void,
    onCancel?: MouseEventHandler<HTMLButtonElement>,
    fixedWidth?: string | number,
    fixedHeight?: string | number,
    scrollable?: boolean,
    confirmLabel?: string,
    deleteLabel?: string,
    className?: string
}

function EditModal(props: Readonly<Props>) {
    const [t] = useTranslation();
    const [isDeletionRequested, setIsDeletionRequested] = useState(false);
    const scrollable = props.scrollable === undefined ? true : props.scrollable;

    function getStyling() {
        let styling = {};
        if (props.fixedWidth) {
            styling = {...styling, width: props.fixedWidth};
        }
        if (props.fixedHeight) {
            styling = {...styling, height: props.fixedHeight};
        }
        return styling;
    }

    return (
        <div className={props.visible ? 'edit-modal-background' : 'd-none'}>
            <div className={` ${props.className ?? ''} edit-modal${scrollable ? '-scrollable' : ''}`}
                 style={getStyling()}>
                <div className="card" style={{height: '100%'}}>
                    <div className="card-header">{props.header}</div>
                    <div className="card-body">
                        {props.children}
                        <div className="row mt-3">
                            <div className="col-2">
                                {
                                    props.onDelete &&
                                    <Icon
                                        src={<DeleteIcon/>}
                                        className="btn btn-secondary"
                                        onClick={() => setIsDeletionRequested(true)}
                                        title={props.deleteLabel ? props.deleteLabel : t('Button.DELETE')}
                                    />
                                }
                            </div>
                            <div className="col-10 text-right">
                                {
                                    props.onCancel &&
                                    <CancelButton onClick={props.onCancel}/>
                                }
                                <ConfirmButton
                                    onClick={(event) => props.onConfirm ? props.onConfirm(event) : undefined}
                                    disabled={!props.onConfirm}
                                    label={props.confirmLabel ?? t('Button.APPLY')}
                                />
                            </div>
                        </div>
                        {
                            isDeletionRequested &&
                            <div>
                                {t('Common.CONFIRM_DELETE')}
                                <Icon
                                    src={<CheckIcon/>}
                                    className="btn btn-secondary ml-3"
                                    onClick={props.onDelete}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditModal;
