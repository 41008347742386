import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Salutation from '../enums/Salutation';
import '../style/TextOutput.scss';
import Dropdown from './form/Dropdown';
import TextInput from './form/TextInput';

function Address({
                     address,
                     handleChange,
                     validation,
                     readOnly = false,
                     id,
                     withSalutation = false,
                     withAdditionalAddressInformation = true,
                     withName = false,
                     withCountry = false,
                     optionals = {
                         salutation: true,
                         name: false,
                         additionalAddressInformation: true,
                         street: false,
                         houseNumber: false,
                         zip: false,
                         city: false,
                         country: false
                     }
                 }) {
    const [t] = useTranslation();
    const [selectedSalutation, setSelectedSalutation] = useState(null);
    const addressId = id ? `-${id}` : '';
    const defaultSalutations = [Salutation.MR, Salutation.MRS, Salutation.COMPANY];
    const salutationOptions = defaultSalutations.map(type => ({
        label: t(`Salutation.${type}`),
        value: type
    }));

    useEffect(() => {
        if (address?.salutation && (!selectedSalutation || !selectedSalutation?.value)) {
            setSelectedSalutation({value: address.salutation});
        }
    }, [address]);

    function onChange(field, value) {
        const newAddress = {
            ...address,
            [field]: value
        };
        if (handleChange) {
            handleChange(newAddress);
        }
    }

    function handleSalutationChange(value) {
        setSelectedSalutation(value);
        onChange('salutation', value ? value.value : null);
    }

    return (
        <>
            <div className="row">
                {
                    withSalutation &&
                    <div className={`col-12 ${withName ? 'col-lg-3' : ''}`}>
                        <Dropdown
                            id={`salutation${addressId}`}
                            label={t('User.SALUTATION')}
                            options={salutationOptions}
                            value={selectedSalutation}
                            onChange={handleSalutationChange}
                            required={!optionals.salutation}
                            isValid={validation.salutation}
                            isDisabled={readOnly}
                            isClearable={optionals.salutation}
                        />
                    </div>
                }
                {
                    withName &&
                    <div className={`col-12 ${withSalutation ? 'col-lg-9' : ''}`}>
                        <TextInput
                            id={`name${addressId}`}
                            label={t('Address.NAME')}
                            value={address.name}
                            onChange={value => onChange('name', value)}
                            required={!optionals.name}
                            isValid={validation.name}
                            readonly={readOnly}
                        />
                    </div>
                }
                {
                    withAdditionalAddressInformation &&
                    <div className="col-12">
                        <TextInput
                            id={`addition-information${addressId}`}
                            label={t('Address.ADDITION_INFORMATION')}
                            value={address.additionalAddressInformation}
                            onChange={value => onChange('additionalAddressInformation', value)}
                            required={!optionals.additionalAddressInformation}
                            isValid={validation.additionalAddressInformation}
                            readonly={readOnly}
                        />
                    </div>
                }

                <div className="col-12 col-md-8">
                    <TextInput
                        id={`street${addressId}`}
                        label={t('Address.STREET')}
                        value={address.street}
                        onChange={value => onChange('street', value)}
                        required={!optionals.street}
                        isValid={validation.street}
                        readonly={readOnly}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <TextInput
                        id={`house-number${addressId}`}
                        label={t('Address.HOUSE_NUMBER')}
                        value={address.houseNumber}
                        onChange={value => onChange('houseNumber', value)}
                        required={!optionals.houseNumber}
                        isValid={validation.houseNumber}
                        readonly={readOnly}
                    />
                </div>
            </div>
            <div className="row">
                <div className={`col-12 ${withCountry ? 'col-md-3' : 'col-md-4'}`}>
                    <TextInput
                        id={`zip${addressId}`}
                        label={t('Address.ZIP')}
                        value={address.zip}
                        onChange={value => onChange('zip', value)}
                        required={!optionals.zip}
                        isValid={validation.zip}
                        readonly={readOnly}
                    />
                </div>
                <div className={`col-12 ${withCountry ? 'col-md-5' : 'col-md-8'}`}>
                    <TextInput
                        id={`city${addressId}`}
                        label={t('Address.CITY')}
                        value={address.city}
                        onChange={value => onChange('city', value)}
                        required={!optionals.city}
                        isValid={validation.city}
                        readonly={readOnly}
                    />
                </div>
                {
                    withCountry &&
                    <div className="col-12 col-md-4">
                        <TextInput
                            id={`country${addressId}`}
                            label={t('Address.COUNTRY')}
                            value={address.country}
                            onChange={value => onChange('country', value)}
                            required={!optionals.country}
                            isValid={validation.country}
                            readonly={readOnly}
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default Address;
