const InstitutionType = {
    DAY_NURSERY: 'DAY_NURSERY',
    AFTER_SCHOOL_CARE_CENTER: 'AFTER_SCHOOL_CARE_CENTER',
    KINDERGARTEN: 'KINDERGARTEN',
    LUNCHTIME_CARE: 'LUNCHTIME_CARE',
    DAY_CARE_CENTER: 'DAY_CARE_CENTER',
    SENIORS: 'SENIORS',
    OTHER: 'OTHER'
};

export default InstitutionType;
