import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BackTo from '../../common/component/BackTo';
import BillIcon from '../../common/icons/BillIcon';
import {DefaultState} from '../../common/reducer/reducers';
import {TransactionFilter} from '../../types/filter/TransactionFilter';
import {searchBills} from '../actions/financeActions';
import AccountBillList from './AccountBillList';

function ParentBillOverview() {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const bills = useSelector((state: DefaultState) => state.finance.bills);
    const catererShortName = useSelector((state: DefaultState) => state.caterer.companyShortName);
    const accountName = useSelector((state: DefaultState) => state.user.account?.person?.lastName);

    useEffect(() => {
        dispatch(searchBills(null, {} as TransactionFilter));
    }, [dispatch]);

    return <div className="container">
        <div className="center-screen">
            <BackTo path="/finances" labelKey="Finance.BACK"/>
            <h3 className="d-flex mt-4 mb-3">
                <span><BillIcon size={1.5}/></span>
                <span className="mt-1 ml-3">{t('Finance.BILLS')}</span>
            </h3>
            <AccountBillList accountName={accountName ?? ''} bills={bills} catererName={catererShortName ?? ''}/>
        </div>
    </div>;
}

export default ParentBillOverview;
