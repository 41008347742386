import {TFunction} from 'i18next';
import {formatDate} from '../../common/util/DateUtil';
import {Payment} from '../../types/payment/PaymentType';

export function formatPendingPayment(payment: Payment, t: TFunction, currentLanguage: any, currencyFormatter: Intl.NumberFormat): string {
    const formattedAmount = currencyFormatter.format(payment.amount);
    const formattedDate = formatDate(currentLanguage, payment.createdAt);
    return `${formattedAmount} ${t('Common.VIA')} ${t('Credit.PAYMENT_METHOD_TYPE_' + payment.paymentMethodType.toUpperCase())} `
        + `${t('Common.OF_DATE')} ${formattedDate}`;
}
