import {httpClient} from '../../common/api/HttpClient';
import {convertDateToIsoDateString} from '../../common/util/DateUtil';

export const transactionApi = {
    createTransaction: (transaction) => httpClient.post('transactions', transaction),
    doOrdersExist: (institutionId, mealdate, checkExactDate = true) => httpClient.get(`transactions/orders/exist?institutionId=${institutionId}&mealdate=${mealdate}&checkExactDate=${checkExactDate}`),
    doOrdersExistForDateRangesAndInstitution: (institutionId, dateRanges) => httpClient.post('transactions/orders/exist/institution/dateranges', {dateRanges}, {params: {institutionId}}),
    doOrdersExistForDateRangeAndCaterer: (catererId, dateRange) => httpClient.post(`transactions/orders/exist/caterers/${catererId}/daterange`, dateRange),
    doOrdersExistForDateRangesAndGroup: (groupId, dateRanges) => httpClient.post('transactions/orders/exist/group/dateranges', {dateRanges}, {params: {groupId}}),
    doOrdersExistForGroupAndCurrentWeekOrLater: (groupId) => httpClient.get(`transactions/orders/exist-current-week-or-later/group/${groupId}`),
    searchCredits: (filter) => {
        if (!filter.page) {
            filter.page = 0;
        }
        if (!filter.pageSize) {
            filter.pageSize = 10;
        }
        const queryParams = searchCriteriaToQueryParams(filter);
        return httpClient.get(`transactions/credits/search?${queryParams}`);
    },
    createOrUpdateOrderAsCaterer: (order) => httpClient.post('transactions/as-caterer', order),
    cancelOrdersForAccount: (accountId) => httpClient.delete('transactions/accounts/' + accountId),
    findBalance: () => httpClient.get('transactions/balance'),
    findBalancePlusCancelableOrders: () => httpClient.get('transactions/balance-plus-cancelable-orders'),
    existsCancelableOrderOrSubscription: (childId) => httpClient.get(`transactions/exists/orderOrSubscription/${childId}`),
    fetchMenuGroupOrderStats: (catererId, orderType, fromDate, toDate) => httpClient.get(`transactions/menu-group-order-stats/${catererId}`, {params: {orderType, fromDate, toDate}, immediate: true})
};

export const searchCriteriaToQueryParams = (filter) => {
    const queryParams = new URLSearchParams();

    if (filter) {
        if (filter.page >= 0) {
            queryParams.append('page', filter.page);
        }
        if (filter.pageSize) {
            queryParams.append('pageSize', filter.pageSize);
        }
        if (filter.bookingDateFrom) {
            queryParams.append('bookingDateFrom', convertDateToIsoDateString(filter.bookingDateFrom));
        }
        if (filter.bookingDateTo) {
            queryParams.append('bookingDateTo', convertDateToIsoDateString(filter.bookingDateTo));
        }
        if (filter.customerNumber) {
            queryParams.append('customerNumber', filter.customerNumber);
        }
        if (filter.customerName) {
            queryParams.append('customerName', filter.customerName);
        }
        if (filter.note) {
            queryParams.append('note', filter.note);
        }
        if (filter.creditTypes) {
            filter.creditTypes.forEach(creditType => queryParams.append('creditTypes', creditType));
        }
        if (filter.paymentMethodType) {
            queryParams.append('paymentMethodType', filter.paymentMethodType);
        }
    }
    return queryParams.toString();
};

export default transactionApi;
