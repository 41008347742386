import {Dispatch} from 'redux';
import {AppThunk} from '../../common/reducer/reducers';
import {CarrierType} from '../../types/CarrierType';
import {CatererType} from '../../types/caterer/CatererType';
import {BaseFilterType} from '../../types/filter/BaseFilterType';
import {InstitutionType} from '../../types/InstitutionType';
import {MealPricesType} from '../../types/MealPricesType';
import {ParentType} from '../../types/ParentType';
import administrationApi from '../api/administrationApi';
import carrierApi from '../api/carrierApi';
import mealPriceApi from '../api/mealPriceApi';
import {AdministrationState} from '../reducer/administrationReducer';

export const SET_CARRIERS = 'SET_CARRIERS';
export const SET_MEAL_PRICES = 'SET_MEAL_PRICES';
export const SET_INSTITUTIONS = 'SET_INSTITUTIONS';
export const SET_CATERERS = 'SET_CATERERS';
export const SET_PARENTS = 'SET_PARENTS';

interface SetCarriersAction {
    type: typeof SET_CARRIERS;
    carriers: CarrierType[];
}

interface SetMealPricesAction {
    type: typeof SET_MEAL_PRICES;
    mealPrices: MealPricesType[];
}

interface SetInstitutionsAction {
    type: typeof SET_INSTITUTIONS;
    institutions: InstitutionType[];
}

interface SetCaterersAction {
    type: typeof SET_CATERERS;
    caterers: CatererType[];
}

interface SetParentsAction {
    type: typeof SET_PARENTS;
    parents: ParentType[];
}

export type AdministrationActions = SetCaterersAction | SetCarriersAction | SetMealPricesAction | SetInstitutionsAction | SetParentsAction

export const findCarriers = (useCache = true, hasCarrierPermission = true, catererId?: number): AppThunk => (dispatch: Dispatch<SetCarriersAction>, getState) => {
    // hasPermission() cannot be called here, so the caller has to provide the information.
    if (!hasCarrierPermission) {
        return;
    }

    if (useCache) {
        const {carriers} = getState().administration as AdministrationState;
        if (carriers && carriers.length > 0) {
            return;
        }
    }

    if (catererId) {
        carrierApi.fetchCarrierListByCatererId(catererId).then((response) => {
            dispatch({
                type: 'SET_CARRIERS',
                carriers: response.data.success ? response.data.result : []
            });
        });
    } else {
        carrierApi.fetchCarrierList().then((response) => {
            dispatch({
                type: 'SET_CARRIERS',
                carriers: response.data.success ? response.data.result : []
            });
        });
    }
};

export const findCatererMealPrices = (useCache = true): AppThunk => (dispatch: Dispatch<SetMealPricesAction>, getState) => {
    if (useCache) {
        const {mealPrices} = getState().administration as AdministrationState;
        if (mealPrices && mealPrices.length > 0) {
            return;
        }
    }

    mealPriceApi.findCatererPrices().then((response) => {
        dispatch({
            type: SET_MEAL_PRICES,
            mealPrices: response.data.success ? response.data.result : []
        });
    });
};

export const findAllInstitutions = (useCache = true): AppThunk =>
    (dispatch: Dispatch<SetInstitutionsAction>, getState) => {
        if (useCache) {
            const {institutions} = getState().administration as AdministrationState;
            if (institutions && institutions.length > 0) {
                return;
            }
        }

        administrationApi.findAllInstitution().then((response) => {
            dispatch({
                type: SET_INSTITUTIONS,
                institutions: response.data.success ? response.data.result : []
            });
        });
    };

export const resetInstitutions = () => (dispatch: Dispatch<SetInstitutionsAction>) => {
    dispatch({
        type: SET_INSTITUTIONS,
        institutions: []
    });
};

export const findAllCaterers = (useCache = true): AppThunk => (dispatch: Dispatch<SetCaterersAction>, getState) => {
    if (useCache) {
        const {caterers} = getState().administration as AdministrationState;
        if (caterers && caterers.length > 0) {
            return;
        }
    }

    administrationApi.findAllCaterer().then((response) => {
        dispatch({
            type: SET_CATERERS,
            caterers: response.data.success ? response.data.result : []
        });
    });
};

export const resetCaterers = () => (dispatch: Dispatch<SetCaterersAction>) => {
    dispatch({
        type: SET_CATERERS,
        caterers: []
    });
};

export const findParents = (filter: BaseFilterType, setTotalCount: (cnt: number) => void, useCache = true): AppThunk =>
    (dispatch: Dispatch<SetParentsAction>, getState) => {
        if (useCache) {
            const {parents} = getState().administration as AdministrationState;
            if (parents && parents.length) {
                return;
            }
        }
        administrationApi.searchParents(filter).then((response) => {
            dispatch({
                type: SET_PARENTS,
                parents: response.data.success ? response.data.result : []
            });

            setTotalCount(response.data.success ? response.data.total : 0);
        });
    };
