import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SlideDown from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import OrderOption from '../../common/enums/OrderOption';
import OrderTypeAdvantages from './OrderTypeAdvantages';

function SubscriptionInfo({
                              onSwitch,
                              isInstitution = false,
                              catererId
                          }) {
    const [t] = useTranslation();
    const [showDetails, setShowDetails] = useState(false);

    function toggleDetails(e) {
        e.preventDefault();
        setShowDetails(!showDetails);
    }

    return (
        <div className="row align-items-end no-gutters">
            <div className="col-12 col-md-7 col-lg-8 mb-3 mb-md-0">
                {
                    !showDetails &&
                    <div className="fade-in">
                        <p>{t('SubscriptionInfo.TEASER')}</p>
                        <div className="text-right">
                            <a href="#" onClick={toggleDetails}>{t('SubscriptionInfo.MORE_INFO')}</a>
                        </div>
                    </div>
                }
                <SlideDown closed={!showDetails}>
                    <OrderTypeAdvantages orderOption={OrderOption.SUBSCRIPTION} isInstitution={isInstitution} catererId={catererId}/>
                    <div className="text-right">
                        <a href="#" onClick={toggleDetails}>{t('SubscriptionInfo.COLLAPSE')}</a>
                    </div>
                </SlideDown>
            </div>

            <div className="col-12 col-md-5 col-lg-4 text-center text-sm-right">
                <button
                    className="btn btn-outline-primary"
                    onClick={onSwitch}>{t('Subscription.SWITCH_TO_SUBSCRIPTION')}</button>
            </div>
        </div>
    );
}

export default SubscriptionInfo;
