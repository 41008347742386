import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {useMount} from 'react-use';
import accountApi from '../../account/api/accountApi';
import {markChanges, navigate, unmarkChanges} from '../../common/action/pageLeaveActions';
import BackTo from '../../common/component/BackTo';
import ConfirmButton from '../../common/component/ConfirmButton';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import FormButtons from '../../common/component/form/FormButtons';
import PageLeaveGuard from '../../common/component/PageLeaveGuard';
import {showError, showMessage} from '../../message/action/messageActions';
import {updateAccount} from '../../user/action/userActions';
import userApi from '../../user/api/userApi';
import childApi from '../api/ChildApi';
import ChildSettings from './ChildSettings';

function ChildEdit({isOnboarding = false}) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const account = useSelector(state => state.user.account);
    const childId = match && match.params ? parseInt(match.params.id) : null;
    const children = useSelector(state => state.user.account.children);
    const [child, setChild] = useState(match && match.params && match.params.id !== 'new' && !isOnboarding ? children.find(c => c.id === childId) :
        {
            firstName: null,
            lastName: null,
            groupCode: null,
            diets: [],
            welfare: [],
            allergies: []
        });
    const [childManagement, setChildManagement] = useState(null);

    const [isValid, setIsValid] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showBlockDialog, setShowBlockDialog] = useState(false);
    const hasChanges = useSelector(state => state.pageLeave.hasUnsavedChanges);

    useMount(() => {
        if (!childId) {
            return;
        }
        childApi.findChildManagementByChildId(childId).then((res) => {
            if (res.data.success) {
                setChildManagement(res.data.result);
            }
        });
    });

    function handleChildChange(updatedChild, treatAsUnsavedChange = true) {
        if (treatAsUnsavedChange) {
            dispatch(markChanges());
        }
        setChild(updatedChild);
    }

    function save() {
        setShowErrors(false);
        if (isValid) {
            const childToSave = {...child};
            childApi.createOrUpdate(childToSave).then((res) => {
                const uploads = [];
                if (res.data.success) {
                    const savedChild = res.data.result;
                    // Append uploaded allergy files
                    if (child.allergies && child.allergies.some(allergy => !!allergy.file)) {
                        for (const allergy of child.allergies) {
                            if (allergy.file) {
                                const formData = new FormData();
                                formData.append('file', allergy.file);
                                formData.append('childId', savedChild.id);
                                formData.append('nutritionClassId', allergy.nutritionClassId);
                                uploads.push(accountApi.uploadProofOfIntolerance(formData));
                            }
                        }
                    }

                    const modifiedWelfares = childToSave.childWelfare ? childToSave.childWelfare.filter(welfare => !!welfare.modified) : [];
                    for (const welfare of modifiedWelfares) {
                        childApi.createOrUpdateWelfare(welfare, savedChild.id).then(welfareRes => {
                            if (welfareRes.data.success) {
                                const savedWelfare = welfareRes.data.result;
                                if (welfare.file) {
                                    const formData = new FormData();
                                    formData.append('file', welfare.file);
                                    formData.append('childWelfareId', savedWelfare.id);
                                    formData.append('childId', savedChild.id);
                                    formData.append('welfareDepartment', welfare.welfareDepartment);
                                    uploads.push(accountApi.uploadWelfareAppliance(formData));
                                }
                            } else {
                                dispatch(showError('Error.GENERAL'));
                            }
                        });
                    }
                }

                if (uploads.length > 0) {
                    Promise.all(uploads).then(() => {
                        finishSave();
                    });
                } else {
                    finishSave();
                }
            });
        } else {
            dispatch(showError('Error.MISSING_PARAMETER'));
            setShowErrors(true);
        }
    }

    function finishSave() {
        userApi.findMe().then(res => {
            const childBefore = childId ? children.find(c => c.id === childId) : null;
            const welfareBefore = childBefore && childBefore.childWelfare && childBefore.childWelfare.length > 0;

            if (res.data.success && res.data.result.account) {
                dispatch(updateAccount(res.data.result.account));
            }
            dispatch(unmarkChanges());
            dispatch(navigate('/'));
            dispatch(showMessage('Child.SAVE_SUCCESS', {child: `${child.firstName} ${child.lastName}`}));

            if (!welfareBefore && child.childWelfare && child.childWelfare.length > 0) {
                dispatch(showMessage('ChildSettings.WELFARE_CONFIRMATION_INFO'));
            }
        });
    }

    function cancel() {
        dispatch(unmarkChanges());
        dispatch(navigate('/'));
    }

    function removeData() {
        childApi.removeChild(child.id).then((response) => {
            if (response.data.success) {
                dispatch(updateAccount({
                    ...account,
                    children: account.children.filter(oldChild => oldChild.id !== child.id)
                }));
                dispatch(navigate('/'));
                dispatch(showMessage('Child.REMOVE_DATA_SUCCESS'));
            }
        });
    }

    function updateBlockedRfid() {
        childApi.updateBlockedRfid(childId, !childManagement.rfidBlocked).then((res) => {
            if (res.data.success) {
                setChildManagement({
                    ...childManagement,
                    rfidBlocked: !childManagement.rfidBlocked
                });
                setShowBlockDialog(false);
            }
        });
    }

    return (
        <div className="container">
            <BackTo path="/" labelKey="ChildrenOverview.TITLE"/>
            <div className="row mt-3">
                <h1 className="col-sm-8 col-12">{t('Child.TITLE')}</h1>
                {
                    child.id &&
                    <div className="col-sm-4 col-12 pb-4 text-right ">
                        <button className="btn btn-secondary text-right"
                                onClick={() => setShowDialog(true)}>{t('Child.REMOVE_DATA')}</button>
                    </div>
                }
            </div>
            <ChildSettings
                child={child}
                onChange={handleChildChange}
                onValidate={value => setIsValid(value)}
                showErrors={showErrors}
            />
            {
                childManagement?.rfid &&
                <div className="card mt-3 mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className=" col-10 text-left">
                                {childManagement.rfidBlocked ? t('Rfid.UNBLOCK_TEXT_PARENT') : t('Rfid.BLOCK_TEXT_PARENT')}
                            </div>
                            <div className="col-2 text-right">
                                {
                                    childManagement.rfidBlocked ?
                                        <button className="btn btn-primary" onClick={() => setShowBlockDialog(true)}>{t('Button.UNBLOCK')}</button>
                                        :
                                        <button className="btn btn-primary" onClick={() => setShowBlockDialog(true)}>{t('Button.BLOCK')}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                isOnboarding ?
                <div className="text-right mt-3">
                    <ConfirmButton onClick={save} label={t('Button.SAVE_AND_CONTINUE')}/>
                </div>
                :
                <FormButtons
                    onSave={save}
                    onCancel={cancel}
                    sticky={false}
                />
            }
            <ConfirmationDialog
                open={showDialog}
                title={t('Child.REMOVE_DATA')}
                body={t('Child.REMOVE_CONFIRMATION_DESCRIPTION')}
                confirmLabel={t('Button.REMOVE')}
                cancelLabel={t('Button.CANCEL')}
                onConfirm={() => {
                    removeData();
                    setShowDialog(false);
                }}
                onCancel={() => setShowDialog(false)}
            />
            <ConfirmationDialog
                open={showBlockDialog}
                title={childManagement?.rfidBlocked ? t('Rfid.TITLE_UNBLOCK') : t('Rfid.TITLE_BLOCK')}
                body={childManagement?.rfidBlocked ? t('Rfid.BODY_UNBLOCK') : t('Rfid.BODY_BLOCK')}
                confirmLabel={childManagement?.rfidBlocked ? t('Button.UNBLOCK') : t('Button.BLOCK')}
                cancelLabel={t('Button.CANCEL')}
                onConfirm={updateBlockedRfid}
                onCancel={() => setShowBlockDialog(false)}
            />
            <PageLeaveGuard hasChanges={hasChanges}/>
        </div>
    );
}

export default ChildEdit;
