import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {FinanceConfig} from '../../types/finance/FinanceConfig';
import {FETCH_FINANCE_CONFIG, FinanceConfigActions} from '../actions/financeConfigActions';

type FinanceConfigState = {
    financeConfig: FinanceConfig;
}

const initialState: FinanceConfigState = {
    financeConfig: {
        FINANCE_SEPA_ACTIVE: true,
        FINANCE_INFO_ACTIVE: false,
        FINANCE_FALLBACK_BANK_DATA: '',
        FINANCE_SCHEDULED_SEPA_ACTIVE: true,
        FINANCE_FEE_ACTIVE: true,
        FINANCE_ALLOW_BANK_TRANSFER_WITHOUT_APPROVAL: false
    }
};

const financeConfigReducer: Reducer<FinanceConfigState, FinanceConfigActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case FETCH_FINANCE_CONFIG: {
            if (action.financeConfig) {
                return {
                    ...state,
                    financeConfig: action.financeConfig
                };
            }

            return {
                ...state
            };
        }
    }

    return state;
};

export default financeConfigReducer;
