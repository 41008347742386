export function checkIsBicMandatory(iban) {
    return !iban || iban.length < 2 || iban.trim().substring(0, 2).toUpperCase() !== 'DE';
}

export function formatIban(input) {
    return input.trim().replace(/\s/g, '');
}

export function formatBic(input) {
    return input.toUpperCase().trim().replace(/\s/g, '');
}

export function capitalizeFirstTwoLetters(string) {
    if (!string) {
        return null;
    } else if (string.length === 1) {
        return string[0].toUpperCase();
    } else {
        return string.charAt(0).toUpperCase() + string.charAt(1).toUpperCase() + string.slice(2);
    }
}