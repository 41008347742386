import React, {MouseEventHandler} from 'react';
import '../../style/StickyContent.scss';
import './FormButtons.scss';
import {useTranslation} from 'react-i18next';
import CancelButton from '../CancelButton';
import ConfirmButton from '../ConfirmButton';

interface Props {
    sticky?: boolean,
    onSave: MouseEventHandler<HTMLButtonElement>,
    onCancel: MouseEventHandler<HTMLButtonElement>,
    saveLabelKey?: string,
    cancelLabelKey?: string,
    saveDisabled?: boolean,
    className?: string,
    showSaveIcon?: boolean,
    showCancelIcon?: boolean
}

export default function FormButtons({sticky = true, onSave, onCancel, saveLabelKey, cancelLabelKey, saveDisabled, className, showSaveIcon, showCancelIcon}: Readonly<Props>) {
    const [t] = useTranslation();

    return (
        <div className={`w-100 ${sticky ? 'sticky-bottom' : ''}`}>
            <div className={`form-buttons ${className ?? ''}`}>
                <div className={`${sticky ? 'sticky-shadow form-button-margin' : ''} form-button-alignment`}>
                    <CancelButton onClick={onCancel} label={t(cancelLabelKey ?? 'Button.CANCEL')} showIcon={showCancelIcon}/>
                    <ConfirmButton onClick={onSave} label={t(saveLabelKey ?? 'Button.SAVE')} disabled={saveDisabled} showIcon={showSaveIcon}/>
                </div>
            </div>
        </div>
    );
}
