import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';
import {AccountType} from '../../types/AccountType';
import {BillType} from '../../types/BillType';
import {CreditType} from '../../types/enums/transaction/CreditType';
import {TransactionTypeEnum} from '../../types/enums/transaction/TransactionTypeEnum';
import {BaseFilterType} from '../../types/filter/BaseFilterType';
import {TransactionFilter} from '../../types/filter/TransactionFilter';
import {TransactionType} from '../../types/TransactionType';
import financeApi from '../api/financeApi';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_BILLS = 'SET_BILLS';
export const SET_TRANSACTION_FILTER = 'SET_TRANSACTION_FILTER';
export const UPDATE_TRANSACTION_FILTER = 'UPDATE_TRANSACTION_FILTER';
export const UPDATE_TRANSACTION_TYPE_FILTER = 'UPDATE_TRANSACTION_TYPE_FILTER';
export const RESET_TRANSACTION_TYPE_FILTER = 'RESET_TRANSACTION_TYPE_FILTER';

interface SetTransactionsAction {
    type: typeof SET_TRANSACTIONS;
    total: number;
    transactions: TransactionType[];
    filter?: TransactionFilter;
}

interface SetBillsAction {
    type: typeof SET_BILLS;
    total: number;
    bills: BillType[];
    filter: BaseFilterType;
}

interface SetTransactionFilterAction {
    type: typeof SET_TRANSACTION_FILTER;
    filter: TransactionFilter;
}

interface UpdateTransactionFilterAction {
    type: typeof UPDATE_TRANSACTION_FILTER;
    filterKey: string;
    filterValue: string;
}

interface UpdateTransactionTypeFilterAction {
    type: typeof UPDATE_TRANSACTION_TYPE_FILTER;
    transactionType: TransactionTypeEnum;
    creditType?: CreditType;
}

interface ResetTransactionTypeFilterAction {
    type: typeof RESET_TRANSACTION_TYPE_FILTER;
}

export type FinanceActions = SetTransactionsAction | SetBillsAction | SetTransactionFilterAction
    | UpdateTransactionFilterAction | UpdateTransactionTypeFilterAction | ResetTransactionTypeFilterAction;

export const searchTransactions = (filter: TransactionFilter): AppThunk =>
    (dispatch: Dispatch<SetTransactionsAction>, getState) => {
        const account = getState().user.account as AccountType;
        if (account) {
            financeApi.searchTransactionsForAccount(account.id, filter).then((response) => {
                dispatch({
                    type: SET_TRANSACTIONS,
                    total: response.data.success ? response.data.total : 0,
                    transactions: response.data.success ? response.data.result : []
                });
            });
        }
    };

export const searchBills = (institutionId: number | null, filter: TransactionFilter): AppThunk =>
    (dispatch: Dispatch<SetBillsAction>, getState) => {
        const account = getState().user.account;
        financeApi.searchBills(institutionId ? null : account?.id, institutionId, filter).then((response) => {
            dispatch({
                type: SET_BILLS,
                total: response.data.success ? response.data.total : 0,
                bills: response.data.success ? response.data.result : [],
                filter
            });
        });
    };

export const setTransactionFilter = (filter: TransactionFilter): AppThunk =>
    (dispatch: Dispatch<SetTransactionFilterAction>) => {
        dispatch({
            type: SET_TRANSACTION_FILTER,
            filter
        });
    };

export const updateTransactionFilter = (filterKey: string, filterValue: string): AppThunk =>
    (dispatch: Dispatch<UpdateTransactionFilterAction>) => {
        dispatch({
            type: UPDATE_TRANSACTION_FILTER,
            filterKey,
            filterValue
        });
    };

export const updateTransactionTypeFilter = (transactionType: TransactionTypeEnum, creditType?: CreditType): AppThunk =>
    (dispatch: Dispatch<UpdateTransactionTypeFilterAction>) => {
        dispatch({
            type: UPDATE_TRANSACTION_TYPE_FILTER,
            transactionType,
            creditType
        });
    };

export const resetTransactionTypeFilter = (): AppThunk =>
    (dispatch: Dispatch<ResetTransactionTypeFilterAction>) => {
        dispatch({
            type: RESET_TRANSACTION_TYPE_FILTER
        });
    };

export const resetParentFinance = () => (dispatch: Dispatch<SetTransactionsAction | SetBillsAction>) => {
    dispatch({
        type: SET_TRANSACTIONS,
        total: 0,
        transactions: [],
        filter: {
            page: 0,
            pageSize: 10
        }
    });
    dispatch({
        type: SET_BILLS,
        total: 0,
        bills: [],
        filter: {
            page: 0,
            pageSize: 10
        }
    });
};
