import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {MessageType} from '../../types/MessageType';
import {ADD_MESSAGE, CLEAR_MESSAGES, MessageActions, SHOW_MESSAGE_SECONDS} from '../action/messageActions';

type MessageState = {
    messages: MessageType[]
}

const initialLoaderState = {
    messages: []
};

const messageReducer: Reducer<MessageState, MessageActions | InitialAction> = (state = initialLoaderState, action = {type: ''}) => {
    if (action.type === ADD_MESSAGE) {
        const nowInMilliseconds = (new Date()).getTime();
        return {
            ...state,
            messages: [
                // Remove older messages
                ...state.messages.filter(message => !message.created || message.created > (nowInMilliseconds - 1000 * SHOW_MESSAGE_SECONDS)),
                {
                    ...action.message,
                    created: nowInMilliseconds
                }
            ]
        };
    } else if (action.type === CLEAR_MESSAGES) {
        return {
            ...state,
            messages: []
        };
    }

    return state;
};

export default messageReducer;
