import i18next from 'i18next';
import React from 'react';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import ArrowNextIcon from '../../icons/ArrowNextIcon';
import Icon from '../../icons/Icon';
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';
import '../../style/Pagination.scss';

type PaginationProps = {
    canPreviousPage: boolean
    gotoPage: (index: any) => void
    canNextPage: boolean
    pageCount: number
    previousPage: () => void
    nextPage: () => void
    pageIndex: number
}

const Pagination = (props: PaginationProps) => {

    const pageNumbers = createPageNumberArray();

    function createPageNumberArray() {
        const pageNumberArray = [];

        if (props.pageCount > 20) {
            // We have to shorten: Use 20 items around the current page
            const begin = Math.max(Math.min(props.pageIndex - 10, props.pageCount - 21), 0);
            const end = begin + 20;

            for (let i = begin; i <= end; i++) {
                pageNumberArray.push(i + 1);
            }
        } else {
            for (let i = 0; i < props.pageCount; i++) {
                pageNumberArray.push(i + 1);
            }
        }

        return pageNumberArray;
    }

    return (
        <nav aria-label={i18next.t('Table.PAGINATION')}>
            <ul className="pagination justify-content-center">
                <li className={'page-item' + (!props.canPreviousPage ? ' disabled' : '')}>
                    <span className="page-link clickable" aria-label={i18next.t('Table.FIRST')}
                          onClick={() => props.gotoPage(0)}>
                        <span aria-hidden="true"><Icon src={<ArrowBackIcon size={0.65}/>}/></span>
                    </span>
                </li>
                <li className={'page-item' + (!props.canPreviousPage ? ' disabled' : '')}>
                    <span className="page-link clickable" aria-label={i18next.t('Table.PREVIOUS')}
                          onClick={() => props.previousPage()}>
                        <span aria-hidden="true"> <Icon src={<LeftIcon size={0.65}/>}/></span>
                    </span>
                </li>
                {
                    props.pageCount > 20 && props.pageIndex > 10 &&
                    <li className="page-item page-link">...</li>
                }
                {
                    pageNumbers.map(page =>
                        <li className={'page-item' + ((page - 1) === props.pageIndex ? ' active' : '')} key={page}>
                            <span className="page-link clickable"
                                  onClick={() => props.gotoPage(page - 1)}>{page}</span>
                        </li>
                    )
                }
                {
                    props.pageCount > 20 && props.pageIndex < props.pageCount - 11 &&
                    <li className="page-item page-link">...</li>
                }

                <li className={'page-item' + (!props.canNextPage ? ' disabled' : '')}>
                    <span className="page-link clickable" aria-label={i18next.t('Table.NEXT')}
                          onClick={() => props.nextPage()}>
                        <span aria-hidden="true"> <Icon src={<RightIcon size={0.65}/>}/></span>
                    </span>
                </li>
                <li className={'page-item' + (!props.canNextPage ? ' disabled' : '')}>
                    <span className="page-link clickable" aria-label={i18next.t('Table.LAST')}
                          onClick={() => props.gotoPage(props.pageCount - 1)}>
                        <span aria-hidden="true"> <Icon src={<ArrowNextIcon size={0.65}/>}/></span>
                    </span>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
