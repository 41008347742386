import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';

type Props = {
    path: string,
    labelKey?: string
}

function BackTo({path, labelKey = 'Button.BACK'}: Readonly<Props>) {
    const [t] = useTranslation();

    return (
        <Link to={path}>
            <ChevronLeftIcon/>
            <span className="ml-2">{t(labelKey)}</span>
        </Link>
    );
}

export default BackTo;
