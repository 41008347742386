import {Dispatch} from 'react';
import menuApi from '../../administration/api/menuApi';
import {AppThunk} from '../../common/reducer/reducers';
import {AdditiveType} from '../../types/AdditiveType';
import {AllergenType} from '../../types/AllergenType';

export const SET_ALLERGENS_ADDITIVES = 'SET_ALLERGENS_ADDITIVES';

interface SetAllergensAdditivesAction {
    type: typeof SET_ALLERGENS_ADDITIVES;
    additives: AdditiveType[];
    allergens: AllergenType[];
}

export const loadAllergensAndAdditives = (): AppThunk =>
    (dispatch: Dispatch<SetAllergensAdditivesAction>) => {
        menuApi.findAllAllergensAndAdditives().then(res => {
            if (res.data.success) {
                dispatch({
                    type: SET_ALLERGENS_ADDITIVES,
                    allergens: res.data.result.allergens,
                    additives: res.data.result.additives
                });
            }
        });
};

export type MealActions = SetAllergensAdditivesAction;
