import {httpClient} from '../../../common/api/HttpClient';

const billApi = {
    fetchBillMetadata: (filterParams, pageIndex, pageSize) => httpClient.get('billing/metadata', {
        params: {
            ...filterParams,
            pageIndex,
            pageSize
        }
    }),
    downloadBillDocumentById: id => httpClient.get(`billing/documents/${id}`, {responseType: 'blob'}),
    downloadMultipleBillDocumentsById: ids => httpClient.get('billing/documents', {
        params: {id: ids},
        paramsSerializer: params => {
            return searchCriteriaToQueryParams(params);
        },
        responseType: 'blob'
    }),
    downloadMultipleBillDocumentsByFilter: filterParams => httpClient.get('billing/documents', {
        params: filterParams,
        responseType: 'blob'
    }),
    rebuildBillById: id => httpClient.post(`billing/rebuild/${id}`)
};

export const searchCriteriaToQueryParams = (params) => {
    const queryParams = new URLSearchParams();
    if (params && params.id) {
        for (let param of params.id) {
            queryParams.append('id', param);
        }
    }
    return queryParams.toString();
};
export default billApi;
