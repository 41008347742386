import {Reducer} from 'react';
import {InitialAction} from '../../common/reducer/reducers';
import {AdditiveType} from '../../types/AdditiveType';
import {AllergenType} from '../../types/AllergenType';
import {MealActions, SET_ALLERGENS_ADDITIVES} from '../actions/mealAction';

type MealState = {
    allergens: AllergenType[]
    additives: AdditiveType[]
}
const initialState: MealState = {
    allergens: [],
    additives: []
};
const mealReducer: Reducer<MealState, MealActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case SET_ALLERGENS_ADDITIVES:
            if (action.additives && action.allergens) {
                return {
                    ...state,
                    additives: action.additives,
                    allergens: action.allergens
                };
            }
            break;
    }
    return state;
};

export default mealReducer;
