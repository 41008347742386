import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {emptyDropdown} from '../../../administration/util/AccountEditUtil';
import Salutation from '../../enums/Salutation';
import Dropdown from './Dropdown';
import TextInput from './TextInput';

function Person({
                    person,
                    onChange,
                    validation,
                    isContact,
                    readOnly = false,
                    optionals = {},
                    salutations,
                    displayAdditionalPhone = false
                }) {
    const [t] = useTranslation();
    const [selectedSalutation, setSelectedSalutation] = useState(emptyDropdown);

    //Used to set salutation when person is filled after mount
    useEffect(() => {
        setSelectedSalutation(person.salutation ? {
            label: t(`Salutation.${person.salutation}`),
            value: person.salutation
        } : emptyDropdown);
    }, [person.salutation]);

    let salutationOptions;
    if (salutations) {
        salutationOptions = salutations.map(type => ({
            label: t(`Salutation.${type}`),
            value: type
        }));
    } else {
        const defaultSalutations = [Salutation.MR, Salutation.MRS, Salutation.MX];
        salutationOptions = defaultSalutations.map(type => ({
            label: t(`Salutation.${type}`),
            value: type
        }));
    }

    function handleSalutationChange(value) {
        handleChange('salutation', value.value);
    }

    function handleChange(key, value) {
        onChange({
            ...person,
            [key]: value
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4">
                    <Dropdown
                        label={t('User.SALUTATION')}
                        options={salutationOptions}
                        value={selectedSalutation}
                        onChange={handleSalutationChange}
                        required={!optionals.salutation}
                        isValid={validation.salutation}
                        isDisabled={readOnly}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <TextInput
                        id="firstName"
                        label={t('User.FIRST_NAME')}
                        value={person.firstName}
                        onChange={value => handleChange('firstName', value)}
                        required={!optionals.firstName}
                        isValid={validation.firstName}
                        readonly={readOnly}
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <TextInput
                        id="lastName"
                        label={t('User.LAST_NAME')}
                        value={person.lastName}
                        onChange={value => handleChange('lastName', value)}
                        required={!optionals.lastName}
                        isValid={validation.lastName}
                        readonly={readOnly}
                    />
                </div>
            </div>
            {
                isContact &&
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <TextInput
                            id="phone"
                            label={t('User.PHONE')}
                            value={person.phone}
                            onChange={value => handleChange('phone', value)}
                            required={false}
                            isValid={validation.phone}
                            readonly={readOnly}
                        />
                    </div>
                    {
                        displayAdditionalPhone &&
                        <div className="col-12 col-lg-6">
                            <TextInput
                                id="additional-phone"
                                label={t('User.ADDITIONAL_PHONE')}
                                value={person.additionalPhone}
                                onChange={value => handleChange('additionalPhone', value)}
                                required={false}
                                isValid={validation.additionalPhone}
                                readonly={readOnly}
                            />
                        </div>
                    }
                    <div className="col-12 col-lg-6">
                        <TextInput
                            id="fax"
                            label={t('User.FAX')}
                            value={person.fax}
                            onChange={value => handleChange('fax', value)}
                            required={false}
                            isValid={validation.fax}
                            readonly={readOnly}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default Person;
