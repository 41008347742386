import {httpClient} from '../../common/api/HttpClient';

export const mealPriceApi = {
    findCatererPrices: () => httpClient.get('meal-prices'),
    findInstitutionPrices: (institutionId) => httpClient.get(`meal-prices?institutionId=${institutionId}`),
    createOrUpdateFuturePrices: (mealPrices) => httpClient.post('meal-prices/future', mealPrices),
    removeCatererPriceChange: () => httpClient.delete('meal-prices/future'),
    removeInstitutionPriceChange: (institutionId) => httpClient.delete(`meal-prices/future?institutionId=${institutionId}`),
};

export default mealPriceApi;
