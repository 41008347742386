import {Reducer} from 'redux';
import {PageClickActions} from '../action/pageClickAction';
import {InitialAction} from './reducers';

type PageClickState = {
    pageClickCount: number
}

const initialState: PageClickState = {
    pageClickCount: 0
};

const pageClickReducer: Reducer<PageClickState, PageClickActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    if (action.type === 'COUNT_PAGE_CLICKED') {
        return {
            ...state,
            pageClickCount: state.pageClickCount + 1
        };
    }

    return state;
};

export default pageClickReducer;
