import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import CheckIconFilled from '../../common/icons/CheckIconFilled';
import DeleteIcon from '../../common/icons/DeleteIcon';
import Icon from '../../common/icons/Icon';
import {DefaultState} from '../../common/reducer/reducers';
import {formatDateAndTime} from '../../common/util/DateUtil';

type Props = {
    sepaMandate: any;
    onRevokeMandate: () => void;
}

function SepaDirectDebitActiveMandate({sepaMandate, onRevokeMandate}: Props) {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const currentBalance = useSelector((state: DefaultState) => state.user.account?.balance);
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

    function confirmationDialogBody() {
        return <>
            {t('Finance.SEPA.MANDATE_SUBJECT')}:<br/>
            {sepaMandate.payerName}<br/>
            {sepaMandate.payerEmailAddress}<br/>
            {t('PaymentInformation.IBAN')}: {sepaMandate.iban}<br/>
            {t('Finance.SEPA.MANDATE_REFERENCE')}: {sepaMandate.reference}
            {
                currentBalance && currentBalance < 0 &&
                <p className="mt-3 font-weight-bold">{t('Finance.SEPA.REVOCATION_CAUSES_CANCELLATION_HINT')}</p>
            }
        </>;
    }

    const handleRevocation = useCallback(() => {
        setIsConfirmationVisible(false);
        onRevokeMandate();
    }, [onRevokeMandate]);

    if (!sepaMandate || !sepaMandate.active) {
        return null;
    }

    return <>
        <div className="card mb-3 border-success">
            <div className="card-body">
                <div className="px-2">
                    <div>
                        <div className="d-flex">
                            <div className="mr-auto">
                                <h4>{t('Finance.SEPA.ACTIVE_MANDATE')}</h4>
                            </div>
                            <div><Icon src={<CheckIconFilled/>} className="text-success mt-3"/></div>
                        </div>
                        <p className="pre-wrap">
                            {`${sepaMandate.payerName}\n${sepaMandate.payerEmailAddress}\n${t('PaymentInformation.IBAN')}: ${sepaMandate.iban}`}
                        </p>

                        <div className="d-flex">
                            <div className="mr-auto">
                                <label className="text-muted">{t('Finance.SEPA.DATE_AND_MANDATE')}</label>
                                <p>{`${formatDateAndTime(sepaMandate.created, currentLanguage, true, false)}, ${sepaMandate.reference}`}</p>
                            </div>
                            <div className="mt-2">
                                <button type="reset" className="btn btn-light" onClick={() => setIsConfirmationVisible(true)}>
                                    <Icon src={<DeleteIcon/>} className="mr-2"/>
                                    {t('Finance.SEPA.REVOKE_MANDATE')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmationDialog
            newDesign={true}
            open={isConfirmationVisible}
            title={t('Finance.SEPA.CONFIRM_MODAL_TITLE')}
            body={confirmationDialogBody()}
            confirmLabel={t('Finance.SEPA.REVOKE_MANDATE')}
            cancelLabel={t('Button.CANCEL')}
            onConfirm={handleRevocation}
            onCancel={() => setIsConfirmationVisible(false)}
        />
    </>;
}

export default SepaDirectDebitActiveMandate;
