import React, {ReactNode, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import Icon from '../icons/Icon';
import InfoIcon from '../icons/InfoIcon';
import '../style/InfoTooltip.scss';

interface Prop {
    label?: string;
    children?: ReactNode;
    showIcon?: boolean;
    withMargin?: boolean;
}

function InfoTooltip(props: Prop) {
    const [showOrderInfos, setShowOrderInfos] = useState<boolean>(false);
    const [popoverTarget, setPopoverTarget] = useState<HTMLAnchorElement | null>(null);
    const showIcon = props.showIcon === undefined ? true : props.showIcon;
    const withMargin = props.withMargin === undefined ? true : props.withMargin;

    const togglePopover = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setShowOrderInfos(!showOrderInfos);
        setPopoverTarget(event.currentTarget);
    };
    return (
        <>
            <a href="#"
               className="info-tooltip"
               onClick={togglePopover}
               onBlur={() => setShowOrderInfos(false)}
            >
                {
                    showIcon &&
                    <Icon src={<InfoIcon/>}
                          className={withMargin ? 'mr-2' : ''}/>
                }
                {props.label}
            </a>
            {
                popoverTarget &&
                <Overlay
                    rootClose
                    rootCloseEvent={'click'}
                    onHide={() => setShowOrderInfos(false)}
                    show={showOrderInfos}
                    target={popoverTarget}
                    placement="bottom"
                >
                    <Popover id="deadlines">
                        <Popover.Content>
                            {props.children}
                        </Popover.Content>
                    </Popover>
                </Overlay>
            }
        </>
    );
}

export default InfoTooltip;
