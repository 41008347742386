import React from 'react';
import {NavLink} from 'react-router-dom';
import '../../style/IconNavLink.scss';

function IconNavLink({
                         icon,
                         exact = false,
                         to,
                         children,
                         keyValue
                     }) {
    return (
        <NavLink
            id="icon-nav-link"
            exact={exact}
            to={to}
            key={keyValue}
        >
            <div className="nav-link">
                <span className="nav-text">
                    <span className="icon-link">
                        {icon}
                    </span>
                    {children}
                </span>
            </div>
        </NavLink>
    );
}

export default IconNavLink;
