import React from 'react';
import {useTranslation} from 'react-i18next';
import InstitutionTypeDropDown from '../../administration/component/institution/InstitutionTypeDropDown';
import InstitutionContactFunction from '../../administration/enums/InstitutionContactFunction';
import Address from '../../common/component/Address';
import Dropdown from '../../common/component/form/Dropdown';
import Person from '../../common/component/form/Person';
import TextInput from '../../common/component/form/TextInput';
import ErrorCode from '../../common/enums/ErrorCode';

function InstitutionInquiryInput({
                                     inquiry,
                                     validation,
                                     handleInquiryChange,
                                     handleCompanyChange,
                                     handleContactPersonChange,
                                     mailErrorCode
                                 }) {
    const [t] = useTranslation();
    const contactFunctionOptions = Object.keys(InstitutionContactFunction).map(type => ({
        label: t(`InstitutionContact.FUNCTION.${type}`),
        value: type
    }));

    function getMailErrorMessage() {
        if (!validation.contactPerson.emailAddress && !!inquiry.contactPerson.emailAddress) {
            return t('Validation.INVALID_EMAIL');
        }
        if (mailErrorCode) {
            const errorMessage = Object.keys(ErrorCode).find(code => ErrorCode[code].code === mailErrorCode);
            return t(`Error.${errorMessage}`);
        }
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <InstitutionTypeDropDown
                        value={inquiry.institutionType}
                        onChange={value => handleInquiryChange('institutionType', value.value)}
                        isValid={validation.institutionType}
                    />
                </div>
            </div>

            <TextInput
                id="company-name"
                type="text"
                label={t('Registration.COMPANY_NAME')}
                value={inquiry.company.name}
                onChange={value => handleCompanyChange('name', value)}
                required={true}
                isValid={validation.company.name}
            />
            <Address
                address={inquiry.company.address}
                handleChange={value => handleCompanyChange('address', value)}
                validation={validation.company.address}
            />
            <h3>{t('Registration.CONTACT_PERSON')}</h3>
            <Person
                person={inquiry.contactPerson}
                onChange={value => handleInquiryChange('contactPerson', value)}
                validation={validation.contactPerson}
                isContact={true}
                optionals={{firstName: true}}
            />
            <Dropdown
                label={t('InstitutionContact.FUNCTION_DROPDOWN')}
                options={contactFunctionOptions}
                value={inquiry.contactPerson.function}
                onChange={value => handleContactPersonChange('function', value.value)}
                required={true}
                isValid={validation.contactPerson.function}
            />
            <TextInput
                id="email"
                type="email"
                label={t('User.EMAIL')}
                value={inquiry.contactPerson.emailAddress}
                onChange={value => handleContactPersonChange('emailAddress', value)}
                required={true}
                isValid={validation.contactPerson.emailAddress && !mailErrorCode}
                errorMessage={getMailErrorMessage()}
            />
        </>
    );
}

export default InstitutionInquiryInput;
