import React from 'react';

interface Props {
    size?: number;
}

const ChevronLeftIcon = ({size = 1} : Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M16.0303 4.21967C16.3232 4.51256 16.3232 4.98744 16.0303 5.28033L9.31066 12L16.0303 18.7197C16.3232 19.0126 16.3232 19.4874
                     16.0303 19.7803C15.7374 20.0732 15.2626 20.0732 14.9697 19.7803L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967
                     11.4697L14.9697 4.21967C15.2626 3.92678 15.7374 3.92678 16.0303 4.21967Z"/>
        </svg>
    );
};

export default ChevronLeftIcon;
