import React from 'react';

type iconProps = {
    size?: number;
}

const PayoutIcon = ({
                        size = 1
                    }: iconProps) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd"
                  d="M4.14723 3.98697C3.62526 4.28524 3.5 4.58301 3.5 4.75C3.5 4.91699 3.62526 5.21476 4.14723 5.51303C4.60985 5.77738 5.27823
                  5.96891 6.05556 5.99656C5.8667 6.37897 5.75 6.81604 5.75 7.30859V7.48195C4.91014 7.42092 4.1297 7.20702 3.5 6.8691V7.75C3.5 7.91699
                  3.62526 8.21476 4.14723 8.51303C4.54803 8.74206 5.10327 8.91642 5.75 8.97691V10.4819C4.91014 10.4209 4.1297 10.207 3.5
                  9.8691V10.75C3.5 10.917 3.62526 11.2148 4.14723 11.513C4.54803 11.7421 5.10327 11.9164 5.75 11.9769V13.3086C5.75 13.3673 5.75166
                  13.4252 5.7549 13.4823C4.86949 13.4186 4.04991 13.185 3.40302 12.8154C2.65825 12.3898 2 11.6876 2 10.75V7.75V4.75C2 3.81242 2.65825
                  3.11019 3.40302 2.6846C4.16902 2.24689 5.17716 2 6.25 2C7.32284 2 8.33098 2.24689 9.09698 2.6846C9.49796 2.91373 9.87385 3.22305
                  10.1354 3.60612C9.52935 3.67343 8.94407 3.81192 8.408 4.01941C8.39011 4.0086 8.3717 3.99779 8.35277 3.98697C7.85201 3.70082 7.11016
                  3.5 6.25 3.5C5.38984 3.5 4.64799 3.70082 4.14723 3.98697ZM8.25 7.30859C8.25 7.1416 8.37526 6.84383 8.89723 6.54556C9.39799 6.25942
                  10.1398 6.05859 11 6.05859C11.8602 6.05859 12.602 6.25942 13.1028 6.54556C13.6247 6.84383 13.75 7.1416 13.75 7.30859C13.75 7.47558
                  13.6247 7.77335 13.1028 8.07162C12.602 8.35777 11.8602 8.55859 11 8.55859C10.1398 8.55859 9.39799 8.35777 8.89723 8.07162C8.37526
                  7.77335 8.25 7.47558 8.25 7.30859ZM6.75 7.30859C6.75 6.37101 7.40825 5.66878 8.15302 5.2432C8.91902 4.80549 9.92716 4.55859 11
                  4.55859C12.0728 4.55859 13.081 4.80549 13.847 5.2432C14.5918 5.66878 15.25 6.37101 15.25 7.30859V10.3086V13.3086C15.25 14.2462
                  14.5918 14.9484 13.847 15.374C13.081 15.8117 12.0728 16.0586 11 16.0586C9.92716 16.0586 8.91902 15.8117 8.15302 15.374C7.40825
                  14.9484 6.75 14.2462 6.75 13.3086V10.3086V7.30859ZM13.75 13.3086V12.4277C12.9969 12.8318 12.0281 13.0586 11 13.0586C9.97186
                  13.0586 9.00312 12.8318 8.25 12.4277V13.3086C8.25 13.4756 8.37526 13.7734 8.89723 14.0716C9.39799 14.3578 10.1398 14.5586 11
                  14.5586C11.8602 14.5586 12.602 14.3578 13.1028 14.0716C13.6247 13.7734 13.75 13.4756 13.75 13.3086ZM13.75 9.42769V10.3086C13.75
                  10.4756 13.6247 10.7734 13.1028 11.0716C12.602 11.3578 11.8602 11.5586 11 11.5586C10.1398 11.5586 9.39799 11.3578 8.89723
                  11.0716C8.37526 10.7734 8.25 10.4756 8.25 10.3086V9.42769C9.00312 9.83184 9.97186 10.0586 11 10.0586C12.0281 10.0586 12.9969
                  9.83184 13.75 9.42769ZM18.4097 10.2275C18.4097 9.81333 18.0739 9.47754 17.6597 9.47754C17.2455 9.47754 16.9097 9.81333 16.9097
                  10.2275V19.4165L14.63 17.1369C14.3372 16.844 13.8623 16.844 13.5694 17.1369C13.2765 17.4298 13.2765 17.9047 13.5694
                  18.1976L17.1158 21.744C17.2525 21.8878 17.4456 21.9775 17.6597 21.9775C17.7395 21.9775 17.8165 21.9651 17.8887 21.9419C17.999
                  21.9067 18.1028 21.8454 18.1903 21.7578L21.7506 18.1976C22.0434 17.9047 22.0434 17.4298 21.7506 17.1369C21.4577 16.844 20.9828
                  16.844 20.6899 17.1369L18.4097 19.4171V10.2275Z"/>
        </svg>
    );
};

export default PayoutIcon;
