import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAsyncDebounce} from 'react-table';
import SearchField from '../SearchField';

type TableGlobalFilterProps = {
    globalFilter: string
    setGlobalFilter: (value: string) => void
}

function TableGlobalFilter(props: TableGlobalFilterProps) {
    const [t] = useTranslation();
    const [value, setValue] = useState<string>(props.globalFilter);
    const onChange = useAsyncDebounce(text => {
        props.setGlobalFilter(text || undefined);
    }, 200);

    useEffect(() => {
        setValue(props.globalFilter);
    }, [props.globalFilter]);

    return (
        <SearchField placeholder={t('Search.PLACEHOLDER_LABEL')}
                     setSearchTerm={(text: string) => {
                         setValue(text);
                         onChange(text);
                     }}
                     searchTerm={value}
                     isPrimarySearchIcon={true}
        />
    );
}

export default TableGlobalFilter;
