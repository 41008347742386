import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';
import defaultWelcomeImage from '../../assets/img/welcome.png';
import supportWelcomeImage from '../../assets/img/welcome-support.png';
import publicCatererApi from '../../caterer/api/publicCatererApi';
import {CatererBinaryType} from '../../caterer/enums/catererBinaryType';
import TextInput from '../../common/component/form/TextInput';
import ErrorCode from '../../common/enums/ErrorCode';
import Gabel1DomainType from '../../common/enums/Gabel1DomainType';
import {DefaultState} from '../../common/reducer/reducers';
import {getFileExtension} from '../../common/util/FileUtil';
import {showMessage} from '../../message/action/messageActions';
import {CatererNews} from '../../types/caterer/CatererNewsType';
import {login} from '../action/userActions';
import loginApi from '../api/loginApi';
import '../style/Login.scss';

interface LoginValidation {
    email: boolean,
    password: boolean
}

function Login() {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const domainType = useSelector((state: DefaultState) => state.domainContext.domainType);
    const isLoggedIn: boolean = useSelector((state: DefaultState) => state.user.isLoggedIn);
    const catererId: number | undefined = useSelector((state: DefaultState) => state.caterer.catererId);
    const catererNews: CatererNews | undefined = useSelector((state: DefaultState) => state.caterer.news);

    const [newsToShow, setNewsToShow] = useState<string | undefined>();
    const [email, setEmail] = useState<string>(() => {
        const userName = query.get('username');
        return userName ?? '';
    });
    const [password, setPassword] = useState<string>('');
    const [validation, setValidation] = useState<LoginValidation>({
        email: true,
        password: true
    });
    const [welcomeImage, setWelcomeImage] = useState<string>();

    const focussedOnLoad: string = query.get('username') ? 'password' : 'username';

    const [loginError, setLoginError] = useState<number | null>(null);

    useEffect(() => {
        if (catererNews) {
            setNewsToShow(catererNews.catererText);
            setTimeout(() => setNewsToShow(undefined),
                (new Date(catererNews.endDate)).getTime() - ((new Date()).getTime()));
        }
    }, [catererNews, setNewsToShow]);

    useEffect(() => {
        if (catererId) {
            publicCatererApi.findCatererBinaryResources(catererId, [CatererBinaryType.WELCOME]).then((response) => {
                if (response.data.success && response.data.result[CatererBinaryType.WELCOME]?.id) {
                    const imageData = response.data.result[CatererBinaryType.WELCOME].binary;
                    setWelcomeImage(`data:image/${getFileExtension(imageData)};base64,${imageData}`);
                }
                else
                {
                    setWelcomeImage(defaultWelcomeImage);
                }
            });
        } else if (domainType === Gabel1DomainType.SUPPORT) {
            setWelcomeImage(supportWelcomeImage);
        }
    }, [catererId, domainType]);

    if (isLoggedIn) {
        return <Redirect to={{pathname: '/'}}/>;
    }

    function validate() {
        const newValidation = {
            email: !!email,
            password: !!password
        };
        setValidation(newValidation);
        return Object.values(newValidation).every(field => field);
    }

    function handleLogin() {
        setLoginError(null);
        if (validate() && (catererId || domainType === Gabel1DomainType.SUPPORT)) {
            dispatch(login(email, password, catererId,(response) => {
                setLoginError(response.errorCode ? response.errorCode : null);
            }));
        }
    }

    function handleEmailChange(value: string) {
        setEmail(value);
        setLoginError(null);
    }

    function handlePasswordReset(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        history.push('/request-password-reset');
    }

    function handleResendConfirmation(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        setLoginError(null);
        loginApi.requestConfirmationLink(email, catererId).then((res) => {
                if (res.data.success) {
                    dispatch(showMessage('Error.ACCOUNT_NOT_CONFIRMED_RESEND', {email: email}));
                }
            }
        );
    }

    let errorMessage: ReactElement = <></>;
    if (ErrorCode.INVALID_AUTHENTICATION.code === loginError) {
        errorMessage = <>{t('Error.INVALID_AUTHENTICATION')}</>;
    }
    if (ErrorCode.NOT_REGISTERED_FOR_CATERER.code === loginError) {
        errorMessage = <>{t('Error.NOT_REGISTERED_FOR_CATERER')}</>;
    }
    if (ErrorCode.ACCOUNT_NOT_CONFIRMED.code === loginError) {
        errorMessage = (
            <>
                {t('Error.ACCOUNT_NOT_CONFIRMED')}
                <div>
                    <a onClick={handleResendConfirmation}
                       href="#">{`${t('Error.ACCOUNT_CONFIRMATION_HINT')} ${t('Error.ACCOUNT_NOT_CONFIRMED_LINK')}`}</a>
                </div>
            </>
        );
    }

    return (
        <div id="login">
            <div className="d-flex flex-lg-row flex-column-reverse">
                <div className="login-welcome-image-container ">
                    <img src={welcomeImage} className="login-welcome-image" alt="Welcome"/>
                </div>
                <div className="login-form">
                    {
                        newsToShow &&
                        <div className="alert alert-info pre-wrap" role="alert">
                            {newsToShow}
                        </div>
                    }
                    <h1 className="login-header">{t('Login.LOGIN')}</h1>
                    {
                        domainType === Gabel1DomainType.CATERER &&
                        <div className="login-registration">
                            {t('Login.NEW')} <Link to="/register">{t('Login.REGISTER_HERE')}</Link>
                        </div>
                    }
                    <form onSubmit={e => {
                        e.preventDefault();
                        handleLogin();
                    }}
                    >
                        <TextInput
                            id="username"
                            value={email}
                            label={t('Login.EMAIL')}
                            onChange={handleEmailChange}
                            required={true}
                            isValid={validation.email && !loginError}
                            focusOnLoad={focussedOnLoad === 'username'}
                            autoComplete="email"
                            errorMessage={errorMessage}
                        />
                        <TextInput
                            id="password"
                            value={password}
                            label={t('Login.PASSWORD')}
                            type="password"
                            onChange={setPassword}
                            required={true}
                            isValid={validation.password}
                            focusOnLoad={focussedOnLoad === 'password'}
                            autoComplete="password"
                        />
                        <div>
                            <a href="#" onClick={handlePasswordReset}>{t('Login.PASSWORD_RESET')}</a>
                        </div>
                        <div className="button-footer text-right">
                            <button type="submit" className="btn btn-primary login-button">
                                {t('Login.LOGIN')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
