import React from 'react';

interface Props {
    size?: number;
}

const BillIcon = ({size = 1}: Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 12.25C7.5 11.8358 7.83579 11.5 8.25 11.5C8.66421 11.5 9 11.8358 9 12.25C9 12.6642 8.66421 13 8.25 13C7.83579 13 7.5 12.6642
                     7.5 12.25ZM8.25 14.5C7.83579 14.5 7.5 14.8358 7.5 15.25C7.5 15.6642 7.83579 16 8.25 16C8.66421 16 9 15.6642 9 15.25C9 14.8358
                     8.66421 14.5 8.25 14.5ZM7.5 18.25C7.5 17.8358 7.83579 17.5 8.25 17.5C8.66421 17.5 9 17.8358 9 18.25C9 18.6642 8.66421 19 8.25
                     19C7.83579 19 7.5 18.6642 7.5 18.25ZM11.25 11.5C10.8358 11.5 10.5 11.8358 10.5 12.25C10.5 12.6642 10.8358 13 11.25
                     13H16.75C17.1642 13 17.5 12.6642 17.5 12.25C17.5 11.8358 17.1642 11.5 16.75 11.5H11.25ZM10.5 15.25C10.5 14.8358 10.8358 14.5
                     11.25 14.5H16.75C17.1642 14.5 17.5 14.8358 17.5 15.25C17.5 15.6642 17.1642 16 16.75 16H11.25C10.8358 16 10.5 15.6642 10.5
                     15.25ZM11.25 17.5C10.8358 17.5 10.5 17.8358 10.5 18.25C10.5 18.6642 10.8358 19 11.25 19H16.75C17.1642 19 17.5 18.6642 17.5
                     18.25C17.5 17.8358 17.1642 17.5 16.75 17.5H11.25ZM19.914 8.414L14.085 2.586C14.07 2.57105 14.0531 2.55808 14.0363 2.54519C14.0238
                     2.53567 14.0115 2.5262 14 2.516C13.929 2.452 13.859 2.389 13.781 2.336C13.7557 2.31894 13.7281 2.30548 13.7005 2.29207C13.6845
                     2.28426 13.6685 2.27647 13.653 2.268C13.6363 2.25859 13.6197 2.24897 13.603 2.23933C13.5488 2.20797 13.4944 2.17648 13.437
                     2.152C13.24 2.07 13.028 2.029 12.813 2.014C12.7933 2.01274 12.7738 2.01008 12.7542 2.00741C12.7271 2.00371 12.6999 2 12.672
                     2H6.5C5.396 2 4.5 2.896 4.5 4V20C4.5 21.104 5.396 22 6.5 22H18.5C19.604 22 20.5 21.104 20.5 20V9.828C20.5 9.298 20.289 8.789
                     19.914 8.414ZM19 20C19 20.275 18.776 20.5 18.5 20.5H6.5C6.224 20.5 6 20.275 6 20V4C6 3.725 6.224 3.5 6.5 3.5H12.5V8C12.5 9.104
                     13.396 10 14.5 10H19V20ZM14 4.621L17.878 8.5H14.5C14.224 8.5 14 8.275 14 8V4.621Z"
                  fill="currentColor"/>
        </svg>
    );
};

export default BillIcon;
