import Iban from 'iban';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import EditModal from '../../../common/component/EditModal';
import TextInput from '../../../common/component/form/TextInput';
import TextOutput from '../../../common/component/TextOutput';
import {checkValidation} from '../../../common/util/ValidationUtil';
import BalanceWithdrawalByPaymentSelection from '../../../finance/component/BalanceWithdrawalByPaymentSelection';
import {capitalizeFirstTwoLetters, checkIsBicMandatory, formatIban} from '../../../finance/util/BankDataUtils';

export default function AccountDeletionUserInputModal({
                                                 visible,
                                                 deletionCheckResult,
                                                 withdrawalAmount,
                                                 onConfirm,
                                                 onCancel
                                             }) {
    const [t] = useTranslation();
    const [withdrawalRequest, setWithdrawalRequest] = useState({
        withdrawalAmount: null,
        receiverName: null,
        iban: null,
        bic: null
    });
    const [stripeRefundDone, setStripeRefundDone] = useState(false);

    const [validation, setValidation] = useState({
        receiverName: true,
        iban: true,
        bic: true
    });
    const [showErrors, setShowErrors] = useState(false);

    function handleChange(field, value) {
        setWithdrawalRequest({
            ...withdrawalRequest,
            [field]: value
        });
    }

    function validate() {
        const mustCheckBankAccount = deletionCheckResult.stripeRefundPaymentMethods.length === 0;
        if (!mustCheckBankAccount) {
            setValidation({all: true});
            return true;
        }

        const newValidation = {
            receiverName: mustCheckBankAccount && !!withdrawalRequest.receiverName,
            iban: mustCheckBankAccount && !!withdrawalRequest.iban && Iban.isValid(withdrawalRequest.iban),
            bic: mustCheckBankAccount && (!checkIsBicMandatory(withdrawalRequest.iban) || !!withdrawalRequest.bic)
        };
        setValidation(newValidation);

        return checkValidation(newValidation);
    }

    function confirm() {
        if (validate()) {
            onConfirm({
                ...withdrawalRequest,
                withdrawalAmount
            });
        } else {
            setShowErrors(true);
        }
    }

    function formatIbanOnBlur() {
        handleChange('iban', capitalizeFirstTwoLetters(formatIban(withdrawalRequest.iban)));
    }

    return (
        <EditModal
            visible={visible}
            header={t('User.DELETION.CONFIRMATION_HEADER')}
            confirmLabel={t('Button.DELETE')}
            onConfirm={deletionCheckResult.stripeRefundPaymentMethods.length === 0 || stripeRefundDone ? confirm : null}
            onCancel={onCancel}
            fixedWidth="65%"
        >
            {
                deletionCheckResult.stripeRefundPaymentMethods.length > 0 &&
                <>
                    {
                        !stripeRefundDone &&
                        <>
                            <p>{t('User.DELETION.STRIPE_REFUND_POSSIBLE')}</p>
                            <BalanceWithdrawalByPaymentSelection
                                withdrawalAmount={withdrawalAmount}
                                paymentMethods={deletionCheckResult.stripeRefundPaymentMethods}
                                onDone={() => setStripeRefundDone(true)}
                                isAccountDeletion={true}
                                buttonPlacement="start"
                            />
                        </>
                    }
                    {
                        stripeRefundDone &&
                        <div className="mt-3 pre-wrap">
                            {deletionCheckResult.hasPendingWithdrawal ?
                                t('User.DELETION.STRIPE_REFUND_DONE_BUT_WITHDRAWAL_PENDING')
                                : t('User.DELETION.STRIPE_REFUND_DONE')}
                        </div>
                    }
                </>
            }
            {
                deletionCheckResult.stripeRefundPaymentMethods.length === 0 &&
                <>
                    <p className="pre-wrap">
                        {t('User.DELETION.CREDIT_WITHOUT_WITHDRAWAL')}
                    </p>

                    <TextOutput
                        label={t('Credit.AMOUNT_IN_EURO')}
                        text={withdrawalAmount}
                    />
                    <TextInput
                        label={t('Finance.BALANCE_WITHDRAWAL.ACCOUNT_HOLDER')}
                        onChange={value => handleChange('receiverName', value)}
                        value={withdrawalRequest.receiverName}
                        isValid={!showErrors || validation.receiverName}
                        required={true}
                    />
                    <TextInput
                        label="IBAN"
                        onChange={value => handleChange('iban', value)}
                        value={withdrawalRequest.iban}
                        isValid={!showErrors || validation.iban}
                        errorMessage={withdrawalRequest.iban && !Iban.isValid(withdrawalRequest.iban) ? t('Validation.INVALID_IBAN') : null}
                        required={true}
                        onBlur={formatIbanOnBlur}
                    />
                    <TextInput
                        label="BIC"
                        onChange={value => handleChange('bic', value)}
                        value={withdrawalRequest.bic}
                        isValid={!showErrors || validation.bic}
                        required={checkIsBicMandatory(withdrawalRequest.iban)}
                        readonly={!checkIsBicMandatory(withdrawalRequest.iban)}
                        placeholder={!checkIsBicMandatory(withdrawalRequest.iban) ? t('Finance.SEPA.BIC_NOT_REQUIRED') : null}
                    />
                </>
            }
        </EditModal>
    );
}
