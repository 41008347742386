import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCatererTextResource} from '../../caterer/action/catererActions';
import {CatererTextType} from '../../caterer/enums/catererTextType';
import Information from '../../common/component/Information';
import {DefaultState} from '../../common/reducer/reducers';

function Imprint() {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const caterer = useSelector((state: DefaultState) => state.caterer);

    useEffect(() => {
        if (caterer.catererId && !caterer.imprint) {
            dispatch(fetchCatererTextResource(caterer.catererId, CatererTextType.IMPRINT));
        }
    }, [caterer.catererId, caterer.imprint, dispatch]);

    return (
        <Information header={t('General.IMPRINT')} content={caterer.imprint}/>
    );
}

export default Imprint;
