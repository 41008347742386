import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {isWelfareActiveForDate, isWelfareInProgressForDate} from '../../../child/util/ChildUtils';
import {unmarkChanges} from '../../../common/action/pageLeaveActions';
import CardArrow from '../../../common/component/CardArrow';
import ConfirmationDialog from '../../../common/component/form/ConfirmationDialog';
import PageLeaveGuard from '../../../common/component/PageLeaveGuard';
import Tabs from '../../../common/component/Tabs';
import {isCatererBusinessDay} from '../../../common/util/CatererBusinessDaysUtil';
import {addDays, DAY_IN_MILLISECONDS, getDateRangeAsArray, getMondayForDate} from '../../../common/util/DateUtil';
import mealPlanApi from '../../api/mealPlanApi';
import '../../style/SingleOrder.scss';
import ChildSingleOrderMobile from './ChildSingleOrderMobile';
import OrderableChildMeal from './OrderableChildMeal';

function ChildSingleOrder({
                              child,
                              meals,
                              onUpdateMeals,
                              menuGroups,
                              loadMeals
                          }) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const catererBusinessDays = useSelector(state => state.caterer.timeManagementChild?.businessDays);
    const hasChanges = useSelector(state => state.pageLeave.hasUnsavedChanges);
    const [menuToBeRemoved, setMenuToBeRemoved] = useState(false);
    const [activeTab, setActiveTab] = useState({
        id: 0,
        label: t('OrderTab.WEEK_ONE')
    });

    const SingleOrderTabs = [{
        id: 0,
        label: t('OrderTab.WEEK_ONE')
    }, {
        id: 1,
        label: t('OrderTab.WEEK_TWO')
    }, {
        id: 2,
        label: t('OrderTab.WEEK_THREE')
    }];

    function addOrUpdateMeal(menu) {
        const mealsCopy = JSON.parse(JSON.stringify(meals));

        for (const mealCopy of mealsCopy) {
            if (mealCopy.mealDate === menu.date) {
                mealPlanApi.createOrUpdateMeal({
                        id: menu.order ? menu.order.id : null,
                        childId: child.id,
                        menuId: menu.id,
                        transactionType: 'DEBIT',
                        amount: menu.amount
                    }
                ).then((orderResponse) => {
                    if (orderResponse.data.success) {
                        if (isWelfareActiveForDate(child.childWelfare, menu.date) || isWelfareInProgressForDate(child.childWelfare, menu.date)) {
                            /* Reload all menus for menu.date.
                             * (With active welfare or welfare task in progress you can only order one menu per day so the orderable flag changes for all menus of that day.) */
                            mealPlanApi.findByTimespanAndChildId(menu.date, menu.date, child.id).then((findResponse) => {
                                if (findResponse.data.success && findResponse.data.result?.length > 0) {
                                    mealCopy.menus = findResponse.data.result[0].menus;
                                }

                                onUpdateMeals(mealsCopy);
                            });
                        } else {
                            // No need for an extra call to the backend
                            for (const menuCopy of mealCopy.menus) {
                                if (menuCopy.menuGroupId === menu.menuGroupId) {
                                    menuCopy.order = orderResponse.data.result;
                                    menuCopy.orderedAmount = menu.amount;
                                }
                            }
                            onUpdateMeals(mealsCopy);
                        }
                    } else {
                        loadMeals();
                        dispatch(unmarkChanges());
                    }
                });
                break;
            }
        }
        ReactTooltip.rebuild();
    }

    function removeMeal() {
        const mealsCopy = JSON.parse(JSON.stringify(meals));

        for (const mealCopy of mealsCopy) {
            if (mealCopy.mealDate === menuToBeRemoved.date) {
                mealPlanApi.removeMeal(mealCopy.menus.find(menu => menu.id === menuToBeRemoved.id).order.id).then((removeResponse) => {
                    if (removeResponse.data.success) {
                        /* Reload of the meal plan is necessary: the orderable flag may change.
                         * (The flag is false if the order limit is reached and will change to true because of this removal.) */
                        mealPlanApi.findByTimespanAndChildId(menuToBeRemoved.date, menuToBeRemoved.date, child.id).then((findResponse) => {
                            if (findResponse.data.success && findResponse.data.result?.length > 0) {
                                mealCopy.menus = findResponse.data.result[0].menus;
                            }
                            onUpdateMeals(mealsCopy);
                        });
                    }
                });
                break;
            }
        }
    }

    const monday = getMondayForDate(new Date());
    const startDate = addDays(monday, (activeTab.id) * 7);
    const endDate = addDays(monday, (activeTab.id + 1) * 7 - 1);
    const dates = getDateRangeAsArray(startDate, endDate);
    const mealsGroupedByMenuGroupAndDate = [...menuGroups];
    for (const menuGroup of mealsGroupedByMenuGroupAndDate) {
        menuGroup.menus = [];
        for (const date of dates) {
            if (!catererBusinessDays || !isCatererBusinessDay(date, catererBusinessDays))
            {
                continue;
            }

            const mealPlan = meals.find(mealPlanElement => mealPlanElement.mealDate === date);
            if (mealPlan) {
                const menu = mealPlan.menus.find(menuElement => menuElement.menuGroupId === menuGroup.id);

                if (menu) {
                    menu.locked = mealPlan.locked;
                    menu.menuLimit = mealPlan.menuLimit;
                    menu.closingDayFromCaterer = mealPlan.closingDayFromCaterer;
                    menu.closingDayFromInstitution = mealPlan.closingDayFromInstitution;
                    menu.closingDayFromGroup = mealPlan.closingDayFromGroup;
                    menuGroup.menus.push(menu);
                } else {
                    menuGroup.menus.push({
                        empty: true,
                        locked: mealPlan.locked,
                        menuLimit: mealPlan.menuLimit,
                        closingDayFromCaterer: mealPlan.closingDayFromCaterer,
                        closingDayFromInstitution: mealPlan.closingDayFromInstitution,
                        closingDayFromGroup: mealPlan.closingDayFromGroup,
                        date
                    });
                }
            } else {
                menuGroup.menus.push({
                    empty: true,
                    date
                });
            }
        }
    }

    // Handle menu groups for which no orders can be placed (any more) but orders are present: First, determine which menu groups are to add:
    const nonOrderableMenuGroups = [];
    for (const mealPlanElement of meals) {
        for (const menu of mealPlanElement.menus) {
            if (menu.order?.amount > 0) {
                const menuGroupAlreadyPresent = mealsGroupedByMenuGroupAndDate.findIndex(el => el.id === menu.menuGroupId) >= 0;
                if (!menuGroupAlreadyPresent && nonOrderableMenuGroups.findIndex(mg => mg.id === menu.menuGroupId) < 0) {
                    nonOrderableMenuGroups.push({...menu.menuGroup});
                }
            }
        }
    }

    // Then, fill each day of the additional menu groups (either with the order or with an empty placeholder):
    for (const nonOrderableMenuGroup of nonOrderableMenuGroups) {
        nonOrderableMenuGroup.menus = [];
        for (const date of dates) {
            if (!catererBusinessDays || !isCatererBusinessDay(date, catererBusinessDays))
            {
                continue;
            }

            const mealPlan = meals.find(mealPlanElement => mealPlanElement.mealDate === date);
            if (mealPlan) {
                const menu = mealPlan.menus.find(menuElement => menuElement.menuGroupId === nonOrderableMenuGroup.id);

                if (menu?.order?.amount > 0) {
                    menu.orderable = false;
                    menu.menuLimit = 1;
                    nonOrderableMenuGroup.menus.push(menu);
                } else {
                    nonOrderableMenuGroup.menus.push({
                        date,
                        empty: true,
                        locked: mealPlan.locked,
                        closingDayFromCaterer: mealPlan.closingDayFromCaterer,
                        closingDayFromInstitution: mealPlan.closingDayFromInstitution,
                        closingDayFromGroup: mealPlan.closingDayFromGroup,
                    });
                }
            } else {
                nonOrderableMenuGroup.menus.push({
                    date,
                    empty: true
                });
            }
        }
    }

    nonOrderableMenuGroups.forEach(el => {
        mealsGroupedByMenuGroupAndDate.push(el);
    });

    return (
        <>
            <div className="d-block d-md-none">
                <ChildSingleOrderMobile
                    menuGroups={menuGroups}
                    nonOrderableMenuGroups={nonOrderableMenuGroups}
                    activeTab={activeTab}
                    tabs={SingleOrderTabs}
                    setActiveTab={setActiveTab}
                    startDate={new Date(getMondayForDate(new Date()).getTime() + (activeTab.id) * 7 * DAY_IN_MILLISECONDS)}
                    meals={meals}
                    onAdd={addOrUpdateMeal}
                    onUpdate={addOrUpdateMeal}
                    onRemove={setMenuToBeRemoved}
                    childWelfare={child.childWelfare}
                />
            </div>

            <div className="d-none d-sm-none d-md-block">
                <Tabs
                    tabs={SingleOrderTabs}
                    onSelectTab={(tab) => setActiveTab(tab)}
                    activeTab={activeTab}
                />
                <div className="row d-none d-sm-none d-md-block">
                    <div className={`col-4 offset-md-${activeTab.id * 4}`}>
                        <CardArrow/>
                    </div>
                </div>
                <div className="card selected child-order">
                    <div className="card-header">
                        {t('OrderOption.SINGLE')}
                    </div>
                    <div className="card-body">
                        {
                            mealsGroupedByMenuGroupAndDate && mealsGroupedByMenuGroupAndDate.map((menuGroup, menuGroupIndex) => {
                                return menuGroup.menus &&
                                    <div className="row row-cols-2 no-gutters"
                                         key={`menu-group-${menuGroupIndex}`}>
                                        {
                                            menuGroup.menus.map((menu, menuIndex) =>
                                                <OrderableChildMeal
                                                    key={`menu-${menuGroupIndex}-${menuIndex}`}
                                                    showDate={menuGroupIndex === 0}
                                                    meals={meals}
                                                    menu={menu}
                                                    childWelfare={child.childWelfare}
                                                    menuGroup={menuGroup}
                                                    onAdd={(amount) => addOrUpdateMeal({
                                                        ...menu,
                                                        amount
                                                    })}
                                                    onUpdate={addOrUpdateMeal}
                                                    onRemove={() => setMenuToBeRemoved(menu)}
                                                />
                                            )
                                        }
                                    </div>;
                            })
                        }
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                open={!!menuToBeRemoved}
                title={t('SingleOrder.CANCELLATION')}
                body={t('SingleOrder.CANCELLATION_CONFIRMATION')}
                confirmLabel={t('Button.YES')}
                cancelLabel={t('Button.NO')}
                onConfirm={() => {
                    removeMeal();
                    setMenuToBeRemoved(null);
                }}
                onCancel={() => setMenuToBeRemoved(null)}
            />


            <PageLeaveGuard hasChanges={hasChanges}/>
        </>
    );
}

export default ChildSingleOrder;
