import React, {FocusEventHandler, KeyboardEventHandler, ReactElement, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '../../icons/CloseIcon';
import Icon from '../../icons/Icon';
import RefreshIcon from '../../icons/RefreshIcon';
import SearchIcon from '../../icons/SearchIcon';
import '../../style/SearchField.scss';

interface Prop {
    autoComplete?: string,
    errorMessage?: string | ReactElement,
    focusOnLoad?: boolean,
    id?: string,
    isValid?: boolean,
    label?: string,
    name?: string,
    maxLength?: number,
    showMaxLength?: boolean,
    onChange?: (value: string) => void,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
    placeholder?: string,
    readonly?: boolean,
    required?: boolean,
    showError?: boolean,
    showLoader?: boolean,
    tabIndex?: number,
    type?: string,
    value?: string | number | null,
    onClick?: () => void,
    pattern?: string,
    className?: string,
    isSearch?: boolean,
    isPrimarySearchIcon?: boolean,
    withSearchButton?: boolean,
    onSearch?: () => void,
    searchButtonDisabled?: boolean
}

export default function TextInput(props: Prop) {
    const [t] = useTranslation();
    const [textValue, setTextValue] = useState<string | number | undefined | null>(props.value);
    const inputRef = useRef<HTMLInputElement>(null);
    const displayError = (props.required && !props.isValid) || props.showError;
    const errorClasses = ' is-invalid border-danger';
    const showMaxLength = props.showMaxLength === undefined ? true : props.showMaxLength;

    // set focus to input field if needed
    useEffect(() => {
        if (props.focusOnLoad) {
            inputRef?.current?.focus();
        }
    }, []); // An empty dependency array is needed here to prevent re-focussing when the property changes!

    // handle change on text value and call external onChange if available
    function handleChange(newValue: string) {
        if (props.onChange) {
            props.onChange(newValue);
        }
        setTextValue(newValue);
    }

    useEffect(() => {
        setTextValue(props.value);
    }, [props.value]);

    return (
        <div className="form-group" onClick={props.onClick}>
            {props.label &&
                <label htmlFor={props.id}>
                    {`${props.label} ${props.required ? '*' : ''}`}
                    {
                        props.maxLength && showMaxLength &&
                        <span className="small text-muted">{`  (${t('Validation.MAX_LENGTH', {count: props.maxLength})})`}</span>
                    }
                </label>
            }
            <div className={`input-group${displayError ? errorClasses : ''}`}>
                <input
                    onWheel={() => 'number' === props.type ? inputRef?.current?.blur() : null}
                    id={props.id}
                    type={props.type === undefined ? 'text' : props.type}
                    name={props.name}
                    value={textValue || ''}
                    placeholder={props.placeholder}
                    onChange={(event) => handleChange(event.target.value)}
                    readOnly={props.readonly}
                    onKeyDown={props.onKeyDown}
                    maxLength={props.maxLength}
                    autoComplete={props.autoComplete ? props.autoComplete : 'on'}
                    className={`form-control${displayError ? errorClasses : ''} ${props.className ? props.className : ''}`}
                    ref={inputRef}
                    tabIndex={props.tabIndex}
                    onBlur={props.onBlur}
                    pattern={props.pattern}
                />
                {
                    props.showLoader &&
                    <div className="input-group-append">
                        <span className="input-group-text">
                            {
                                // @ts-ignore
                                <Icon src={<RefreshIcon/>} className="fa-spin"/>
                            }
                        </span>
                    </div>
                }
                {
                    props.isSearch &&
                    <div className="search input-group-append">
                        <span
                            className={`input-group-text  ${props.isPrimarySearchIcon ? 'primary-search-icon' : ''}`}
                            style={{ cursor: props.value ? 'pointer' : 'default'}}
                            // @ts-ignore
                            onClick={() => props.value ? handleChange(null) : null}
                        >
                           <Icon src={props.value ? <CloseIcon/> : <SearchIcon/>}/>
                        </span>
                    </div>
                }
                {
                    props.withSearchButton &&
                    <button
                        className='btn btn-primary'
                        onClick={props.onSearch}
                        disabled={props.searchButtonDisabled}>
                            <Icon src={<SearchIcon size={0.75}/>}/>
                    </button>
                }
            </div>
            {
                displayError &&
                <div className="invalid-feedback">
                    {props.errorMessage ? props.errorMessage : t('Validation.REQUIRED')}
                </div>
            }
        </div>
    );
}
