import React from 'react';

const SettingIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M12.0127 2.25C12.7466 2.25846 13.4777 2.34326 14.1942 2.50304C14.5069 2.57279 14.7408 2.83351 14.7763
                  3.15196L14.9465 4.67881C15.0235 5.37986 15.6155 5.91084 16.3211 5.91158C16.5108 5.91188 16.6984 5.87238
                  16.8737 5.79483L18.2743 5.17956C18.5656 5.05159 18.906 5.12136 19.1234 5.35362C20.1356 6.43464 20.8894 7.73115
                  21.3282 9.14558C21.4228 9.45058 21.3139 9.78203 21.0568 9.9715L19.8154 10.8866C19.4612 11.1468 19.2521 11.56
                  19.2521 11.9995C19.2521 12.4389 19.4612 12.8521 19.8161 13.1129L21.0587 14.0283C21.3158 14.2177 21.4248 14.5492
                  21.3302 14.8543C20.8916 16.2685 20.1382 17.5649 19.1266 18.6461C18.9093 18.8783 18.5693 18.9483 18.278 18.8206L16.8716
                  18.2045C16.4693 18.0284 16.0072 18.0542 15.627 18.274C15.2468 18.4937 14.9938 18.8812 14.9454 19.3177L14.7764
                  20.8444C14.7415 21.1592 14.5126 21.4182 14.2045 21.4915C12.7561 21.8361 11.247 21.8361 9.79852 21.4915C9.49039
                  21.4182 9.26153 21.1592 9.22667 20.8444L9.05785 19.32C9.00826 18.8843 8.75483 18.498 8.37491 18.279C7.995 18.06
                  7.53369 18.0343 7.13269 18.2094L5.72606 18.8256C5.43471 18.9533 5.09452 18.8833 4.87727 18.6509C3.86511 17.5685
                  3.11168 16.2705 2.67369 14.8548C2.57935 14.5499 2.68835 14.2186 2.94533 14.0293L4.18867 13.1133C4.54281 12.8531
                  4.75196 12.4399 4.75196 12.0005C4.75196 11.561 4.54281 11.1478 4.1882 10.8873L2.94565 9.97285C2.68828 9.78345
                  2.57918 9.45178 2.67386 9.14658C3.11261 7.73215 3.86643 6.43564 4.87861 5.35462C5.09608 5.12236 5.43643 5.05259
                  5.72773 5.18056L7.12811 5.79572C7.53105 5.97256 7.99429 5.94585 8.37626 5.72269C8.75658 5.50209 9.00977 5.11422
                  9.05866 4.67764L9.22873 3.15196C9.26425 2.83335 9.49835 2.57254 9.81129 2.50294C10.5286 2.34342 11.2604 2.25865
                  12.0127 2.25ZM12.0128 3.7499C11.5588 3.75524 11.106 3.79443 10.6583 3.86702L10.5494 4.84418C10.4476 5.75368 9.92052
                  6.56102 9.13091 7.01903C8.33646 7.48317 7.36785 7.53903 6.52507 7.16917L5.62678 6.77456C5.05485 7.46873 4.59963
                  8.25135 4.27901 9.09168L5.07681 9.67879C5.81562 10.2216 6.25196 11.0837 6.25196 12.0005C6.25196 12.9172 5.81562
                  13.7793 5.07759 14.3215L4.27853 14.9102C4.59887 15.752 5.05417 16.5361 5.62649 17.2316L6.53162 16.8351C7.36971
                  16.4692 8.33173 16.5227 9.12402 16.9794C9.9163 17.4361 10.4448 18.2417 10.5485 19.1526L10.6574 20.1365C11.5471
                  20.2878 12.4559 20.2878 13.3456 20.1365L13.4545 19.1527C13.5553 18.2421 14.0832 17.4337 14.8765 16.9753C15.6697
                  16.5168 16.6337 16.463 17.4733 16.8305L18.3777 17.2267C18.9495 16.5323 19.4046 15.7495 19.7252 14.909L18.9272
                  14.3211C18.1884 13.7783 17.7521 12.9162 17.7521 11.9995C17.7521 11.0827 18.1884 10.2206 18.9263 9.67847L19.7232
                  9.09109C19.4026 8.25061 18.9473 7.46784 18.3753 6.77356L17.4788 7.16737C17.1134 7.32901 16.7183 7.4122 16.3192
                  7.41158C14.8495 7.41004 13.616 6.30355 13.4556 4.84383L13.3467 3.8667C12.9012 3.7942 12.4531 3.75512 12.0128
                  3.7499ZM12.0002 8.24995C14.0713 8.24995 15.7502 9.92888 15.7502 12C15.7502 14.071 14.0713 15.75 12.0002
                  15.75C9.92912 15.75 8.25018 14.071 8.25018 12C8.25018 9.92888 9.92912 8.24995 12.0002 8.24995ZM12.0002 9.74995C10.7575
                  9.74995 9.75018 10.7573 9.75018 12C9.75018 13.2426 10.7575 14.25 12.0002 14.25C13.2428 14.25 14.2502 13.2426 14.2502
                  12C14.2502 10.7573 13.2428 9.74995 12.0002 9.74995Z"/>
        </svg>
    );
};

export default SettingIcon;
