import React, {useEffect, useMemo} from 'react';
import {Column, Row, SortingRule, TableOptions, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {PageProps} from '../../../types/PageProps';
import '../../style/Table.scss';
import Pagination from './Pagination';
import TableActions from './TableActions';
import TableHeaderColumn from './TableHeaderColumn';

type TableColumn<T extends object> = Column<T> & {
    className?: string
    hidden?: boolean
}

export interface Data<S extends object> extends Record<string, S> {
}

type RowProps = {
    className: string
}
type TableProps<T extends object> = {
    columns: Array<TableColumn<T>>,
    data: Array<T>,
    className?: string
    filter?: string
    observeForReset?: any
    filterable?: boolean
    sortable?: boolean
    autoResetFilters?: boolean
    autoResetSortBy?: boolean
    withinTab?: boolean
    showTableActions?: boolean
    forceAutoSort?: boolean
    exportLabel?: string
    pageIndex?: number
    initialSort?: SortingRule<Data<T>>[]
    pageSize?: number
    totalCount?: number
    hiddenColumns?: Array<string>
    setFilter?: (value: string) => void
    rowProps?: (row: any) => RowProps
    onAdd?: () => void
    fetchData?: (pageProps: any) => void
    onExport?: () => void
    onRowClick?: (row: Row<T>) => void
    rowClickableCheck?: (row: Row<T>) => boolean
}

export const DEFAULT_PAGE_SIZE = 15;

const OcTable = <T extends object>(props: TableProps<T>) => {

    const tableConfig = useMemo<TableOptions<T>>(() => {
        function getPageSize() {
            return props.pageSize ? props.pageSize : DEFAULT_PAGE_SIZE;
        }

        return {
            columns: props.columns,
            data: props.data,
            disableFilters: !props.filterable,
            disableSortBy: !props.sortable,
            initialState: {
                pageIndex: props.pageIndex ? props.pageIndex : 0,
                pageSize: getPageSize(),
                sortBy: props.initialSort ? props.initialSort : [],
                globalFilter: props.filter ? props.filter : ''
            },
            pageCount: props.totalCount ? Math.ceil(props.totalCount / getPageSize()) : -1,
            manualPagination: !!props.fetchData,
            manualSortBy: !!props.fetchData && !props.forceAutoSort,
            autoResetFilters: props.autoResetFilters,
            autoResetSortBy: props.autoResetSortBy
        } as TableOptions<T>;
    }, [props]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
        gotoPage,
        nextPage,
        previousPage,
        setGlobalFilter
    } = useTable(tableConfig, useFilters, useGlobalFilter, useSortBy, usePagination);

    const hasHeader = useMemo(() => {
        return headerGroups[0].headers?.some(header => !!header.Header && header.Header !== 'emptyRenderer');
    }, [headerGroups]);

    useEffect(() => {
        if (props.fetchData) {
            props.fetchData({
                pageIndex: pageIndex,
                pageSize: pageSize
            } as PageProps);
        }
    }, [pageIndex, pageSize]);

    useEffect(() => {
        if (typeof props.pageIndex !== 'undefined' && props.pageIndex !== pageIndex) {
            gotoPage(0);
        }
    }, [props.pageIndex]);

    useEffect(() => {
        if ((!!props.observeForReset && (!!props.pageIndex || props.pageIndex === 0)) && pageIndex !== props.pageIndex) {
            gotoPage(props.pageIndex);
            return;
        }
        if (pageIndex >= pageCount) {
            gotoPage(0);
        }
    }, [props.observeForReset]);

    return <>
        {
            props.showTableActions &&
            <TableActions
                globalFilter={globalFilter}
                setGlobalFilter={(value) => {
                    const myFilter = value && value.length > 0 ? value.trim() : value;
                    setGlobalFilter(myFilter);
                    props.setFilter && props.setFilter(myFilter);
                }}
                onExport={props.onExport}
                exportLabel={props.exportLabel}
                onAdd={props.onAdd}
            />
        }

        <div className={`table-container ${props.withinTab ? 'withinTab' : ''} ${props.className ?? ''}`}>
            <table {...getTableProps()} className="table d-inline-block d-sm-table overflow-auto">
                {
                    hasHeader &&
                    <thead className="thead-light">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.filter(column => !(column as TableColumn<T>)?.hidden).map((column, index) => <TableHeaderColumn
                                column={column}
                                key={index}/>)}
                        </tr>
                    ))}
                    </thead>
                }
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    const rowPropsClassName = !!props.rowProps ? props.rowProps(row).className : '';
                    return (
                        <tr {...row.getRowProps(!!props.rowProps ? props.rowProps(row) : {})}
                            className={`${rowPropsClassName} ${(props.onRowClick && (!props.rowClickableCheck || props.rowClickableCheck(row))) ? 'can-row-selected' : ''}`}
                            onClick={() => (props.onRowClick && (!props.rowClickableCheck || props.rowClickableCheck(row))) ? props.onRowClick(row) : null}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps({
                                    className: (cell.column as TableColumn<T>).className
                                })}>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {
                pageOptions.length > 1 &&
                <Pagination
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    previousPage={previousPage}
                />
            }
        </div>
    </>;
};

OcTable.defaultProps = {
    filterable: false,
    sortable: true,
    autoResetFilters: false,
    autoResetSortBy: false,
    withinTab: false,
    showTableActions: false,
    forceAutoSort: false,
    pageSize: DEFAULT_PAGE_SIZE
};

export default OcTable;
