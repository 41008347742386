import {httpClient} from '../../common/api/HttpClient';

export const loginApi = {
    login: (username, password, catererId) => httpClient.post('public/login', {
        username,
        password,
        catererId
    }),
    logout: () => httpClient.get('public/logout'),
    requestPasswordReset: (username, catererId) => httpClient.post(`public/request-password`, {
        username,
        catererId
    }),
    requestConfirmationLink: (username, catererId, ignoreRefererUrl) => httpClient.post(`public/request-confirmation`, {
        username,
        catererId
    }, { params: {ignoreRefererUrl} }),
    checkForValidPasswordReset: (token, username, catererId) => httpClient.post('public/check-for-valid-password-reset-token', {
        token,
        username,
        catererId
    }),
    passwordReset: (token, username, password, catererId) => httpClient.post('public/password-reset', {
        token,
        username,
        password,
        catererId
    }),
    confirmLogin: (token, username, catererId) => httpClient.post(`public/confirm-login`, {
        token,
        username,
        catererId
    }),
    checkLoginAvailability: (catererId, email, role, loginId) => {
        const queryParams = searchCriteriaToQueryParams(catererId, email, role, loginId);
        return httpClient.get(`login/availability?${queryParams}`);
    }
};

export const searchCriteriaToQueryParams = (catererId, email, role, loginId) => {
    const queryParams = new URLSearchParams();
    if (catererId) {
        queryParams.append('catererId', catererId);
    }
    if (email) {
        queryParams.append('userName', email);
    }
    if (role) {
        queryParams.append('role', role);
    }
    if (loginId) {
        queryParams.append('loginId', loginId);
    }
    return queryParams.toString();
};

export default loginApi;
