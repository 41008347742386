import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';
import {CatererNews} from '../../types/caterer/CatererNewsType';
import publicCatererApi from '../api/publicCatererApi';
import {CatererTextType} from '../enums/catererTextType';
import {CatererTimeManagementData} from '../types/CatererTimeManagementData';

export const SET_CATERER_BASICS = 'SET_CATERER_BASICS';
export const SET_CATERER_IMPRINT = 'SET_CATERER_IMPRINT';
export const SET_CATERER_PRIVACY = 'SET_CATERER_PRIVACY';
export const SET_CATERER_TERMS_CONDITION = 'SET_CATERER_TERMS_CONDITION';
export const SET_CATERER_LOGO = 'SET_CATERER_LOGO';
export const SET_CATERER_ICON = 'SET_CATERER_ICON';
export const SET_CATERER_NEWS = 'SET_CATERER_NEWS';
export const SET_CATERER_TIME_MANAGEMENT = 'SET_CATERER_TIME_MANAGEMENT';

interface SetCatererBasicsAction {
    type: typeof SET_CATERER_BASICS;
    catererId: number,
    companyName: string,
    displayName?: string,
    companyShortName: string,
    menuLimit: number
}

interface SetCatererImprintAction {
    type: typeof SET_CATERER_IMPRINT;
    imprint: string;
}

interface SetCatererPrivacyAction {
    type: typeof SET_CATERER_PRIVACY;
    privacy: string;
}

interface SetCatererTermsConditionAction {
    type: typeof SET_CATERER_TERMS_CONDITION;
    termsCondition: string;
}

interface SetCatererLogoAction {
    type: typeof SET_CATERER_LOGO;
    logo: CatererResource;
}

interface SetCatererIconAction {
    type: typeof SET_CATERER_ICON;
    icon: CatererResource;
}

interface SetCatererNewsAction {
    type: typeof SET_CATERER_NEWS;
    news: CatererNews;
}

interface SetCatererTimeManagementAction {
    type: typeof SET_CATERER_TIME_MANAGEMENT;
    timeManagementChild: CatererTimeManagementData;
    timeManagementGroup: CatererTimeManagementData;
}

interface CatererResource {
    data?: string,
    // Indicates if it has been tried to fetch the caterer resource. So we can prevent to show the default resource too early, i.e. while loading the caterer-specific resource.
    tried: boolean
}

export type CatererActions = SetCatererBasicsAction | SetCatererImprintAction | SetCatererPrivacyAction | SetCatererTermsConditionAction
    | SetCatererLogoAction | SetCatererIconAction | SetCatererNewsAction | SetCatererTimeManagementAction;

export const updateCatererBasics = (
    catererId: number,
    companyName: string,
    companyShortName: string,
    menuLimit: number,
    displayName?: string): AppThunk => (dispatch: Dispatch<SetCatererBasicsAction>) => {
    dispatch({
        type: SET_CATERER_BASICS,
        catererId,
        companyName,
        displayName,
        companyShortName,
        menuLimit
    });
};

export const fetchCatererTextResource = (
    catererId: number,
    textType: CatererTextType
): AppThunk => (dispatch: Dispatch<CatererActions>) => {
    publicCatererApi.findCatererTextResources(catererId, [textType]).then(res => {
        if (res.data.success) {
            switch (textType) {
                case CatererTextType.IMPRINT: {
                    dispatch({
                        type: SET_CATERER_IMPRINT,
                        imprint: res.data.result.IMPRINT.textData
                    });
                }
                    break;
                case CatererTextType.PRIVACY: {
                    dispatch({
                        type: SET_CATERER_PRIVACY,
                        privacy: res.data.result.PRIVACY.textData
                    });
                }
                    break;
                case CatererTextType.TERMS_CONDITION: {
                    dispatch({
                        type: SET_CATERER_TERMS_CONDITION,
                        termsCondition: res.data.result.TERMS_CONDITION.textData
                    });
                }
                    break;
            }
        }
    });
};

export const updateCatererLogo = (data?: string): AppThunk => (dispatch: Dispatch<SetCatererLogoAction>) => {
    dispatch({
        type: SET_CATERER_LOGO,
        logo: {
            data,
            tried: true
        }
    });
};

export const updateCatererIcon = (data?: string): AppThunk => (dispatch: Dispatch<SetCatererIconAction>) => {
    dispatch({
        type: SET_CATERER_ICON,
        icon: {
            data,
            tried: true
        }
    });
};

export const setCatererNews = (news: CatererNews): AppThunk => (dispatch: Dispatch<SetCatererNewsAction>) => {
    dispatch({
        type: SET_CATERER_NEWS,
        news
    });
};

export const setCatererTimeManagement = (data: Array<CatererTimeManagementData>): AppThunk => (dispatch: Dispatch<SetCatererTimeManagementAction>) => {
    const childEntry = data.find(el => el.timeManagementType === 'CHILD');
    const groupEntry = data.find(el => el.timeManagementType === 'GROUP');
    if (!childEntry || !groupEntry) {
        console.warn('Invalid caterer time management data');
        return;
    }

    dispatch({
        type: SET_CATERER_TIME_MANAGEMENT,
        timeManagementChild: childEntry,
        timeManagementGroup: groupEntry
    });
};
