import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {resetCarrierTableFilter} from '../../../administration/action/carrierFilterActions';
import {resetInstitutionTableFilter} from '../../../administration/action/institutionFilterActions';
import {resetSupportData} from '../../../administration/support/redux/supportDataActions';
import LogoutIcon from '../../icons/LogoutIcon';
import {DefaultState} from '../../reducer/reducers';
import {logOut} from '../../util/UserSessionUtils';
import ConfirmationDialog from '../form/ConfirmationDialog';
import NavigationAdmin from './NavigationAdmin';
import NavigationCarrier from './NavigationCarrier';
import NavigationCaterer from './NavigationCaterer';
import NavigationInstitution from './NavigationInstitution';
import NavigationParent from './NavigationParent';
import NavigationSupport from './NavigationSupport';

function NavigationLoggedIn() {
    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const role = useSelector((state: DefaultState) => state.user.login ? state.user.login.role : null);
    const hasChanges = useSelector((state: DefaultState) => state.pageLeave.hasUnsavedChanges);
    const [isLogoutConfirmationVisible, setIsLogoutConfirmationVisible] = useState(false);

    function doLogOut() {
        dispatch(resetInstitutionTableFilter());
        dispatch(resetCarrierTableFilter());
        dispatch(resetSupportData());
        setIsLogoutConfirmationVisible(false);
        logOut(dispatch, () => history.push('/'));
    }

    function handleLogout(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        if (hasChanges) {
            setIsLogoutConfirmationVisible(true);
        } else {
            doLogOut();
        }
    }

    function cancelLogOut() {
        setIsLogoutConfirmationVisible(false);
    }

    return (
        <>
            {
                role &&
                {
                    'PARENT': <NavigationParent/>,
                    'INSTITUTION': <NavigationInstitution/>,
                    'CARRIER': <NavigationCarrier/>,
                    'CATERER': <NavigationCaterer/>,
                    'SUPPORT': <NavigationSupport/>,
                    'ADMINISTRATOR': <NavigationAdmin/>
                }[role]
            }

            <a id="icon-nav-link" href="#" onClick={handleLogout}>
                <div className="nav-link">
                    <span className="nav-text ml-4 ml-lg-0">
                        <span className="mr-2">
                            <LogoutIcon/>
                        </span>
                        {t('Navigation.LOGOUT')}
                    </span>
                </div>
            </a>

            {/* PageLeaveGuard cannot be used to handle unsaved changes for the logout:*/}
            {/* - It is only triggered on navigating to other pages, but the logout is mainly a back-end request.*/}
            {/* - There would be multiple Prompt's in parallel: One in the Header component and one in the respective edit component.*/}
            {/*   Thus, PageLeaveGuard would have to be installed centrally. This would affect the navigation of the whole application.*/}
            {/* => As long as the logout is the only case with special needs, we use a separate ConfirmationDialog for it.*/}
            <ConfirmationDialog
                open={isLogoutConfirmationVisible}
                title={t('Common.UNSAVED_CHANGES_TITLE')}
                body={t('Common.UNSAVED_CHANGES_ON_LOGOUT_MESSAGE')}
                onConfirm={doLogOut}
                onCancel={cancelLogOut}
                confirmLabel={t('Common.UNSAVED_CHANGES_DISCARD')}
                cancelLabel={t('Common.UNSAVED_CHANGES_GO_BACK')}
            />
        </>
    );
}

export default NavigationLoggedIn;
