import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getClosingDayText} from '../../../order/util/OrderMenuUtil';
import '../../style/Meal.scss';
import {formatDate} from '../../util/DateUtil';
import InfoTooltip from '../InfoTooltip';
import Menu from './Menu';

function Meal({meal}) {
    const [t] = useTranslation();
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);

    if (!meal || !meal.menus) {
        return null;
    }

    let menu = null;
    if (meal.menus.length > 0) {
        const mealWithOrder = meal.menus.find(menuItem => menuItem.order != null);
        menu = !!mealWithOrder ? mealWithOrder : meal.menus[0];
    }

    if (!menu) {
        return null;
    }

    let allergens = [];
    if (menu.meals) {
        const allergenLists = menu.meals.map(aMeal => aMeal.allergens);
        allergenLists.forEach(allergenList => allergens = allergens.concat(allergenList));
    }

    return (
        <div className={`meal orderable${menu.order ? ' is-ordered' : ''}`}>
            <div className="meal-date">{formatDate(currentLanguage, meal.mealDate, true)}</div>
            <div className="card">
                {
                    meal.locked &&
                    <div className="my-auto mx-3 text-center text-muted small">{getClosingDayText(meal, t)}</div>
                }
                {
                    !meal.locked &&
                    menu.order &&
                    <>
                        <div className="card-header">
                            <div className="position-absolute position-top-right mr-2">
                                {
                                    allergens.length > 0 &&
                                    <InfoTooltip showIcon={true} withMargin={false}>
                                        <div className="small">
                                            <strong>{t('Meal.ALLERGENS')}</strong><br/>
                                            {allergens.map(a => a.name).join(', ')}
                                        </div>
                                    </InfoTooltip>
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            <Menu meals={menu.meals}/>
                        </div>
                    </>
                }
                {
                    !meal.locked && !menu.order &&
                    <div className="my-auto mx-3 text-center text-muted small">
                        {t('MealPlan.NO_MEAL_ORDERED')}
                    </div>
                }
            </div>
        </div>
    );
}

export default Meal;
