import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Sonderkost from '../../assets/pdf/Sonderkost.pdf';
import Checkbox from '../../common/component/form/Checkbox';
import TextInput from '../../common/component/form/TextInput';
import InfoIcon from '../../common/icons/InfoIcon';
import {checkValidation} from '../../common/util/ValidationUtil';
import mealPlanManagementApi from '../../mealplan/api/mealPlanManagementApi';
import {showMessage} from '../../message/action/messageActions';
import ChildApi from '../api/ChildApi';
import OnSiteOrderLimits from './OnSiteOrderLimits';
import FoodRestrictions from './FoodRestrictions';
import GroupInput from './GroupInput';
import Welfare from './Welfare';

function ChildSettings({
                           child,
                           onChange,
                           onValidate,
                           showErrors
                       }) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const catererId = useSelector(state => state.user.login.catererId);
    const [validation, setValidation] = useState({
        firstName: true,
        lastName: true,
        group: true,
        allergies: true,
        welfare: true,
        dailyOnSiteOrderLimit: true,
        allergyPdfConfirmation: true
    });
    const [restrictionValidation, setRestrictionValidation] = useState({});
    const [welfareValidation, setWelfareValidation] = useState({});
    const [nutritionClassOptions, setNutritionClassOptions] = useState([]);
    const [isAllergyPdfConfirmationMandatory, setIsAllergyPdfConfirmationMandatory] = useState(false);
    const [isAllergyPdfConfirmed, setIsAllergyPdfConfirmed] = useState(false);

    useEffect(() => {
        validate();
    }, [child, restrictionValidation, welfareValidation, isAllergyPdfConfirmed]);

    useEffect(() => {
        if (child?.group?.institutionId) {
            mealPlanManagementApi.fetchMenuGroupsForInstitution(child.group.institutionId, true, true, false).then((res) => {
                if (res.data.success) {
                    const nutritionClasses = res.data.result.map(menuGroup => menuGroup.nutritionClass)
                        // find unique nutritionClasses
                        .filter((nutritionClass, index, self) => self.findIndex(element => element.id === nutritionClass.id) === index);
                    setNutritionClassOptions(nutritionClasses);
                }
            });
        }
    }, [child.group]);

    function selectAllergyRestriction(restrictions) {
        setIsAllergyPdfConfirmationMandatory(restrictions.findIndex(re => re.isAttestRequired) >= 0);

        const childCopy = {...child};
        if (childCopy) {
            childCopy.allergies = restrictions;
            if (onChange) {
                onChange(childCopy);
            }
        }
    }

    function validate() {
        const newValidation = {
            firstName: !!child.firstName,
            lastName: !!child.lastName,
            group: !!child.groupCode || !!child.group,
            allergies: restrictionValidation,
            welfare: welfareValidation,
            dailyOnSiteOrderLimit: !child.group?.institutionUsesOnSiteOrder || !!child.dailyOnSiteOrderLimit || child.dailyOnSiteOrderLimit === 0,
            allergyPdfConfirmation: !isAllergyPdfConfirmationMandatory || isAllergyPdfConfirmed
        };
        setValidation(newValidation);

        // Inform parent about validation status
        onValidate(checkValidation(newValidation));
    }

    function handleRestrictionValidation(restriction) {
        setRestrictionValidation({
            ...restrictionValidation,
            [restriction.nutritionClassId]: !restriction.verified ? restriction.valid : true
        });
    }

    function handleWelfareChange(childWelfare) {
        if (child && onChange) {
            onChange({
                ...child,
                childWelfare
            });
        }
    }

    function deleteWelfare(welfare) {
        ChildApi.removeWelfare(child.id, welfare.id).then((response) => {
            if (response.data.success) {
                onChange(response.data.result, false);
                dispatch(showMessage('Welfare.DELETE_MESSAGE'));
            }
        });
    }

    return (<>
        <div className="card mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <TextInput
                            label={t('Child.FIRST_NAME')}
                            value={child.firstName}
                            isValid={!showErrors || validation.firstName}
                            required={true}
                            onChange={(value) => {
                                onChange({
                                    ...child,
                                    firstName: value
                                });
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <TextInput
                            label={t('Child.LAST_NAME')}
                            value={child.lastName}
                            isValid={!showErrors || validation.lastName}
                            required={true}
                            onChange={(value) => {
                                onChange({
                                    ...child,
                                    lastName: value
                                });
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <GroupInput
                            group={child.group}
                            groupCode={child.groupCode}
                            onChangeGroup={(value, manualChange = true) => onChange({
                                ...child,
                                group: value,
                                groupCode: value.code
                            }, manualChange)}
                            isValid={!showErrors || validation.group}
                            childName={`${child.firstName} ${child.lastName}`}
                            catererId={catererId}
                            childId={child.id}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="card mb-3">
            <div className="card-body">
                <h3 className="mt-3">{t('Welfare.TITLE')}</h3>
                <Welfare
                    childWelfare={child.childWelfare}
                    onChange={handleWelfareChange}
                    onDelete={deleteWelfare}
                    onValidate={setWelfareValidation}
                    showErrors={showErrors}
                />
            </div>
        </div>
        {
            nutritionClassOptions?.length !== 0 &&
            <div className="card mb-3">
                <div className="card-body">
                    <h3 className="mt-3">{t('ChildSettings.SPECIAL_MEALS')}</h3>
                    <div className="mb-2">
                        {t('ChildSettings.SPECIAL_MEALS_INFO1')}
                        <a href={Sonderkost} target="_blank">{t('ChildSettings.SPECIAL_MEALS_HERE')}</a>
                        {t('ChildSettings.SPECIAL_MEALS_INFO2')}
                        {
                            isAllergyPdfConfirmationMandatory &&
                            <div className="d-flex">
                                <div className="icon-primary-not-clickable mr-2"><InfoIcon/></div>
                                <Checkbox
                                    value={isAllergyPdfConfirmed}
                                    label={t('ChildSettings.CONFIRM_READ_ALLERGY_INFO')}
                                    onChange={setIsAllergyPdfConfirmed}
                                    required={true}
                                    isValid={!showErrors || validation.allergyPdfConfirmation}
                                />
                                {
                                    showErrors && !validation.allergyPdfConfirmation &&
                                    <div className="small text-danger mt-1 ml-2">{t('Common.PLEASE_CONFIRM')}</div>
                                }
                            </div>
                        }
                    </div>

                    <FoodRestrictions
                        allergyRestrictions={child.allergies}
                        handleRestrictionChange={selectAllergyRestriction}
                        handleValidationChange={handleRestrictionValidation}
                        group={child.group}
                        showErrors={showErrors}
                        nutritionClassOptions={nutritionClassOptions}
                        isNew={!child.id}
                    />
                </div>
            </div>
        }
        {
            child.group?.institutionUsesOnSiteOrder &&
            <OnSiteOrderLimits
                child={child}
                onChange={(value) => onChange({
                    ...child,
                    dailyOnSiteOrderLimit: value
                })}
                showValidationError={showErrors && !validation.dailyOnSiteOrderLimit}
            />
        }
    </>);
}

export default ChildSettings;
