const initialState = {
    creditTableFilter: {
        page: 0,
        pageSize: 10,
        bookingDateFrom: null,
        bookingDateTo: null,
        customerNumber: null,
        customerName: null,
        note: null,
        creditTypes: [],
        paymentMethodType: null
    },
    creditTableTransactions: [],
    totalCreditTableTransactions: 0
};

export default function creditReducer(state = initialState, action = {type: ''}) {
    switch (action.type) {
        case 'SET_CREDIT_TABLE_FILTER': {
            if (!action.filter) {
                return {
                    ...state,
                    creditTableFilter: {
                        page: 0,
                        pageSize: 10
                    }
                };
            }
            return {
                ...state,
                creditTableFilter: action.filter
            };
        }
        case 'SET_CREDITS': {
            if (!action.creditTableTransactions || !action.totalCreditTableTransactions) {
                return {
                    ...state,
                    creditTableTransactions: [],
                    totalCreditTableTransactions: 0,
                    creditTableFilter: action.filter ? action.filter : {
                        page: 0,
                        pageSize: 10
                    }
                };
            }
            return {
                ...state,
                creditTableTransactions: action.creditTableTransactions,
                totalCreditTableTransactions: action.totalCreditTableTransactions,
                creditTableFilter: action.filter
            };
        }
    }
    return state;
}
