import axios from 'axios';

const defaultHeader = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
};

export const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: defaultHeader,
    withCredentials: false
});
