import i18n from 'i18next';
import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

type SupportedLocals = 'en' | 'de';
export type SupportedLanguages = 'en-US' | 'de-DE';

interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE;
    language: SupportedLanguages;
}

interface LanguageType {
    value: SupportedLanguages,
    locale: SupportedLocals,
    label: string
}

export interface I18nState {
    currentLanguage: SupportedLanguages,
    currentLocale: SupportedLocals,
    languages: LanguageType[]
}

export type I18nActions = ChangeLanguageAction;

export const changeLanguage = (language: SupportedLanguages): AppThunk => (dispatch: Dispatch<ChangeLanguageAction>) => {
    i18n.changeLanguage(language).then(() => {
        dispatch({
            type: 'CHANGE_LANGUAGE',
            language
        });
    });
};
