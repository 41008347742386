import {bottom, Placement} from '@popperjs/core/lib/enums';
import React, {MouseEvent, ReactNode, useEffect, useRef, useState} from 'react';
import Tooltip from '../component/Tooltip';
import '../style/Icon.scss';

interface Prop {
    className?: string;
    src?: ReactNode;
    title?: string;
    skidding?: number;
    hidden?: boolean;
    onClick?: () => void;
    tooltipPlacement?: Placement;
}

const Icon = (props: Prop) => {
    const iconElement = useRef<HTMLSpanElement>(null);
    const [showInfos, setShowInfos] = useState<boolean>(false);

    let timeoutForMouseLeaveRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    let timeoutForMouseEnterRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (timeoutForMouseLeaveRef.current) {
            clearTimeout(timeoutForMouseLeaveRef.current);
        }
        if (timeoutForMouseEnterRef.current) {
            clearTimeout(timeoutForMouseEnterRef.current);
        }
    }, []);

    const togglePopover = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setShowInfos(!showInfos);
    };

    if (props.hidden) {
        return null;
    }

    const handleMouseEvent = (event: MouseEvent<HTMLSpanElement>) => {
        if (!showInfos && props.title) {
            timeoutForMouseEnterRef.current = setTimeout(() => {
                togglePopover(event);
            }, 500);
        }
    };

    return (
        <div className={`icon-tooltip ${props.className ? props.className : ''}`}
             onClick={props.onClick}>
            {
                props.title && iconElement &&
                <Tooltip
                    isVisible={showInfos}
                    root={iconElement.current}
                    distance={5}
                    skidding={props.skidding}
                    placement={props.tooltipPlacement === undefined ? bottom : props.tooltipPlacement}
                >
                    <span className="pre-wrap">{props.title}</span>
                </Tooltip>
            }
            <span
                ref={iconElement}
                style={{
                    position: 'relative'
                }}
                onMouseLeave={() => {
                    if (props.title) {
                        timeoutForMouseLeaveRef.current = setTimeout(() => {
                            setShowInfos(false);
                        }, 500);
                    }
                }
                }
                onMouseEnter={handleMouseEvent}
            >{props.src}</span>
        </div>
    );

};

export default Icon;
