import {httpClient} from '../../common/api/HttpClient';
import {convertDateToIsoDateString} from '../../common/util/DateUtil';

export const subscripitionApi = {
    createOrUpdate: (subscription) => httpClient.post('subscriptions', subscription),
    createOrUpdateGroupSubscriptions: (groupId, startDate, endDate, subscriptions) => {
        const groupQueryParams = buildGroupQueryParams(startDate, endDate);
        return httpClient.post(`subscriptions/group/${groupId}?${groupQueryParams}`, subscriptions);
    },
    findByChildId: (childId) => httpClient.get(`subscriptions/children/${childId}`),
    findByGroupId: (groupId) => httpClient.get(`subscriptions/group/${groupId}`),
    remove: (subscriptionId) => httpClient.delete(`subscriptions/${subscriptionId}`),
    removeGroupSubscriptions: (groupId) => httpClient.delete((`subscriptions/group/${groupId}`))
};

export const buildGroupQueryParams = (startDate, endDate) => {
    const queryParams = new URLSearchParams();

    if (startDate) {
        queryParams.append('startDate', convertDateToIsoDateString(startDate));
    }
    if (endDate) {
        queryParams.append('endDate', convertDateToIsoDateString(endDate));
    }
    return queryParams.toString();
};

export default subscripitionApi;
