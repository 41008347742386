import React from 'react';
import {Nav} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ChatHelpIcon from '../../icons/ChatHelpIcon';
import LoginIcon from '../../icons/LoginIcon';
import MailIcon from '../../icons/MailIcon';
import SignatureIcon from '../../icons/SignatureIcon';
import IconNavLink from './IconNavLink';

function NavigationLoggedOut() {
    const [t] = useTranslation();

    return (
        <Nav className="ml-4 ml-lg-0">
            <IconNavLink exact={true} to="/" icon={<LoginIcon/>}>{t('Navigation.LOGIN')}</IconNavLink>
            <IconNavLink exact={true} to="/register" icon={<SignatureIcon/>}>{t('Navigation.REGISTER')}</IconNavLink>
            <IconNavLink exact={true} to="/inquiry" icon={<MailIcon/>}>{t('Navigation.INQUIRY')}</IconNavLink>
            <IconNavLink exact={true} to="/faq-and-contact" icon={<ChatHelpIcon/>}>{t('Navigation.FAQ')}</IconNavLink>
        </Nav>
    );
}

export default NavigationLoggedOut;
