import {Reducer} from 'react';
import {InitialAction} from '../../common/reducer/reducers';
import {TaskFilterType} from '../../types/filter/TaskFilterType';
import {Task} from '../../types/Task';
import {emptyFilter, SET_TASKBOARD_FILTER, SET_TASKS, TaskActions} from '../action/taskActions';

type TaskState = {
    tasks: Task[]
    total: number
    filter: TaskFilterType
}
const initialState = {
    tasks: [],
    total: 0,
    filter: emptyFilter
};

const taskReducer: Reducer<TaskState, TaskActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case SET_TASKS: {
            return {
                ...state,
                tasks: action.tasks,
                total: action.total,
                filter: action.filter
            };
        }
        case SET_TASKBOARD_FILTER: {
            return {
                ...state,
                filter: action.filter
            };
        }
    }

    return state;
};

export default taskReducer;
