import {httpClient} from '../../common/api/HttpClient';
import {convertDateToIsoDateString} from '../../common/util/DateUtil';
import {TaskFilterType} from '../../types/filter/TaskFilterType';
import {Task} from '../../types/Task';

export const taskApi = {
    findById: (taskId: number) => httpClient.get(`tasks/${taskId}`),
    findTaskDocumentsById: (taskId: number) => httpClient.get(`tasks/${taskId}/documents`),
    findAllCaterers: () => httpClient.get(`tasks/caterer`),
    downloadTaskDocumentById: (documentId: number) => httpClient.get(`tasks/documents/${documentId}`, {responseType: 'blob'}),
    removeTaskDocumentById: (documentId: number) => httpClient.delete(`tasks/documents/${documentId}`),
    findAll: () => httpClient.get('tasks/'),
    createOrUpdate: (task: Task) => httpClient.post('tasks', task),
    search: (filter: TaskFilterType) => {
        if (!filter.page) {
            filter.page = 0;
        }
        if (!filter.pageSize) {
            filter.pageSize = 10;
        }
        const queryParams = searchCriteriaToQueryParams(filter);
        return httpClient.get(`tasks/search?${queryParams}`);
    },
    uploadTaskDocument: (taskId: number, file: File) => httpClient.post(`tasks/${taskId}/documents`, file),
    findMyTaskTypes: () => httpClient.get('tasks/task-types'),
    checkBillingAddressUsage: (billingAddressId: number) => httpClient.get(`tasks/billing-addresses/${billingAddressId}`)
};

export const searchCriteriaToQueryParams = (filter: TaskFilterType) => {
    const queryParams = new URLSearchParams();

    if (filter) {
        if (filter.page >= 0) {
            queryParams.append('page', `${filter.page}`);
        }
        if (filter.pageSize) {
            queryParams.append('pageSize', `${filter.pageSize}`);
        }
        if (filter.taskType) {
            queryParams.append('taskType', filter.taskType.value);
        }
        if (filter.taskState) {
            queryParams.append('taskState', filter.taskState.value);
        }
        if (filter.text) {
            queryParams.append('text', filter.text);
        }
        if (filter.from) {
            queryParams.append('from', convertDateToIsoDateString(filter.from));
        }
        if (filter.to) {
            queryParams.append('to', convertDateToIsoDateString(filter.to));
        }
    }
    return queryParams.toString();
};

export default taskApi;
