import {Reducer} from 'redux';
import {DomainContextActions, SET_DOMAIN_TYPE} from '../action/domainContextActions';
import {InitialAction} from './reducers';

type DomainContextState = {
    domainType: string | null
}
const initialState: DomainContextState = { domainType: null };

const domainContextReducer: Reducer<DomainContextState, DomainContextActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    if (action.type === SET_DOMAIN_TYPE) {
        return {
            ...state,
            domainType: action.domainType
        };
    }

    return state;
};

export default domainContextReducer;
