import React from 'react';
import {useTranslation} from 'react-i18next';
import CheckIcon from '../../common/icons/CheckIcon';
import Icon from '../../common/icons/Icon';
import '../style/OrderTypeSwitch.scss';
import OrderTypeAdvantages from './OrderTypeAdvantages';

function OrderTypeOption({
                             orderOption,
                             isSelected,
                             onSelect,
                             isInstitution,
                             catererId
                         }) {
    const [t] = useTranslation();
    return (
        <div className="col-12 col-md-6" key={orderOption}>
            <div className={`card h-100${isSelected ? ' selected' : ''}`}
                 onClick={onSelect}>
                <div className="card-header">
                    <div className="row no-gutters">
                        <div className="offset-1 col-10 text-center">
                            {t(`OrderOption.${orderOption}`)}

                        </div>
                        {
                            isSelected &&
                            <div className="col-1 text-right">
                                <Icon src={<CheckIcon/>}/>
                            </div>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <OrderTypeAdvantages orderOption={orderOption} isInstitution={isInstitution} catererId={catererId}/>
                </div>
            </div>
        </div>
    );
}

export default OrderTypeOption;
