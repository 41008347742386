import React, {useEffect, useRef, useState} from 'react';
import {Overlay} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ArrowSortDownIcon from '../../icons/ArrowSortDownIcon';
import ArrowSortUpIcon from '../../icons/ArrowSortUpIcon';
import Icon from '../../icons/Icon';
import '../../style/Pagination.scss';

const TableHeaderColumn = ({
                               column
                           }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);
    const [t] = useTranslation();
    let timerRef = useRef(null);

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    return (
        <th scope="col"
            className={column.isSorted ? (column.isSortedDesc ? 'col-sort-desc' : 'col-sort-asc') : ''}
            {...column.getHeaderProps({
                ...column.getSortByToggleProps(),
                style: {
                    minWidth: column.minWidth,
                    width: column.width
                }
            })}
            title="">
            {
                column.canSort ?
                    <>
                        <div className="sort-header" ref={target}
                             onMouseEnter={() => {
                                 if (!showTooltip) {
                                     timerRef.current = setTimeout(() => {
                                         setShowTooltip(true);
                                     }, 500);
                                 }
                             }}
                             onMouseLeave={() => setTimeout(() => {
                                 setShowTooltip(false);
                             }, 500)}>
                            {column.render('Header')}
                            {
                                column.isSorted && (<Icon className="float-right" src={column.isSortedDesc ? <ArrowSortUpIcon size={0.95}/> : <ArrowSortDownIcon size={0.95}/>}/>)
                            }
                        </div>
                        <Overlay target={target.current} show={showTooltip} placement="top">
                            {({
                                  placement,
                                  arrowProps,
                                  show: _show,
                                  popper,
                                  ...props
                              }) => (
                                <div
                                    {...props}
                                    className="table-header-tooltip"
                                >
                                    {t('Table.SORT_BY', {column: column.render('Header')})}
                                </div>
                            )}
                        </Overlay>
                    </>
                    : column.render('Header')
            }
        </th>
    );
};

export default TableHeaderColumn;
