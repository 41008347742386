import React from 'react';

const WrenchIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M10.5001 7.75098C10.5001 4.57534 13.0745 2.00098 16.2501 2.00098C17.196
                  2.00098 18.0906 2.22996 18.8794 2.63617C19.0924 2.74583 19.2401 2.95058
                  19.2771 3.18723C19.314 3.42387 19.2358 3.66393 19.0664 3.83329L16.3006
                  6.59906L17.3613 7.65972L20.1398 4.88117C20.3081 4.71295 20.5461 4.63451
                  20.7814 4.6698C21.0167 4.70508 21.2213 4.84991 21.3327 5.06008C21.759 5.86374
                  22.0001 6.78031 22.0001 7.75098C22.0001 10.9266 19.4258 13.501 16.2501 13.501C15.7895
                  13.501 15.3408 13.4467 14.9104 13.3439L7.54552 20.9628C6.42372 22.1232 4.59212 22.2091
                  3.36665 21.1587C2.03293 20.0155 1.93771 17.9851 3.15859 16.7221L10.6339 8.989C10.5462
                  8.58971 10.5001 8.17537 10.5001 7.75098ZM16.2501 3.50098C13.9029 3.50098 12.0001 5.40377
                  12.0001 7.75098C12.0001 8.18294 12.0643 8.59854 12.1832 8.98949C12.2624 9.25007 12.1942
                  9.53312 12.0049 9.72894L4.23708 17.7646C3.61648 18.4066 3.66488 19.4387 4.34283 20.0198C4.96577
                  20.5538 5.8968 20.5101 6.46704 19.9202L14.1435 11.9791C14.3435 11.7722 14.6443 11.698 14.9176
                  11.7881C15.3358 11.926 15.7834 12.001 16.2501 12.001C18.5973 12.001 20.5001 10.0982 20.5001
                  7.75098C20.5001 7.40883 20.4598 7.0766 20.3838 6.75854L17.8916 9.25071C17.5987 9.5436 17.1238
                  9.5436 16.831 9.25071L14.7096 7.12939C14.4167 6.8365 14.4167 6.36162 14.7096 6.06873L17.1763
                  3.60203C16.8785 3.53589 16.5686 3.50098 16.2501 3.50098Z"
            />
        </svg>

    );
};

export default WrenchIcon;
