import React from 'react';

const PreviewIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M3.87218 11.6184C5.33545 8.6025 8.42586 6.52579 11.9999 6.52579C15.574 6.52579 18.6645 8.60262 20.1277
                  11.6187C20.2462 11.8629 20.2461 12.1479 20.1277 12.3921C18.6644 15.408 15.574 17.4847 12 17.4847C8.42587 17.4847
                  5.33538 15.4079 3.87217 12.3918C3.75371 12.1476 3.75371 11.8626 3.87218 11.6184ZM11.9999 5.02579C7.82963 5.02579
                  4.22715 7.45049 2.52263 10.9636C2.20357 11.6213 2.20356 12.3889 2.5226 13.0465C4.22705 16.5599 7.82962 18.9847
                  12 18.9847C16.1702 18.9847 19.7727 16.56 21.4772 13.0469C21.7963 12.3892 21.7963 11.6216 21.4773 10.964C19.7728
                  7.45064 16.1702 5.02579 11.9999 5.02579ZM16 12C16 9.79088 14.2091 8 12 8C9.79086 8 8 9.79088 8 12C8 14.2092 9.79086
                  16 12 16C14.2091 16 16 14.2092 16 12ZM9.5 12C9.5 10.6193 10.6193 9.49998 12 9.49998C13.3807 9.49998 14.5 10.6193 14.5
                  12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12Z"/>
        </svg>
    );
};

export default PreviewIcon;
