// @ts-ignore
import * as PopperJS from '@popperjs/core';
import {Placement} from '@popperjs/core/lib/enums';
import React, {useRef} from 'react';
import {usePopper} from 'react-popper';
import '../style/Tooltip.scss';

interface Prop {
    skidding?: number,
    distance?: number,
    root: HTMLButtonElement | HTMLSpanElement | null,
    placement: Placement,
    isVisible: boolean,
    children: React.ReactNode
}

const Tooltip = (props: Prop) => {

    const popperElement = useRef<HTMLInputElement>(null);

    const skidding = props.skidding ? props.skidding : 0;
    const distance = props.distance ? props.distance : 0;

    // for more modifiers see https://popper.js.org/docs/v2/modifiers/
    const {
        styles,
        attributes
    } = usePopper(props.root, popperElement.current, {
        placement: props.placement,
        modifiers: [{
            name: 'popperOffsets'
        }, {
            name: 'hide'
        }, {
            name: 'offset',
            options: {
                offset: [skidding, distance]
            }
        }]
    });

    return (
        <div ref={popperElement}
             style={styles.popper}
             className={`oc-tooltip ${props.isVisible ? 'tooltip-visible' : 'tooltip-hide'}`}
             {...attributes.popper}>
            <span style={{width: 'max-content'}} className="icon-tooltip-body">{props.children}</span>
        </div>
    );
};

Tooltip.defaultProps = {
    placement: 'bottom'
};

export default Tooltip;
