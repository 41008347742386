export function sortCaseInsensitive(a, b) {
    if (a && !b) {
        return 1;
    }
    if (!a && b) {
        return -1;
    }
    if (a && b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }
    return 0;
}

export function sortPerson(person1, person2) {
    if (person1 && person2) {
        return sortCaseInsensitive(`${person1.lastName} ${person1.firstName}`, `${person2.lastName} ${person2.firstName}`);
    }
    return 0;
}

export function sortDates(a, b) {
    if (!a && !b) {
        return 0;
    }

    if (!a && b) {
        return -1;
    }

    if (a && !b) {
        return 1;
    }

    let date1 = a;
    let date2 = b;

    if (!(a instanceof Date)) {
        date1 = new Date(a);
    }

    if (!(b instanceof Date)) {
        date2 = new Date(b);
    }
    if (date1.getTime() === date2.getTime()) {
        return 0;
    }

    return date1.getTime() > date2.getTime() ? 1 : -1;
}

export function sortByKey(a, b, key) {
    return sortCaseInsensitive(a[key], b[key]);
}
