import {Dispatch} from 'react';
import {AppThunk} from '../reducer/reducers';

export const COUNT_PAGE_CLICKED = 'COUNT_PAGE_CLICKED';

interface CountPageClickedAction {
    type: typeof COUNT_PAGE_CLICKED;
}

export type PageClickActions = CountPageClickedAction

export const pageClicked = (): AppThunk => (dispatch: Dispatch<CountPageClickedAction>) => {
    dispatch({
        type: COUNT_PAGE_CLICKED
    });
};
