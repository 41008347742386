import React from 'react';
import {Nav, NavDropdown} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import ClipboardTaskIcon from '../../icons/ClipboardTaskIcon';
import DocumentIcon from '../../icons/Document';
import HomeIcon from '../../icons/HomeIcon';
import PersonIcon from '../../icons/PersonIcon';
import ServingIcon from '../../icons/ServingIcon';
import SettingIcon from '../../icons/SettingIcon';
import WrenchIcon from '../../icons/WrenchIcon';
import IconNavLink from './IconNavLink';

function NavigationCaterer({
                               additionalOptions = null,
                               additionalActiveOptions = false,
                               isAdmin = false
                           }) {
    const [t] = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const isManagementDropdownActive = location.pathname && (location.pathname.includes('/carriers') ||
        location.pathname.includes('/billing-addresses') ||
        location.pathname.includes('/delivery-groups') ||
        location.pathname.includes('/parents') ||
        location.pathname.includes('/communication') ||
        location.pathname.includes('/credits') ||
        (isAdmin && location.pathname.includes('/institutions')) ||
        additionalActiveOptions
    );

    const isMoreDropdownActive = location.pathname && (location.pathname.includes('/bills') ||
        location.pathname.includes('/menu') ||
        location.pathname.includes('/caterer-profile')
    );

    function buildDropdownLabel(icon, label, isActive) {
        return (
            <span id="icon-nav-dropdown" className={isActive ? 'active' : ''}>
                <span className="nav-dropdown-icon">
                    {icon}
                </span>
                {label}
            </span>
        );
    }

    function handleItemClick(destiny) {
        history.push(destiny);
    }

    const buildManagementNavLinks = () => {
        return (
            <>
                {
                    isAdmin &&
                    <NavDropdown.Item onClick={() => handleItemClick('/institutions')} className="dropdown-navigation-link">
                        {t('Navigation.INSTITUTION_ADMINISTRATION')}
                    </NavDropdown.Item>
                }
                <NavDropdown.Item onClick={() => handleItemClick('/carriers')}
                                  className={`dropdown-navigation-link ${location.pathname.includes('/carriers') ? 'active' : ''}`}>
                    {t('Navigation.CARRIER_ADMINISTRATION')}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleItemClick('/billing-addresses')}
                                  className={`dropdown-navigation-link ${location.pathname.includes('/billing-addresses') ? 'active' : ''}`}>
                    {t('Navigation.BILLING_ADDRESSES')}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleItemClick('/delivery-groups')}
                                  className={`dropdown-navigation-link ${location.pathname.includes('/delivery-groups') ? 'active' : ''}`}>
                    {t('Navigation.DELIVERY_GROUPS')}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleItemClick('/communication')}
                                  className={`dropdown-navigation-link ${location.pathname.includes('/communication') ? 'active' : ''}`}>
                    {t('Navigation.COMMUNICATION')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleItemClick('/credits')}
                                  className={`dropdown-navigation-link ${location.pathname.includes('/credits') ? 'active' : ''}`}>
                    {t('Navigation.CREDIT')}</NavDropdown.Item>
                {additionalOptions}
            </>
        );
    };

    return (
        <>
            <Nav className="ml-4 ml-lg-0">
                <IconNavLink to="/tasks" icon={<ClipboardTaskIcon/>}>{t('Navigation.TASKBOARD')}</IconNavLink>
                {
                    isAdmin ?
                        <IconNavLink to={'/caterer'} icon={<HomeIcon/>}>{t('Navigation.CATERER')}</IconNavLink>
                        :
                        <IconNavLink to="/institutions" icon={<HomeIcon/>}>{t('Navigation.INSTITUTION_ADMINISTRATION')}</IconNavLink>
                }

                {/*Show "Management"-Links as a Dropdown for big and medium screens*/}
                <Nav className="d-none d-lg-flex ">
                    <NavDropdown id="management"
                                 title={buildDropdownLabel(<WrenchIcon/>, t('Navigation.MANAGE'), isManagementDropdownActive)}>
                        {buildManagementNavLinks()}
                    </NavDropdown>
                </Nav>
                {/*Else show "Management"-Links as a list*/}
                <Nav className="d-flex d-lg-none">
                    <div className="mb-4">
                        {buildDropdownLabel(<WrenchIcon/>, t('Navigation.MANAGE'), isManagementDropdownActive)}
                    </div>
                    <div className="ml-5">
                        {buildManagementNavLinks()}
                    </div>
                </Nav>
                {
                    isAdmin ?
                        <>
                            <IconNavLink to="/bills" keyValue="/bills" icon={<DocumentIcon/>}>{t('Finance.BILLS')}</IconNavLink>
                            <IconNavLink to="/admin" keyValue="/admin" icon={<SettingIcon/>}>{t('Admin.HEADER')}</IconNavLink>
                        </>
                        :
                        <>
                            {/*Show "More"-Links for big screen and burger menu*/}
                            <Nav className="d-flex d-lg-none d-xl-flex">
                                <IconNavLink to="/bills" keyValue="/bills" icon={<DocumentIcon/>}>{t('Finance.BILLS')}</IconNavLink>
                                <IconNavLink to="/menu" keyValue="/menu" icon={<ServingIcon/>}>{t('Navigation.MENU')}</IconNavLink>
                                <IconNavLink exact={true} to="/caterer-profile" keyValue="/caterer-profile" icon={<PersonIcon/>}>{t('Navigation.PROFILE')}</IconNavLink>

                            </Nav>
                            {/*Else show "More"-Dropdown*/}
                            <Nav className="d-none d-lg-flex d-xl-none">
                                <NavDropdown id="more" title={buildDropdownLabel(null, t('Navigation.MORE'), isMoreDropdownActive)}>
                                    <NavDropdown.Item onClick={() => handleItemClick('/bills')}
                                                      className={`dropdown-navigation-link ${location.pathname.includes('/bills') ? 'active' : ''}`}>
                                        {t('Finance.BILLS')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleItemClick('/menu')}
                                                      className={`dropdown-navigation-link ${location.pathname.includes('/menu') ? 'active' : ''}`}>
                                        {t('Navigation.MENU')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleItemClick('/caterer-profile')}
                                                      className={`dropdown-navigation-link ${location.pathname.includes('/caterer-profile') ? 'active' : ''}`}>
                                        {t('Navigation.PROFILE')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </>
                }
            </Nav>
        </>

    );
}

export default NavigationCaterer;
