import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';
import {FinanceConfig} from '../../types/finance/FinanceConfig';
import financeApi from '../api/financeApi';

export const FETCH_FINANCE_CONFIG = 'FETCH_FINANCE_CONFIG';

interface FetchFinanceConfigAction {
    type: typeof FETCH_FINANCE_CONFIG;
    financeConfig: FinanceConfig;
}

export type FinanceConfigActions = FetchFinanceConfigAction;

export const fetchFinanceConfig = (): AppThunk =>
    (dispatch: Dispatch<FetchFinanceConfigAction>) => {
        financeApi.fetchFinanceConfig().then(res => {
            if (res.data.success && res.data.result) {
                const result = res.data.result;
                dispatch({
                    type: FETCH_FINANCE_CONFIG,
                    financeConfig: {
                        FINANCE_SEPA_ACTIVE: result.FINANCE_SEPA_ACTIVE === 'true',
                        FINANCE_INFO_ACTIVE: result.FINANCE_INFO_ACTIVE === 'true',
                        FINANCE_FALLBACK_BANK_DATA: result.FINANCE_FALLBACK_BANK_DATA,
                        FINANCE_SCHEDULED_SEPA_ACTIVE: result.FINANCE_SCHEDULED_SEPA_ACTIVE === 'true',
                        FINANCE_FEE_ACTIVE: result.FINANCE_FEE_ACTIVE === 'true',
                        FINANCE_ALLOW_BANK_TRANSFER_WITHOUT_APPROVAL: result.FINANCE_ALLOW_BANK_TRANSFER_WITHOUT_APPROVAL === 'true'
                    }
                });
            }
        });
    };
