import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import transactionApi from '../../administration/api/transactionApi';
import CancelButton from '../../common/component/CancelButton';
import ConfirmButton from '../../common/component/ConfirmButton';
import CheckIcon from '../../common/icons/CheckIcon';
import Icon from '../../common/icons/Icon';
import {DefaultState} from '../../common/reducer/reducers';
import {currencyFormatter} from '../../common/util/CurrencyUtil';
import {showMessage} from '../../message/action/messageActions';
import {PaymentMethod} from '../../types/payment/PaymentMethod';
import withdrawalApi from '../api/withdrawalApi';
import '../style/WithdrawalPaymentSelection.scss';
import {PaymentMethodType} from './PaymentMethodTypes';

interface BalanceWithdrawalByPaymentProps {
    paymentMethods: PaymentMethod[];
    withdrawalAmount: number;
    onDone: () => void,
    onCancel?: () => void,
    isAccountDeletion?: boolean,
    buttonPlacement?: string
}

function BalanceWithdrawalByPaymentSelection({paymentMethods, withdrawalAmount, onDone, onCancel, isAccountDeletion = false, buttonPlacement = 'end'}: BalanceWithdrawalByPaymentProps) {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const account = useSelector((state: DefaultState) => state.user.account);
    const formatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
    const [refundDone, setRefundDone] = useState(false);

    const buttonPlacementClassName = buttonPlacement !== 'start' ? 'justify-content-end' : 'justify-content-start';

    const [showError, setShowError] = useState(false);
    useEffect(() => {
        if (paymentMethods && paymentMethods.length === 1) {
            setSelectedPaymentMethod(paymentMethods[0]);
        }
    }, [paymentMethods]);

    const handleCancel = useCallback(() => {
        if (onCancel) {
            setSelectedPaymentMethod(undefined);
            onCancel();
        }
    }, [onCancel]);

    const triggerInstantRefund = useCallback(() => {
        if (selectedPaymentMethod) {
            withdrawalApi.triggerInstantPayout([{
                stripeId: selectedPaymentMethod.stripeId,
                amount: withdrawalAmount
            }]).then((withdrawalResponse) => {
                if (withdrawalResponse.data.success) {
                    setRefundDone(true);
                    dispatch(showMessage('Finance.BALANCE_WITHDRAWAL_PAYMENT_SUCCESS',
                        {amount: formatter.format(withdrawalAmount)}));
                    onDone();
                }
            });
        } else {
            setShowError(true);
        }
    }, [selectedPaymentMethod, withdrawalAmount, formatter, onDone, dispatch]);

    const cancelOrdersAndRequestRefund = useCallback(() => {
        if (selectedPaymentMethod && account) {
            /* For the withdrawal request, the backend has to check the withdrawal amount against the current balance.
             * So first increase the balance by cancelling all orders. */
            transactionApi.cancelOrdersForAccount(account.id).then(cancelResponse => {
                if (cancelResponse.data.success) {
                    triggerInstantRefund();
                }
            });
        } else {
            setShowError(true);
        }
    }, [selectedPaymentMethod, account, triggerInstantRefund]);

    const handleRefund = useCallback(() => {
        if (isAccountDeletion) {
            cancelOrdersAndRequestRefund()
        } else {
            triggerInstantRefund();
        }
    }, [isAccountDeletion, cancelOrdersAndRequestRefund, triggerInstantRefund]);

    const handleSelectPaymentMethod = useCallback((paymentMethod: PaymentMethod) => {
        if (!refundDone) {
            setSelectedPaymentMethod(paymentMethod);
            setShowError(false);
        }
    }, [refundDone]);

    const renderPaymentDetails = useCallback((paymentMethodData) => {
        if (paymentMethodData.paymentType === PaymentMethodType.CUSTOMER_BALANCE) {
            return <div className="payment-details">
                <span className="payment-details-text">{t('Finance.BALANCE_WITHDRAWAL_BANK_TRANSFER_DETAILS')}</span>
            </div>
        }

        return <div className="payment-details">
            {paymentMethodData.paymentType === PaymentMethodType.SEPA_DIRECT_DEBIT || paymentMethodData.paymentType === PaymentMethodType.CREDIT_CARD ? 'XXXXXXXXXXXXXXXX ' : ''}
            <span className="payment-details-text">{paymentMethodData.paymentDetails}</span>
        </div>;
    }, [t]);

    return (<>
        <div>
            {t('Finance.BALANCE_WITHDRAWAL_BY_PAYMENT_INSTRUCTION')}
        </div>
        <div className="withdraw-amount-card mt-3">
            {t('Finance.BALANCE_WITHDRAWAL_BY_PAYMENT_AMOUNT_TITLE')}:&nbsp;
            <span className="bold-primary-text ">{formatter.format(withdrawalAmount)}</span>
            {
                onCancel &&
                <span className="float-right bold-primary-text change-button" onClick={handleCancel}>
                    {t('Finance.BALANCE_WITHDRAWAL_BY_PAYMENT_AMOUNT_CHANGE')}
                </span>
            }
        </div>
        {
            showError && <div className="error-text pt-3">{t('Finance.BALANCE_WITHDRAWAL_PAYMENT_ERROR')}</div>
        }
        <div className="row mt-3 mb-4">
            {
                paymentMethods.map(paymentMethod =>
                    <div className="col-12 col-xl-6 mb-3" key={`payment-method-selection-${paymentMethod.stripeId}`}>
                        <div className={`payment-item ${selectedPaymentMethod?.stripeId === paymentMethod.stripeId ? 'selected' : ''}`}
                             onClick={() => handleSelectPaymentMethod(paymentMethod)}>
                            <div className="grey-line"></div>
                            {selectedPaymentMethod?.stripeId === paymentMethod.stripeId && <Icon src={<CheckIcon/>} className="selected-icon"/>}
                            <div className="payment-type">
                                {t(`Credit.PAYMENT_METHOD_TYPE_HEADER_${paymentMethod.paymentType.toUpperCase()}`)}
                            </div>
                            {renderPaymentDetails(paymentMethod)}
                        </div>
                    </div>
                )
            }
        </div>

        <div className={`d-inline-flex w-100 ${buttonPlacementClassName}`}>
            {
                onCancel &&
                <CancelButton onClick={handleCancel}/>
            }
            <ConfirmButton onClick={handleRefund} disabled={refundDone} label={t('Finance.BALANCE_WITHDRAWAL.WITHDRAW_NOW')}/>
        </div>
    </>);
}

export default BalanceWithdrawalByPaymentSelection;
