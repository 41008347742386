import React, {lazy} from 'react';
import {useSelector} from 'react-redux';
import Role from '../../common/enums/Role';
import UserEdit from './user/UserEdit';

const CarrierEdit = lazy(() => import(/* webpackChunkName: "nonparent" */ './carrier/CarrierEdit'));
const Institution = lazy(() => import(/* webpackChunkName: "nonparent" */ './institution/Institution'));

function Profile() {
    const role = useSelector(state => state.user.login ? state.user.login.role : null);
    return (
        <>
            {
                role === Role.PARENT &&
                <UserEdit/>
            }
            {
                role === Role.INSTITUTION &&
                <Institution/>
            }
            {
                role === Role.CARRIER &&
                <CarrierEdit/>
            }
        </>
    );
}

export default Profile;
