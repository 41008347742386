import {httpClient} from '../../common/api/HttpClient';

export const accountApi = {
    findById: (accountId, checkSepaMandate = false) => httpClient.get(`accounts/${accountId}`, {params: {checkSepaMandate}}),
    findByCustomerNumber: (customerNumber) => httpClient.get(`accounts/customer-numbers/${customerNumber}`, {immediate: true}),
    checkDeletable: (accountId, simpleMode = false) => httpClient.get(`accounts/${accountId}/deletable`, {params: {simpleMode}}),
    prepareDeletion: (accountId, balanceWithdrawal) => httpClient.post(`accounts/${accountId}/deletion-preparation`, balanceWithdrawal),
    delete: (id) => httpClient.delete(`accounts/${id}`),
    purge: (id) => httpClient.delete(`accounts/${id}`, {params: {purge: true}}),
    createOrUpdate: (account) => httpClient.post('accounts', account),
    updateLanguage: (accountId, language) => httpClient.post('accounts/language', {accountId, language}),
    register: (account, catererId) => httpClient.post('public/register', account, {params: {catererId}}),
    uploadProofOfIntolerance: (formData) => httpClient.post('tasks/proofOfIntolerance', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }),
    uploadWelfareAppliance: (formData) => httpClient.post('tasks/welfareAppliance', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }),
    fetchPaymentSettings: accountId => httpClient.get(`accounts/${accountId}/payment-settings`),
    fetchAccountsWithDisabledDirectDebit: (page, pageSize) => {
        const queryParams = {
            page,
            pageSize
        };
        return httpClient.get('accounts/direct-debit/disabled-accounts', {params: queryParams});
    },
    clearTransactionReversalHistory: accountId => httpClient.delete(`accounts/${accountId}/transaction-reversal-history`),
    updateBankTransferApproval: (customerNumber, isApproved) => httpClient.post('accounts/bank-transfer-approval', {customerNumber, isApproved})
};

export default accountApi;
