// Locales for react-datepicker
import de from 'date-fns/locale/de';
import enUs from 'date-fns/locale/en-US';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import '../../style/DateInput.scss';

registerLocale('de-DE', de);
registerLocale('en-US', enUs);

export default function DateInput(props) {
    const [t] = useTranslation();
    let errorMessage = null;
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);

    // Show error message for invalid content
    let className = 'form-control';
    if (props.centerText) {
        className += ' text-center';
    }
    if (props.isValid === false && !props.readonly) {
        errorMessage = props.errorMessage ? props.errorMessage : t('Validation.REQUIRED');
        className += ' is-invalid';
    }

    return (
        <div className="form-group">
            <label htmlFor={props.id}>{`${props.label} ${props.required ? '*' : ''}`}</label>
            <div>
                {
                    props.withTime && !props.withYear &&
                    <DatePicker
                        locale={currentLanguage}
                        selected={props.value}
                        onChange={value => props.onChange(value)}
                        disabled={props.readonly}
                        showTimeSelect={props.withTime}
                        timeFormat="HH:mm"
                        timeIntervals={5}
                        timeCaption={t('DateInput.TIME_LABEL')}
                        dateFormat="dd.MM.yyyy HH:mm"
                        className={className}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        isClearable={props.isClearable && !props.readonly}
                        calendarClassName="react-datepicker--with-time"
                        tabIndex={props.tabIndex}
                    />
                }
                {
                    !props.withTime && !props.withYear &&
                    <DatePicker
                        locale={currentLanguage}
                        selected={props.value}
                        disabled={props.readonly}
                        onChange={value => props.onChange(value)}
                        dateFormat="dd.MM.yyyy"
                        className={className}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        isClearable={props.isClearable && !props.readonly}
                        tabIndex={props.tabIndex}
                    />
                }
                {
                    props.withYear && !props.withTime &&
                    <DatePicker
                        locale={currentLanguage}
                        selected={props.value}
                        onChange={value => props.onChange(value)}
                        dateFormat="dd.MM.yyyy"
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        isClearable={props.isClearable && !props.readonly}
                        tabIndex={props.tabIndex}
                        className={className}
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                    />
                }
            </div>
            {
                errorMessage &&
                <div className="invalid-feedback d-block">
                    {errorMessage}
                </div>
            }
        </div>
    );
}

DateInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
    errorMessage: PropTypes.string,
    isValid: PropTypes.bool,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
    minDate: PropTypes.any,
    maxDate: PropTypes.any
};
