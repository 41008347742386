import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {markChanges, unmarkChanges} from '../../../common/action/pageLeaveActions';
import Icon from '../../../common/icons/Icon';
import MinusIcon from '../../../common/icons/MinusIcon';
import PlusIcon from '../../../common/icons/PlusIcon';
import '../../../common/style/Meal.scss';
import '../../style/OrderOrUpdateMealWithLimit.scss';

function OrderOrUpdateMealWithLimit({
                                        menu,
                                        onAddOrUpdate,
                                        onRemove,
                                        notChangeable
                                    }) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [mealAmount, setMealAmount] = useState(menu.orderedAmount ? menu.orderedAmount : 1);
    const [editMode, setEditMode] = useState(false);
    const order = menu.order;
    const readOnly = notChangeable && !menu.orderable && (!order || !order.cancelable);
    useEffect(() => {
        setMealAmount(menu.orderedAmount ? menu.orderedAmount : 1);
    }, [menu]);


    function handleAddOrUpdate() {
        if (mealAmount === 0) {
            onRemove();
        } else {
            onAddOrUpdate({
                ...menu,
                amount: mealAmount
            });
        }

        setEditMode(false);
        dispatch(unmarkChanges());
    }

    function canDeleteMeal() {
        if (order && !order.cancelable && mealAmount <= menu.orderedAmount) {
            return false;
        } else if (!order && mealAmount < 2) {
            return false;
        } else if (mealAmount < 1) {
            return false;
        }
        return true;
    }

    function canOrderMeal() {
        if (mealAmount >= menu.menuLimit) {
            return false;
        } else if (!menu.orderable && mealAmount >= menu.orderedAmount) {
            return false;
        }

        return true;
    }

    function handleClickMinus() {
        if (canDeleteMeal()) {
            if (mealAmount - 1 !== menu.orderedAmount) {
                dispatch(markChanges());
            } else {
                dispatch(unmarkChanges());
            }
            setMealAmount(mealAmount - 1);
        }
    }

    function handleClickPlus() {
        if (canOrderMeal()) {
            if (mealAmount + 1 !== menu.orderedAmount) {
                dispatch(markChanges());
            } else {
                dispatch(unmarkChanges());
            }
            setMealAmount(mealAmount + 1);
        }
    }

    return (
        <div className="order-with-limit">
            <div className="order-amount text-center row">
                {
                    (!readOnly && !(order && !editMode)) &&
                    <Icon src={<MinusIcon/>} className={`col-3 text-right ${canDeleteMeal() ? '' : 'disabled'}`}
                          onClick={handleClickMinus}/>
                }
                <div className={`${order && !editMode ? 'col-12' : 'col-6'}`}>{readOnly && !menu.orderedAmount ? '' : mealAmount}{!editMode && order &&
                    <span>x {t('Order.ORDERED')}</span>}</div>
                {
                    (!readOnly && !(order && !editMode)) &&
                    <Icon src={<PlusIcon/>} className={`col-3 text-right ${canOrderMeal() ? '' : 'disabled'}`}
                          onClick={handleClickPlus}/>
                }
            </div>
            {
                !order && menu.orderable ?
                    <button className="btn btn-block btn-primary btn-sm mt-2" onClick={handleAddOrUpdate}>
                        {t('Button.BUY')}
                    </button>
                    :
                    (order && order.cancelable || menu.orderable) && (editMode ?
                        <button className="btn btn-block btn-primary btn-sm mt-2" disabled={mealAmount === menu.orderedAmount} onClick={handleAddOrUpdate}>
                            {t('Button.UPDATE')}
                        </button> :
                        <button className="btn btn-block btn-secondary btn-sm mt-2" onClick={() => setEditMode(true)}>
                            {t('Button.CHANGE')}
                        </button>)
            }
            {
                readOnly && <div className="text-muted small no-change-possible">{t('Meal.NOT_CHANGEABLE')}</div>
            }

        </div>

    );
}

export default OrderOrUpdateMealWithLimit;


