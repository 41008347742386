import {IbanElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import publicCatererApi from '../../caterer/api/publicCatererApi';
import {HIDE_LOADER, SHOW_LOADER} from '../../common/action/LoaderAction';
import TextInput from '../../common/component/form/TextInput';
import CheckIcon from '../../common/icons/CheckIcon';
import Icon from '../../common/icons/Icon';
import {DefaultState} from '../../common/reducer/reducers';
import {checkValidation} from '../../common/util/ValidationUtil';
import {showError, showMessage} from '../../message/action/messageActions';
import paymentProviderIntegrationApi from '../api/paymentProviderIntegrationApi';

interface Props {
    account: any;
    onSaveCompleted: (setupIntentId: string) => void;
    initialSepaMandate: any;
}

function SepaDirectDebitMandateForm({account, onSaveCompleted, initialSepaMandate}: Props) {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [catererCompanyName, setCatererCompanyName] = useState<string>();
    const catererId = useSelector((state: DefaultState) => state.caterer.catererId);
    const [catererDetails, setCatererDetails] = useState<string>();
    const [creditorIdentifier, setCreditorIdentifier] = useState<string>();
    const [showErrors, setShowErrors] = useState(false);
    const [sepaMandate, setSepaMandate] = useState({
        ...initialSepaMandate,
        payerName: account?.person?.firstName ? `${account.person.firstName} ${account.person.lastName}` : account?.person?.lastName,
        payerEmailAddress: account?.login?.emailAddress
    });

    const initialValidation = {
        payerName: true,
        payerEmailAddress: true,
        iban: true
    };

    const [validation, setValidation] = useState(initialValidation);
    const [isIbanComplete, setIsIbanComplete] = useState(false);

    const loadCatererData = useCallback((theCatererId) => {
        publicCatererApi.findPublicCatererProfileById(theCatererId).then(response => {
            if (response.data.success) {
                const caterer = response.data.result;
                setCatererCompanyName(caterer.companyName);
                let details = '';
                if (caterer.managingDirector) {
                    details += `${t('Caterer.MANAGING_DIRECTOR')}: ${caterer.managingDirector}\n`;
                }
                if (caterer.address.additionalAddressInformation) {
                    details += caterer.address.additionalAddressInformation + '\n';
                }
                details += `${caterer.address.street} ${caterer.address.houseNumber}\n${caterer.address.zip} ${caterer.address.city}`;
                setCatererDetails(details);
                setCreditorIdentifier(caterer.creditorIdentifier);
            }
        });
    }, [t]);

    useEffect(() => {
        if (catererId) {
            loadCatererData(catererId);
        }
    }, [catererId, loadCatererData]);

    const IBAN_ELEMENT_OPTIONS = {
        supportedCountries: ['SEPA'],
        placeholderCountry: 'DE',
        classes: {invalid: 'form-control.is-invalid border-danger'}
    };

    const validate = useCallback(() => {
        const newValidation = {
            payerName: !!sepaMandate.payerName,
            payerEmailAddress: !!sepaMandate.payerEmailAddress,
            iban: isIbanComplete
        };
        setValidation(newValidation);

        return checkValidation(newValidation);
    }, [sepaMandate, isIbanComplete]);

    useEffect(() => {
        validate();
    }, [sepaMandate, validate]);

    useEffect(() => {
        if (elements) {
            const ibanElement = elements.getElement(IbanElement);
            ibanElement?.on('change', event => {
                setIsIbanComplete(event.complete);
            });
        }
    }, [elements]);

    const sendConfirmationToStripe = useCallback((clientSecret) => {
        const ibanElement = elements?.getElement(IbanElement);
        if (!ibanElement) {
            return;
        }

        if (!stripe) {
            return;
        }
        // Show the loader until the confirmCardPayment request in submitPayment is finished. (That request does not use our http client, thus the loader is triggered manually.)
        dispatch({type: SHOW_LOADER});
        stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
                sepa_debit: ibanElement,
                billing_details: {
                    name: sepaMandate.payerName.trim(),
                    email: sepaMandate.payerEmailAddress.trim()
                }
            }
        }).then(result => {
            if (result.error || result.setupIntent?.status !== 'succeeded') {
                let label = 'Finance.STRIPE.SEPA_SETUP_FAILED';
                if (result.error?.code === 'email_invalid') {
                    label = 'Finance.STRIPE.SEPA_SETUP_INVALID_EMAIL';
                } else if (result.error?.code === 'invalid_owner_name') {
                    label = 'Finance.STRIPE.SEPA_SETUP_INVALID_NAME';
                }
                dispatch(showError(label));
            } else {
                dispatch(showMessage('Finance.STRIPE.SEPA_SETUP_SUCCEEDED'));
                onSaveCompleted(result.setupIntent.id);
            }
        }).catch(() => {
            dispatch(showError('Finance.STRIPE.SEPA_SETUP_FAILED'));
        }).finally(() =>
            dispatch({type: HIDE_LOADER})
        );
    }, [sepaMandate, stripe, elements, onSaveCompleted, dispatch]);

    const handleSubmit = useCallback((event) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        if (validate()) {
            paymentProviderIntegrationApi.setupSepaDirectDebit(catererId, account.id).then(response => {
                if (response.data.success) {
                    sendConfirmationToStripe(response.data.result.clientSecret);
                }
            });
        } else {
            dispatch(showError('Error.MISSING_PARAMETER'));
            setShowErrors(true);
        }
    }, [sendConfirmationToStripe, catererId, account.id, stripe, elements, validate, dispatch]);

    function handleChange(key: any, value: any) {
        setSepaMandate({
            ...sepaMandate,
            [key]: value
        });
    }

    return <>
        <div>
            <label className="text-muted">{t('Finance.SEPA.CREDITOR_ADDRESS_HEADER')}</label>
            <p className="pre-wrap">{`${catererCompanyName}\n${catererDetails}`}</p>

            <label className="text-muted">{t('Finance.SEPA.CREDITOR_ID_HEADER')}</label>
            <p>{creditorIdentifier}</p>

            <form onSubmit={handleSubmit} className="mt-5">
                <h4>{t('Finance.SEPA.PAYMENT_DETAILS')}</h4>
                <TextInput
                    label={t('Finance.SEPA.ACCOUNT_HOLDER')}
                    value={sepaMandate.payerName}
                    onChange={value => handleChange('payerName', value)}
                    isValid={!showErrors || validation.payerName}
                    required={true}
                />
                <TextInput
                    label={t('Finance.SEPA.EMAIL_ADDRESS')}
                    value={sepaMandate.payerEmailAddress}
                    onChange={value => handleChange('payerEmailAddress', value)}
                    isValid={!showErrors || validation.payerEmailAddress}
                    required={true}
                />
                <div className="form-group">
                    <label>{t('PaymentInformation.IBAN')}</label>
                    <IbanElement options={IBAN_ELEMENT_OPTIONS}/>
                </div>

                <p className="mb-4 mt-3 permission_info">{t('Finance.SEPA.STRIPE_PERMISSION_INFO', {catererCompanyName})}</p>
                <div className="text-right">
                    <button type="submit" className="btn btn-primary">
                        <Icon src={<CheckIcon/>} className="mr-2"/>
                        {t('Finance.SEPA.SET_UP')}
                    </button>
                </div>
            </form>
        </div>
    </>;
}

export default SepaDirectDebitMandateForm;
