import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import OcTable from '../../common/component/table/OcTable';
import {currencyFormatter} from '../../common/util/CurrencyUtil';
import {formatDate, formatDateAndTime} from '../../common/util/DateUtil';
import {sortDates} from '../../common/util/SortUtil';
import userActivityApi from '../api/userActivityApi';
import Action from '../enums/Action';
import Subject from '../enums/Subject';
import '../style/UserActivityOverview.scss';

const PAGE_SIZE = 25;

function UserActivityOverview() {
    const [t] = useTranslation();
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const currentLogin = useSelector(state => state.user.login);

    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);

    const search = useCallback(({pageIndex}) => {
        userActivityApi.findActivitiesForLoginWithPaging(currentLogin.id, pageIndex, PAGE_SIZE).then(res => {
            if (res.data.success) {
                setData(res.data.result);
                setTotalCount(res.data.total);
            }
        });
    }, [currentLogin]);

    const priceFormatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);

    const formatPropertyValue = useCallback((key, value) => {
        if (value === '<empty>') {
            return t('UserActivity.EMPTY_PROPERTY');
        }

        switch (key.toLowerCase()) {
            case 'payment_amount':
                return priceFormatter.format(value);
            case 'payment_method':
                return t(`Credit.PAYMENT_METHOD_TYPE_HEADER_${value.toUpperCase()}`);
            case 'on_site_order':
                return value ? t('Common.YES') : t('Common.NO');
            case 'start_date':
            case 'end_date':
            case 'pause_start_date':
            case 'pause_end_date':
                return formatDate(currentLanguage, value)
            default:
                return value;
        }
    }, [priceFormatter, t, currentLanguage]);

    const createNoteFromProperties = useCallback(userActivity => {
        return <ul className="pl-2">
            {
                userActivity.properties.map(([key, value]) =>
                    <li key={key}>{`${t('UserActivity.PROPERTY_' + key.toUpperCase())}: ${formatPropertyValue(key, value)}`}</li>
                )
            }
        </ul>;
    }, [formatPropertyValue, t]);

    const getNoteBySubject = useCallback((userActivity) => {
        const labelValues = [...userActivity.labelValues];
        if (userActivity.action !== Action.DELETE || userActivity.subject === Subject.ORDER) {
            switch (userActivity.subject) {
                case Subject.PROFILE: {
                    const name = labelValues.shift();
                    const address = labelValues.shift();
                    const email = labelValues.shift();
                    return (<ul className="mb-0">
                        {name && <li>{`${t('UserActivity.NAME')}: ${name}`}</li>}
                        {address && <li>{`${t('UserActivity.ADDRESS')}: ${address}`}</li>}
                        {email && <li>{`${t('UserActivity.EMAIL')}: ${email}`}</li>}
                    </ul>);
                }
                case Subject.BALANCE_WITHDRAWAL: {
                    const amount = labelValues.shift();
                    return (<>{amount && `${t('UserActivity.AMOUNT')}: ${amount}€`}</>);
                }
                case Subject.CHILD_PROFILE: {
                    const name = labelValues.shift();
                    return (<>{name && `${t('UserActivity.NAME')}: ${name}`}</>);

                }
                case Subject.CHILD_WELFARE: {
                    const department = labelValues.shift();
                    const departmentString = t(`Welfare.${department}`);
                    const from = labelValues.shift();
                    const to = labelValues.shift();
                    return (<ul>
                        {department && <li>{`${t('UserActivity.DEPARTMENT')}: ${departmentString}`}</li>}
                        {from && <li>{`${t('UserActivity.FROM')}: ${from}`}</li>}
                        {to && <li>{`${t('UserActivity.TO')}: ${to}`}</li>}
                    </ul>);
                }
                case Subject.CHILD_ALLERGIES: {
                    const allergy = labelValues.shift();
                    return (<>{allergy && `${t('UserActivity.ALLERGY')}: ${allergy}`}</>);
                }
                case Subject.ORDER: {
                    const date = formatDateAndTime(new Date(labelValues.shift()), 'de-DE', false, false);
                    const isHandledByCaterer = labelValues.shift();
                    return (<ul>
                        {isHandledByCaterer && <li>{t('UserActivity.CATERER_ORDER')}</li>}
                        {date && <li>{`${t('UserActivity.DATE')}: ${date}`}</li>}
                    </ul>);
                }
                case Subject.SUBSCRIPTION: {
                    let from = labelValues.shift();
                    from = from != null ? formatDateAndTime(new Date(from), 'de-DE', false, false) : null;
                    let to = labelValues.shift();
                    to = to != null ? formatDateAndTime(new Date(to), 'de-DE', false, false) : null;
                    let weekdays = null;
                    if (labelValues.length !== 0) {
                        weekdays = labelValues.reduce((finalString, currentString) => `${finalString}, ${currentString}`);
                    }
                    return (<ul>
                        {from && <li>{`${t('UserActivity.FROM')}: ${from}`}</li>}
                        {to && <li>{`${t('UserActivity.TO')}: ${to}`}</li>}
                        {weekdays && <li>{`${t('UserActivity.WEEKDAYS')}: ${weekdays}`}</li>}
                    </ul>);
                }
                case Subject.RFID_BLOCKED: {
                    const rfidBlocked = labelValues.shift();
                    return (
                        <>{rfidBlocked === 'true' ? t('UserActivity.RFID_BLOCKED_TEXT') : t('UserActivity.RFID_UNBLOCKED_TEXT')}</>
                    );
                }
                default :
                    return null;
            }
        }
        return null;
    }, [t]);

    const columns = useMemo(() => [
        {
            Header: t('UserActivity.DATE'),
            accessor: 'date',
            Cell: (props) => {
                return <div className="date-column">{formatDateAndTime(props.value, 'de-DE')}</div>;
            },
            sortType: (a, b) => sortDates(a, b)
        },
        {
            Header: t('UserActivity.AFFECTED_PERSON'),
            accessor: 'affectedItem'
        },
        {
            Header: t('UserActivity.SUBJECT'),
            accessor: 'subject',
            Cell: (props) => {
                const row = props.row.original;
                return t(`UserActivity.${row.subject}`);
            }
        },
        {
            Header: t('UserActivity.DESCRIPTION'),
            accessor: 'action',
            Cell: (props) => {
                const row = props.row.original;
                return t(`UserActivity.${row.subject}_${row.action}`);
            }
        },
        {
            Header: t('UserActivity.NOTES'),
            Cell: (props) => {
                const userActivity = props.row.original;
                return userActivity.properties ? createNoteFromProperties(userActivity) : getNoteBySubject(userActivity);
            }
        }
    ], [createNoteFromProperties, getNoteBySubject, t]);

    return (
        <div className="container">
            <h1>{t('UserActivity.HEADER')}</h1>
            {
                data &&
                <OcTable
                    columns={columns}
                    data={data}
                    fetchData={search}
                    totalCount={totalCount}
                    pageSize={PAGE_SIZE}
                />
            }
        </div>
    );
}

export default UserActivityOverview;
