import React from 'react';

const PersonIcon = ({size = 1}) => {
    return (
        <svg width={25 * size} height={24 * size} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M17.7541 14.0002C18.9961 14.0002 20.0029 15.007 20.0029 16.2491V16.8245C20.0029
                  17.7188 19.6833 18.5836 19.1018 19.263C17.5324 21.0965 15.1453 22.0013 11.9999
                  22.0013C8.85401 22.0013 6.468 21.0962 4.9017 19.2619C4.32194 18.583 4.00342
                  17.7195 4.00342 16.8267V16.2491C4.00342 15.007 5.01027 14.0002 6.25229
                  14.0002H17.7541ZM17.7541 15.5002H6.25229C5.8387 15.5002 5.50342 15.8355
                  5.50342 16.2491V16.8267C5.50342 17.3624 5.69453 17.8805 6.04239 18.2878C7.29569
                  19.7555 9.26157 20.5013 11.9999 20.5013C14.7382 20.5013 16.7058 19.7555 17.9623
                  18.2876C18.3112 17.8799 18.5029 17.361 18.5029 16.8245V16.2491C18.5029 15.8355
                  18.1676 15.5002 17.7541 15.5002ZM11.9999 2.00488C14.7613 2.00488 16.9999 4.24346
                  16.9999 7.00488C16.9999 9.76631 14.7613 12.0049 11.9999 12.0049C9.23845 12.0049
                  6.99988 9.76631 6.99988 7.00488C6.99988 4.24346 9.23845 2.00488 11.9999 2.00488ZM11.9999
                  3.50488C10.0669 3.50488 8.49988 5.07189 8.49988 7.00488C8.49988 8.93788 10.0669 10.5049
                  11.9999 10.5049C13.9329 10.5049 15.4999 8.93788 15.4999 7.00488C15.4999 5.07189 13.9329
                  3.50488 11.9999 3.50488Z"
            />
        </svg>

    );
};

export default PersonIcon;
