import React from 'react';

const ArrowNextIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M18.25 3C18.6297 3 18.9435 3.28215 18.9932 3.64823L19 3.75V20.25C19 20.6642 18.6642 21 18.25
                  21C17.8703 21 17.5565 20.7178 17.5068 20.3518L17.5 20.25V3.75C17.5 3.33579 17.8358 3 18.25 3ZM5.21967
                  3.21967C5.48594 2.9534 5.9026 2.9292 6.19621 3.14705L6.28033 3.21967L14.5303 11.4697C14.7966 11.7359
                  14.8208 12.1526 14.6029 12.4462L14.5303 12.5303L6.28033 20.7803C5.98744 21.0732 5.51256 21.0732 5.21967
                  20.7803C4.9534 20.5141 4.9292 20.0974 5.14705 19.8038L5.21967 19.7197L12.9393 12L5.21967 4.28033C4.92678
                  3.98744 4.92678 3.51256 5.21967 3.21967Z"/>
        </svg>
    );
};

export default ArrowNextIcon;
