import React from 'react';
import {useSelector} from 'react-redux';
import {addDays, withoutTime} from '../../../common/util/DateUtil';
import '../../style/SingleOrderMobile.scss';

function ChildSingleOrderWeekSelection({
                                           startDate,
                                           selectedDay,
                                           setSelectedDay
                                       }) {
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const days = {
        'de-DE': ['Mo', 'Di', 'Mi', 'Do', 'Fr'],
        'en-US': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
    };

    return (
        <div className="row w-100">
            {
                days[currentLanguage].map((day, index) => {
                        const selectedDayClass = withoutTime(selectedDay).getTime() - withoutTime(addDays(startDate, index)).getTime() === 0 ? 'day-selected' : '';
                        return (
                            <div
                                className={`col text-center day-of-week ${selectedDayClass}`}
                                key={index}
                                onClick={() => setSelectedDay(addDays(startDate, index))}>
                                <small>{day}</small>
                                <div
                                    className="day">{addDays(startDate, index).getDate()}</div>
                                <small>{addDays(startDate, index).toLocaleDateString(currentLanguage, {month: 'short'})}</small>
                            </div>
                        );
                    }
                )
            }
        </div>
    );
}

export default ChildSingleOrderWeekSelection;
