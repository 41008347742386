import i18n from 'i18next';
import {Dispatch} from 'redux';
import {getCookieConsentValue} from 'react-cookie-consent';
import transactionApi from '../../administration/api/transactionApi';
import {
    fetchCaterersForSupportUser,
    SetCaterersForSupportUserAction
} from '../../administration/support/redux/supportDataActions';
import {GOOGLE_ANALYTICS_COOKIE} from '../../common/component/consent/Consent';
import {AppThunk} from '../../common/reducer/reducers';
import {denyGA, trackGARole} from '../../common/util/GoogleAnalyticsUtil';
import {AccountType} from '../../types/AccountType';
import {BalanceUpdateType} from '../../types/BalanceUpdateType';
import {OcAxiosResponse} from '../../types/OcAxiosResponse';
import {UserType} from '../../types/UserType';
import {loginApi} from '../api/loginApi';
import {userApi} from '../api/userApi';

export const SET_USER = 'SET_USER';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_BALANCE = 'SET_BALANCE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

interface SetUserAction {
    type: typeof SET_USER;
    user: UserType;
}

interface SetAccountAction {
    type: typeof SET_ACCOUNT;
    account: AccountType;
}

interface SetBalanceAction {
    type: typeof SET_BALANCE;
    updateAmount?: number,
    balance: number;
}

interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE;
    language: string;
}

export type UserActions = SetUserAction | SetAccountAction | SetBalanceAction;

export const login = (username: string, password: string, catererId?: number, onError?: (response: OcAxiosResponse) => void): AppThunk => (dispatch: Dispatch<SetUserAction | ChangeLanguageAction | SetCaterersForSupportUserAction>) => {
    loginApi.login(username, password, catererId).then((response) => {
        if (response.data.success) {
            window.sessionStorage.setItem('api-key', response.data.result.apiKey);
            userApi.findMe().then((res) => {
                setUser(dispatch, res.data.success ? res.data.result : null);
                if (res.data.success && res.data.result.account) {
                    changeLanguage(dispatch, res.data.result.account.language);
                    if (res.data.result.account.login.role === 'SUPPORT') {
                        fetchCaterersForSupportUser(dispatch);
                    }
                }
            });
        } else if (onError) {
            onError(response);
        }
    });
};

export const updateUser = (user: UserType): AppThunk => (dispatch: Dispatch<SetUserAction>) => {
    setUser(dispatch, user);
};

export const updateAccount = (account: AccountType): AppThunk => (dispatch: Dispatch<SetAccountAction>) => {
    dispatch({
        type: SET_ACCOUNT,
        account
    });
};

export const reloadBalance = (): AppThunk => (dispatch: Dispatch<SetBalanceAction>) => {
    transactionApi.findBalance().then(response => {
        if (response.data.success) {
            dispatch({
                type: SET_BALANCE,
                balance: response.data.result
            });
        }
    });
};

export const updateBalance = (balanceUpdate: BalanceUpdateType): AppThunk => (dispatch: Dispatch<SetBalanceAction>) => {
    dispatch({
        type: SET_BALANCE,
        ...balanceUpdate
    });
};

export const checkState = (): AppThunk => (dispatch: Dispatch<SetUserAction | ChangeLanguageAction>) => {
    userApi.findMe().then((response) => {
        setUser(dispatch, response.data.success ? response.data.result : null);
        if (response.data.success && response.data.result.account) {
            changeLanguage(dispatch, response.data.result.account.language);
        }
    });
};

function setUser(dispatch: Dispatch<SetUserAction>, user: UserType) {
    dispatch({
        type: SET_USER,
        user
    });
    if (getCookieConsentValue(GOOGLE_ANALYTICS_COOKIE) === 'true') {
        if (user && user.login)
            trackGARole(user.login.role);
    } else {
        denyGA();
    }
}

function changeLanguage(dispatch: Dispatch<ChangeLanguageAction>, language: string) {
    i18n.changeLanguage(language).then(() => {
        dispatch({
            type: 'CHANGE_LANGUAGE',
            language: language
        });
    });
}
