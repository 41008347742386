const ErrorCode = {
    'NO_DATA_FOUND': {code: 1001},
    'NO_DATA_FOUND_SILENT': {
        code: 1019,
        silent: true
    },
    'MISSING_PARAMETER': {code: 1002},
    'INVALID_PARAMETER': {code: 1003},
    'INVALID_AUTHENTICATION': {
        code: 1004,
        silent: true
    },
    'INTERNAL_SERVER_ERROR': {code: 1006},
    'INVALID_LOGIN_SESSION': {code: 1017},
    'NOT_AUTHORIZED': {code: 1007},
    'VALIDATION_FAILED': {code: 1009},
    'ACCOUNT_NOT_CONFIRMED': {
        code: 1010,
        silent: true
    },
    'BALANCE_INSUFFICIENT': {code: 1011},
    'LOGIN_ALREADY_EXISTS': {
        code: 1012,
        silent: true
    },
    'ACCOUNT_ALREADY_CONFIRMED': {
        code: 1013,
        silent: true
    },
    'TOKEN_MISMATCH': {
        code: 1014,
        silent: true
    },
    'TOKEN_EXPIRED': {
        code: 1015,
        silent: true
    },
    'NOT_REGISTERED_FOR_CATERER': {
        code: 1018,
        silent: true
    },
    'CATERER_CONTEXT_MISSING': {
        code: 1020
    },
    'INVALID_INSTITUTION_CODE': {code: 10002},
    'ORDER_ALREADY_EXISTS': {
        code: 10004,
        silent: true
    },
    'ROLE_CONFLICT': {
        code: 10005,
        silent: true
    },
    'WELFARE_CONFLICT': {
        code: 10006,
        silent: true
    },
    'WELFARE_TASK_UPDATE_NOT_ALLOWED_BECAUSE_MULTIPLE_ORDERS': {
        code: 10104,
        silent: true
    },
    'INVALID_TASK_STATE': {
        code: 10007
    },
    'RFID_VALIDATION_FAILED': {
        code: 10008
    },
    'NUTRITION_CLASS_DELETION_FAILED': {
        code: 10009,
        silent: true
    },
    'STILL_IN_USE': {
        code: 10010,
        silent: true
    },
    'DUPLICATE_KEY': {
        code: 10014,
        silent: true
    },
    'HOSTNAME_ALREADY_EXISTS': {
        code: 10012,
        silent: true
    },
    'LOGIN_TO_CONFIRM_NOT_FOUND': {
        code: 10015,
        silent: true
    },
    'RESEND_MAIL_ERROR': {
        code: 10016,
        silent: true
    },
    'STRIPE_ONBOARDING_ERROR': {
        code: 10017,
        silent: true
    },
    'DAILY_ON_SITE_ORDER_LIMIT_EXCEEDED': {
        code: 10019,
        silent: true
    },
    'CLOSING_TIME_THRESHOLD_VIOLATION': {
        code: 10100
    },
    'CLOSING_TIME_INVALID_CANNOT_CHANGE_TYPE': {
        code: 10101
    },
    'CLOSING_TIME_CANNOT_EDIT_PAST_OR_TODAY': {
        code: 10102
    },
    'WELFARE_UPDATE_NOT_ALLOWED_FOR_BOTH_DATES': {
        code: 10103
    },
    'HOSTNAME_RESERVED': {
        code: 10105,
        silent: true
    }
};

export default ErrorCode;
