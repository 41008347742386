import React, {useState} from 'react';
import '../style/Toggle.scss';

type Props = {
    toggled: boolean,
    label: string,
    onClick: (value: any) => void
}

function Toggle(props: Props) {
    const [isToggled, toggle] = useState(props.toggled);
    const callback = () => {
        toggle(!isToggled);
        props.onClick(!isToggled);
    };

    return (
        <div className="toggle">
            <label>
                <input type="checkbox" defaultChecked={isToggled} onClick={callback}/>
                <span/>
                <strong>{props.label}</strong>
            </label>
        </div>
    );
}

export default Toggle;
