import {Action, combineReducers} from 'redux';
import {ThunkAction} from 'redux-thunk';
import administration from '../../administration/reducer/administrationReducer';
import billingAddress from '../../administration/reducer/billingAddressReducer';
import carrierFilter from '../../administration/reducer/carrierFilterReducer';
import credits from '../../administration/reducer/creditReducer';
import deliveryGroup from '../../administration/reducer/deliveryGroupReducer';
import institutionFilter from '../../administration/reducer/institutionFilterReducer';
import finance from '../../finance/reducer/financeReducer';
import financeConfig from '../../finance/reducer/financeConfigReducer';
import i18n from '../../i18n/reducer/i18nReducer';
import meal from '../../menu/reducer/mealReducer';
import message from '../../message/reducer/messageReducer';
import taskboard from '../../taskboard/reducer/taskReducer';
import user from '../../user/reducer/userReducer';
import caterer from './../../caterer/reducer/catererReducer';
import loader from './loaderReducer';
import pageClick from './pageClickReducer';
import pageLeave from './pageLeaveReducer';
import domainContext from './domainContextReducer';
import supportData from '../../administration/support/redux/supportDataReducer';

export type InitialAction = {
    type: ''
}

const rootReducer = combineReducers({
    i18n,
    loader,
    domainContext,
    user,
    taskboard,
    message,
    administration,
    finance,
    financeConfig,
    credits,
    institutionFilter,
    carrierFilter,
    pageLeave,
    pageClick,
    meal,
    caterer,
    billingAddress,
    deliveryGroup,
    supportData
});

export default rootReducer;
export type DefaultState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, DefaultState, unknown, Action<string>>
