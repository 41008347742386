import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import LoginCredentials from '../../common/component/form/LoginCredentials';
import ErrorCode from '../../common/enums/ErrorCode';
import loginApi from '../../user/api/loginApi';

function AccountConfirmation() {
    const [t] = useTranslation();
    const {search} = useLocation();
    const catererId = useSelector(state => state.caterer.catererId);
    const [confirmationSent, setConfirmationSent] = useState(false);
    const [confirmationSuccess, setConfirmationSuccess] = useState(false);
    const query = new URLSearchParams(search);
    const confirmationToken = query.get('confirmationToken');
    const passwordResetToken = query.get('passwordResetToken');
    const email = query.get('email');
    const [infoTextPart1, setInfoTextPart1] = useState('Confirmation.INFO_ACTIVATION');
    const [infoTextPart2, setInfoTextPart2] = useState('Confirmation.INFO_LOGIN_WITH_MAIL_AND_PASSWORD');
    const [errorCode, setErrorCode] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const [login, setLogin] = useState();
    const [password, setPassword] = useState({
        password: null,
        passwordRepeat: null
    });
    const [isValidated, setIsValidated] = useState(false);
    const [validation, setValidation] = useState({
        password: true,
        passwordRepeat: true
    });

    useEffect(() => {
        if (isValidated) {
            validate();
        }
    }, [password]);

    function sendConfirmation() {
        loginApi.confirmLogin(confirmationToken, email, catererId).then(response => {
            setConfirmationSent(true);
            if (response.data.success) {
                setConfirmationSuccess(true);
                setLogin(response.data.result);
                if (response.data.result && !response.data.result.hasPassword) {
                    setInfoTextPart2('Confirmation.INFO_BEFORE_PASSWORD_RESET');
                }
            } else {
                setErrorCode(response.errorCode ? response.errorCode : null);
                setConfirmationSuccess(false);
            }
        }).catch(() => {
            setConfirmationSuccess(false);
        });
    }

    function handlePasswordChange(field, value) {
        setPassword({
            ...password,
            [field]: value
        });
    }

    function savePassword() {
        if (validate()) {
            loginApi.passwordReset(passwordResetToken, email, password.password, catererId).then((res) => {
                if (res.data.success) {
                    setLogin(res.data.result);
                    setInfoTextPart1('Confirmation.INFO_AFTER_PASSWORD_RESET');
                    setInfoTextPart2('Confirmation.INFO_LOGIN_WITH_MAIL_AND_PASSWORD');
                } else {
                    setConfirmationSuccess(false);
                }
            }).catch(() => {
                setConfirmationSuccess(false);
            });
        }
    }

    function resendConfirmationMail() {
        loginApi.requestConfirmationLink(email, catererId).then((res) => {
            if (res.data.success) {
                setEmailSent(true);
            }
        });
    }

    function validate() {
        const newValidation = {
            password: !!password.password,
            passwordRepeat: !!password.passwordRepeat && password.password === password.passwordRepeat
        };
        setIsValidated(true);
        setValidation(newValidation);

        // Check if password is valid
        return Object.values(newValidation).every(field => !!field);
    }

    function renderSuccess() {
        return (<>
            <div className="zack zack-hooray mx-auto"/>
            <p className="text-center mx-auto mt-5" style={{maxWidth: '1000px'}}>
                {t(infoTextPart1)}
                <br/>
                {t(infoTextPart2)}
            </p>
            <div className="text-center">
                {
                    login?.hasPassword &&
                    <Link to={{pathname: '/', search: `username=${encodeURIComponent(login.emailAddress)}`}} className="btn btn-primary">{t('Confirmation.GO_TO_LOGIN')}</Link>
                }
            </div>
            {
                login && !login.hasPassword &&
                <div className="card">
                    <div className="card-body">
                        <LoginCredentials
                            login={password}
                            onChange={handlePasswordChange}
                            onSubmit={savePassword}
                            validation={validation}
                            showEmail={false}
                        />

                        <div className="button-footer">
                            <button className="btn btn-primary" onClick={savePassword}>{t('Button.SAVE')}</button>
                        </div>
                    </div>
                </div>
            }
        </>);
    }

    function renderFailure() {
        return (
            <>
                {
                    (errorCode === ErrorCode.NOT_REGISTERED_FOR_CATERER.code) &&
                    <div>{t('Error.NOT_REGISTERED_FOR_CATERER')}</div>
                }
                {
                    (errorCode === ErrorCode.ACCOUNT_ALREADY_CONFIRMED.code) &&
                    <Trans i18nKey="Confirmation.ACCOUNT_ALREADY_CONFIRMED"
                           components={{1: <Link to={'/login'}/>}}
                    />
                }
                {
                    (errorCode === ErrorCode.TOKEN_EXPIRED.code) &&
                    <>
                        {
                            !emailSent ?
                                <Trans i18nKey={'Confirmation.TOKEN_EXPIRED'}
                                       components={{1: <a href="#" onClick={resendConfirmationMail}/>}}
                                />
                                :
                                <div>{t('Confirmation.MAIL_SENT')}</div>
                        }
                    </>
                }
                {
                    (errorCode === ErrorCode.TOKEN_MISMATCH.code) &&
                    <>
                        {
                            !emailSent ?
                                <Trans i18nKey={'Confirmation.TOKEN_MISMATCH'}
                                       components={{1: <a href="#" onClick={resendConfirmationMail}/>}}
                                />
                                :
                                <div>{t('Confirmation.MAIL_SENT')}</div>
                        }
                    </>
                }
                {
                    (errorCode === ErrorCode.LOGIN_TO_CONFIRM_NOT_FOUND.code) &&
                    <div>{t('Login.CAN_NOT_CONFIRM_BECAUSE_DELETED', {username: email})}</div>
                }
            </>);
    }

    return (
        <div className="container">
            <h1>{t('Confirmation.TITLE')}</h1>

            {
                !confirmationSent &&
                <div className="text-center mt-5">
                    <p>
                        <Trans i18nKey="Confirmation.INSTRUCTIONS"
                           components={{i: <i/>}}
                           values={{emailAddress: email}}
                        />
                    </p>
                    <button className="btn btn-primary" onClick={sendConfirmation}>
                        {t('Button.CONFIRM')}
                    </button>
                </div>
            }

            {
                confirmationSent && confirmationSuccess && renderSuccess()
            }
            {
                confirmationSent && !confirmationSuccess && renderFailure()
            }
        </div>
    );
}

export default AccountConfirmation;
