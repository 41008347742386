import {httpClient} from '../../common/api/HttpClient';

export const mealPlanApi = {
    createOrUpdateMeal: (order) => httpClient.post('transactions/', order),
    removeMeal: (orderId) => httpClient.delete(`transactions/${orderId}`),
    createOrUpdateGroupOrders: (groupId, orders) => httpClient.post(`transactions/group/${groupId}`, orders),
    findByTimespanAndChildId: (from, to, childId) => httpClient.get(`menus/child/${childId}?startDate=${from}&endDate=${to}`),
    findByTimespanAndGroupId: (from, to, groupId) => httpClient.get(`menus/group/${groupId}?startDate=${from}&endDate=${to}`)
};

export default mealPlanApi;
