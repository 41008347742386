// https://stackoverflow.com/a/59787588
export function flattenObject(obj, prefix = false, result = null) {
    result = result || {};

    // Preserve empty objects and arrays, they are lost otherwise
    if (prefix && typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0) {
        result[prefix] = Array.isArray(obj) ? [] : {};
        return result;
    }

    prefix = prefix ? prefix + '.' : '';

    for (const i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i)) {
            if (typeof obj[i] === 'object' && obj[i] !== null) {
                // Recursion on deeper objects
                flattenObject(obj[i], prefix + i, result);
            } else {
                result[prefix + i] = obj[i];
            }
        }
    }
    return result;
}

export function objectToFilterArray(object) {
    return Object.entries(object).reduce((array, entry) => {
        if (!!entry[1]) {
            array.push({
                id: entry[0],
                value: entry[1]
            });
        }
        return array;
    }, []);
}

export function asString(number) {
    return '' + number;
}
