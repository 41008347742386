import React, {useState} from 'react';
import ConfirmButton from '../../common/component/ConfirmButton';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import {useTranslation} from 'react-i18next';

function SubscriptionFooterRow({
                                   isSubscriptionPresent,
                                   onSave,
                                   cancelLabel,
                                   onRemove
                               }) {
    const [t] = useTranslation();
    const [showCancellationConfirmation, setShowCancellationConfirmation] = useState(false);
    return (
        <>
            <div className="button-footer row">
                <div className="col-12 col-md-4 order-2 order-md-1 text-center text-md-left mt-3 mt-md-0">
                    {
                        isSubscriptionPresent &&
                        <button className="btn btn-light" onClick={() => setShowCancellationConfirmation(true)}>
                            {t('Subscription.CANCEL')}
                        </button>
                    }
                </div>
                <div className="col-12 col-md-8 order-1 order-md-2 text-center text-md-right">
                    <ConfirmButton onClick={onSave} label={t(isSubscriptionPresent ? 'Subscription.UPDATE' : 'Subscription.ORDER')}/>
                </div>
            </div>
            <ConfirmationDialog
                open={showCancellationConfirmation}
                title={t('Subscription.CANCEL')}
                body={cancelLabel}
                confirmLabel={t('Button.YES')}
                cancelLabel={t('Button.NO')}
                onConfirm={() => {
                    onRemove();
                    setShowCancellationConfirmation(false);
                }}
                onCancel={() => setShowCancellationConfirmation(false)}
            />
        </>
    );
}

export default SubscriptionFooterRow;
