import React, {useEffect} from 'react';
import {Nav} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {findAllInstitutions} from '../../../administration/action/administrationActions';
import HomeIcon from '../../icons/HomeIcon';
import PersonIcon from '../../icons/PersonIcon';
import IconNavLink from './IconNavLink';

function NavigationInstitution() {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const institutions = useSelector(state => state.administration.institutions);

    useEffect(() => {
        if (institutions.length === 0) {
            dispatch(findAllInstitutions(false));
        }
    }, [dispatch, institutions.length]);

    return (
        <Nav className="ml-4 ml-lg-0">
            <IconNavLink to="/institutions" icon={<HomeIcon/>}>{t('Navigation.INSTITUTION')}</IconNavLink>
            <IconNavLink to="/contact-profile" icon={<PersonIcon/>}>{t('Navigation.PROFILE')}</IconNavLink>
        </Nav>
    );
}

export default NavigationInstitution;
