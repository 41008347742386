const Permission = {
    WRITE_CATERER: 'WRITE_CATERER',
    READ_ACCOUNT: 'READ_ACCOUNT',
    WRITE_ACCOUNT: 'WRITE_ACCOUNT',
    WRITE_PARENT: 'WRITE_PARENT',
    READ_CARRIER: 'READ_CARRIER',
    READ_INSTITUTION: 'READ_INSTITUTION',
    WRITE_INSTITUTION: 'WRITE_INSTITUTION',
    READ_TASK: 'READ_TASK',
    WRITE_TASK: 'WRITE_TASK',
    WRITE_ANY_CREDIT: 'WRITE_ANY_CREDIT',
    WRITE_CREDIT_FOR_ASSOCIATED_PARENT: 'WRITE_CREDIT_FOR_ASSOCIATED_PARENT',
    WRITE_ASSOCIATED_CHILD_ORDER: 'WRITE_ASSOCIATED_CHILD_ORDER',
    WRITE_ASSOCIATED_GROUP_ORDER: 'WRITE_ASSOCIATED_GROUP_ORDER',
    READ_BUT: 'READ_BUT',
    WRITE_BUT: 'WRITE_BUT',
    READ_DELIVERY_GROUP: 'READ_DELIVERY_GROUP',
    WRITE_DELIVERY_GROUP: 'WRITE_DELIVERY_GROUP',
    READ_PRICE: 'READ_PRICE',
    READ_EMAILS: 'READ_EMAILS'
};
export default Permission;
