import React from 'react';
import Tag from './Tag';

function Diet({selectedItems = []}) {
    if (!selectedItems || !selectedItems.length) {
        return null;
    }

    return (
        <>
            {
                selectedItems.map((item, index) =>
                    <Tag key={index} type="small" className="danger" label={item.name}/>
                )
            }
        </>
    );
}

export default Diet;
