import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {markChanges} from '../../common/action/pageLeaveActions';
import Checkbox from '../../common/component/form/Checkbox';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import WelfareDepartmentType from '../../common/enums/WelfareDepartmentType';
import Icon from '../../common/icons/Icon';
import PlusIcon from '../../common/icons/PlusIcon';
import {checkValidation} from '../../common/util/ValidationUtil';
import {TaskStateEnum} from '../../types/enums/TaskStateEnum';
import '../style/Welfare.scss';
import WelfareEditModal from './WelfareEditModal';
import WelfareList from './WelfareList';

function Welfare({
                     childWelfare,
                     onChange,
                     onValidate,
                     onDelete,
                     showErrors
                 }) {
    const [hasWelfare, setHasWelfare] = useState(childWelfare && childWelfare.length > 0);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCannotDeleteDialog, setShowCannotDeleteDialog] = useState(false);
    const [welfareToEdit, setWelfareToEdit] = useState(null);
    const [tempId, setTempId] = useState(1);
    const [isValid, setIsValid] = useState(true);
    const dispatch = useDispatch();
    const defaultWelfareDepartment = WelfareDepartmentType.LANDRATSAMT;
    const [t] = useTranslation();

    useEffect(() => validate(), [childWelfare, hasWelfare]);

    function handleWelfareChange(welfareToSave) {
        let copyWelfares = childWelfare ? [...childWelfare] : [];

        if (!!welfareToSave.id) {
            copyWelfares = copyWelfares.map(welfare => {
                if (welfare.id === welfareToSave.id) {
                    return welfareToSave;
                } else {
                    return welfare;
                }
            });
        } else if (!!welfareToSave.tempId) {
            copyWelfares = copyWelfares.map(welfare => {
                if (welfare.tempId === welfareToSave.tempId) {
                    return welfareToSave;
                } else {
                    return welfare;
                }
            });
        } else {
            welfareToSave.tempId = tempId;
            copyWelfares.push(welfareToSave);
            setTempId(tempId + 1);
        }

        setWelfareToEdit(null);
        onChange(copyWelfares);
    }

    function editNewWelfare() {
        setWelfareToEdit({welfareDepartment: defaultWelfareDepartment});
    }

    function deleteAllWelfare() {
        for (const welfare of childWelfare) {
            if (onDelete) {
                onDelete(welfare);
            }
        }
        setWelfareToEdit(null);
        setHasWelfare(false);
    }

    function handleSingleDelete(welfare) {
        if (welfare && welfare.tempId) {
            // Delete unsaved welfare from childWelfare
            const indexToDelete = childWelfare.findIndex(cw => !!cw.tempId && cw.tempId === welfare.tempId);
            childWelfare.splice(indexToDelete, 1);

            return;
        }

        if (!welfare || !welfare.id) {
            return;
        }
        if (!childWelfare || childWelfare.length <= 1) {
            setHasWelfare(false);
        }
        if (onDelete) {
            onDelete(welfare);
        }
    }

    function toggleCheckBox(value) {
        if (!value && childWelfare && childWelfare.some(welfare => welfare.task.taskState === TaskStateEnum.ACCEPTED)) {
            setShowCannotDeleteDialog(true);
            return;
        }

        if (!value && childWelfare && childWelfare.some(welfare => welfare.id)) {
            setShowDeleteDialog(true);
            return;
        }

        dispatch(markChanges());
        setHasWelfare(value);
    }

    function validate() {
        // Only check for the length of the array, details have already been checked inside WelfareEditModal.
        const newValidation = {valid: !hasWelfare || (childWelfare && childWelfare.length > 0)};
        setIsValid(checkValidation(newValidation));

        onValidate(newValidation);
    }

    return (
        <>
            <Checkbox
                label={t('ChildSettings.WELFARE_VOUCHER')}
                value={hasWelfare}
                onChange={(value) => toggleCheckBox(value)}
            />
            {
                hasWelfare &&
                <>
                    <p>{t('ChildSettings.WELFARE_EXTRA_INFO')}</p>
                    <WelfareList
                        childWelfare={childWelfare ? childWelfare : []}
                        onDelete={handleSingleDelete}
                        onEdit={setWelfareToEdit}
                    />
                    <div className="text-right mt-1">
                        <button className={`btn btn-primary ${isValid || !showErrors ? '' : 'missing-input'}`} onClick={editNewWelfare}>
                            <Icon src={<PlusIcon/>} className="mr-2"/>
                            {t('Welfare.ADD_APPLICATION')}
                        </button>
                    </div>
                    <WelfareEditModal
                        visible={!!welfareToEdit}
                        welfare={welfareToEdit}
                        onConfirm={(welfare) => handleWelfareChange(welfare)}
                        onCancel={() => setWelfareToEdit(null)}
                    />
                </>
            }
            <ConfirmationDialog
                open={showDeleteDialog}
                title={t('Welfare.DIALOG_TITLE')}
                body={t('Welfare.DELETE_ALL_WELFARE')}
                confirmLabel={t('Button.DELETE')}
                cancelLabel={t('Button.CANCEL')}
                onConfirm={() => {
                    deleteAllWelfare();
                    setShowDeleteDialog(false);
                }}
                onCancel={() => setShowDeleteDialog(false)}
            />
            <ConfirmationDialog
                open={showCannotDeleteDialog}
                title={t('Welfare.CANNOT_DELETE_TITLE')}
                body={t('Welfare.CANNOT_DELETE_INFO')}
                confirmLabel={t('Button.OK')}
                onConfirm={() => {
                    setShowCannotDeleteDialog(false);
                }}
                onCancel={() => setShowCannotDeleteDialog(false)}
                closeable
            />
        </>
    );
}

export default Welfare;
