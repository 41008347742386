import React, {lazy, useCallback, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Icon from '../icons/Icon';
import PreviewIcon from '../icons/PreviewIcon';
import '../style/FilePreview.scss';

const PdfPreview = lazy(() => import('./PdfPreview'));

const FilePreview = ({
                         downloadFunction,
                         id,
                         title,
                         fileName, // Legacy, use title and fileType instead!
                         fileType
                     }) => {
    const [t] = useTranslation();
    const [file, setFile] = useState();
    const [fileOpen, setFileOpen] = useState(false);

    const determineType = useCallback((type, name) => {
        if (type) {
            return type;
        }

        return name.split('.').pop().toLowerCase();
    }, []);

    const downloadDocument = useCallback((documentIdentifier) => {
        downloadFunction(documentIdentifier).then((res) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                if (fileOpen) {
                    setFile(e.target.result);
                }
            };

            if (determineType(fileType, fileName) === 'pdf') {
                reader.readAsArrayBuffer(res.data);
            } else {
                // FileViewer expects a URL
                reader.readAsDataURL(res.data);
            }
        });
    }, [downloadFunction, determineType, fileName, fileType, fileOpen]);

    useEffect(() => {
        if (fileOpen) {
            downloadDocument(id);
        }
    }, [id, fileOpen, downloadDocument]);

    function openFilePreview() {
        setFileOpen(true);
    }

    return (
        <>
            {
                fileOpen &&
                <Modal show={fileOpen} onHide={() => {
                    setFileOpen(false);
                    setFile(null);
                }}>
                    <Modal.Header closeButton onHide={() => setFileOpen(false)}>
                        <Modal.Title>{title || fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-inline-flex justify-content-center align-items-center">
                        <div key={id} className="d-inline-flex justify-content-center align-items-center">
                            {file && fileOpen && determineType(fileType, fileName) !== 'pdf' &&
                                <img className="mw-100" src={file} key={id + 'fw'}/>
                            }
                            {file && fileOpen && determineType(fileType, fileName) === 'pdf' &&
                                <PdfPreview
                                    key={id + 'fw'}
                                    file={file}
                                />
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            }
            <div className="file-preview-icon mr-1 mr-lg-3">
                <Icon src={<PreviewIcon/>}
                      onClick={openFilePreview}
                      className="icon-primary"
                      title={t('FileButton.SHOW_PREVIEW')}
                />
            </div>
        </>
    );
};

export default FilePreview;
