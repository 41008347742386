import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {CHANGE_LANGUAGE, I18nActions, I18nState} from '../action/i18nActions';

export const initialState: I18nState = {
    currentLanguage: 'de-DE',
    currentLocale: 'de',
    languages: [
        {
            value: 'de-DE',
            locale: 'de',
            label: 'Deutsch'
        },
        {
            value: 'en-US',
            locale: 'en',
            label: 'English'
        }
    ]
};

const i18nReducer: Reducer<I18nState, I18nActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    if (action.type === CHANGE_LANGUAGE) {
        return {
            ...state,
            currentLanguage: action.language
        };
    }

    return state;
};

export default i18nReducer;
