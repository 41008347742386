import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';

export const SET_INSTITUTION_FILTER = 'SET_INSTITUTION_FILTER';
export const RESET_INSTITUTION_FILTER = 'RESET_INSTITUTION_FILTER';
export const SET_INSTITUTION_PAGE_INDEX = 'SET_INSTITUTION_PAGE_INDEX';

type SetInstitutionFilterAction = {
    type: typeof SET_INSTITUTION_FILTER
    value: string
}

type ResetInstitutionFilterAction = {
    type: typeof RESET_INSTITUTION_FILTER
}

type SetInstitutionPageIndexAction = {
    type: typeof SET_INSTITUTION_PAGE_INDEX
    pageIndex: number
}

export type InstitutionActions = SetInstitutionFilterAction | ResetInstitutionFilterAction | SetInstitutionPageIndexAction

export const setInstitutionTableFilter = (value: string): AppThunk => (dispatch: Dispatch<SetInstitutionFilterAction>) => {
    dispatch({
        type: SET_INSTITUTION_FILTER,
        value
    });
};

export const resetInstitutionTableFilter = (): AppThunk => (dispatch: Dispatch<ResetInstitutionFilterAction>) => {
    dispatch({
        type: RESET_INSTITUTION_FILTER
    });
};

export const setInstitutionTablePageIndex = (pageIndex: number): AppThunk => (dispatch: Dispatch<SetInstitutionPageIndexAction>) => {
    dispatch({
        type: SET_INSTITUTION_PAGE_INDEX,
        pageIndex
    });
};
