export const emptyAccount = {
    active: false,
    login: {
        active: false,
        emailAddress: null,
        role: 'PARENT',
        permissions: [],
        password: null,
        lastLogin: null,
        status: null
    },
    address: {
        additionalAddressInformation: null,
        city: null,
        houseNumber: null,
        street: null,
        zip: null
    },
    childAccounts: null,
    children: [],
    companyName: null,
    customerNumber: null,
    id: null,
    language: null,
    parentAccount: {
        id: null
    },
    person: {
        fax: null,
        firstName: null,
        lastName: null,
        phone: null,
        salutation: null
    },
    menuGroup: null,
    noLoginDataRequired: false,
    catererId: null,
    balance: null
};

export const initialValidationState = {
    login: {
        emailAddress: true,
        password: true,
        passwordRepeat: true
    },
    companyName: true,
    person: {
        firstName: true,
        lastName: true,
        salutation: true
    },
    address: {
        street: true,
        houseNumber: true,
        zip: true,
        city: true
    },
    institutionType: true,
    catererId: true
};

export const emptyDropdown = {
    label: null,
    value: null
};
