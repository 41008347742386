import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {InstitutionActions, RESET_INSTITUTION_FILTER, SET_INSTITUTION_FILTER, SET_INSTITUTION_PAGE_INDEX} from '../action/institutionFilterActions';

type InstituteState = {
    filter: string | null
    pagination: {
        page: number
    }
}

const initialState = {
    filter: null,
    pagination: {page: 0}
};

const institutionFilterReducer: Reducer<InstituteState, InstitutionActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case RESET_INSTITUTION_FILTER: {
            return {
                ...state,
                filter: null
            };
        }
        case SET_INSTITUTION_FILTER: {
            return {
                ...state,
                filter: action.value
            };
        }

        case SET_INSTITUTION_PAGE_INDEX: {
            if (!action.pageIndex) {
                return {
                    ...state,
                    pagination: initialState.pagination
                };
            }
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.pageIndex
                }
            };
        }
    }
    return state;
};

export default institutionFilterReducer;
