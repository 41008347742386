import React from 'react';
import {useTranslation} from 'react-i18next';
import Tag from '../../../common/component/Tag';
import {TaskStateEnum} from '../../../types/enums/TaskStateEnum';

type Props = {
    taskState: TaskStateEnum
}

const WelfareState = ({taskState} : Props) => {
    const [t] = useTranslation();
    let label = '';
    let className = 'light';

    switch (taskState) {
        case TaskStateEnum.OPEN:
            className = 'warning';
            label = 'OPEN';
            break;
        case TaskStateEnum.IN_PROGRESS:
            className = 'warning';
            label = 'PENDING';
            break;
        case TaskStateEnum.ACCEPTED:
            className = 'success';
            label = 'VERIFIED';
            break;
        case TaskStateEnum.REJECTED:
            className = 'danger';
            label = 'REJECTED';
            break;
    }

    return (
        <Tag type="tiny" className={className} label={t(`WelfareState.${label}`)}/>
    );
};

export default WelfareState;
