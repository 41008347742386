import React from 'react';
import '../style/Loader.scss';

function SuspenseLoader() {
    // ATTENTION do not use redux or i18next here

    return (
        <div className="overlay">
            <div className="loader-container">
                <span className="sr-only">Loading</span>
                <div className="loader-roller">
                    {/* All divs are necessary, each div represents a dot of the loader */}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default SuspenseLoader;
