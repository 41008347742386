import React, {MouseEventHandler, ReactNode} from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import CloseIcon from '../../icons/CloseIcon';
import Icon from '../../icons/Icon';
import '../../style/ConfirmationDialog.scss';
import CancelButton from '../CancelButton';
import ConfirmButton from '../ConfirmButton';

interface Props {
    open?: boolean,
    closeable?: boolean,
    redundantClosing?: boolean,
    title?: string,
    body?: ReactNode,
    onCancel?: () => void,
    onDecline?: () => void,
    onConfirm?: MouseEventHandler<HTMLButtonElement>,
    confirmLabel?: string,
    cancelLabel?: string,
    disabled?: boolean
    newDesign?: boolean
}

export default function ConfirmationDialog(props: Readonly<Props>) {
    const [t] = useTranslation();

    return (
        <Modal show={props.open} className="online-catering d-inline-flex" onHide={props.onCancel}>
            <Modal.Header className="border-0" closeButton={props.closeable} onHide={props.onCancel}>
                <Modal.Title className={props.newDesign ? 'px-4 pt-4' : ''}>{props.title}</Modal.Title>
                {
                    props.newDesign &&
                    <button
                        onClick={() => props.onCancel !== undefined ? props.onCancel() : undefined}
                        type="button"
                        className="btn text-right p-0">
                        <Icon src={<CloseIcon/>} className="mr-2 text-muted"/>
                    </button>
                }
            </Modal.Header>
            <Modal.Body className={props.newDesign ? 'px-4 mx-4' : 'p-4'}>
                <div className="pre-wrap">{props.body}</div>
            </Modal.Body>
            <Modal.Footer className={props.newDesign ? 'border-0 p-4 mx-3' : 'border-0'}>
                {
                    props.closeable && props.redundantClosing &&
                    <CancelButton onClick={() => props.onCancel ? props.onCancel() : undefined}/>
                }
                {
                    ((props.onCancel && !props.closeable) || props.onDecline) &&
                    <CancelButton
                        onClick={() => props.onDecline ? props.onDecline() : (props.onCancel ? props.onCancel() : undefined)}
                        label={props.cancelLabel}
                    />
                }
                {
                    props.onConfirm &&
                    <ConfirmButton onClick={props.onConfirm} label={props.confirmLabel ?? t('Button.OK')} disabled={props.disabled}/>
                }
            </Modal.Footer>
            <ReactTooltip/>
        </Modal>
    );
}
