import {Reducer} from 'react';
import {InitialAction} from '../../common/reducer/reducers';
import {BillingAddressActions, SET_BILLING_ADDRESS_FILTER} from '../action/billingAddressActions';

export type BillingAddressState = {
    filter: string,
}
const initialState: BillingAddressState = {
    filter: ''
};

const billingAddressReducer: Reducer<BillingAddressState, BillingAddressActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    if (action.type === SET_BILLING_ADDRESS_FILTER) {
        return {
            ...state,
            filter: action.filter
        };
    }
    return state;
};

export default billingAddressReducer;
