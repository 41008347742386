import {httpClient} from '../../common/api/HttpClient';

export const ChildApi = {
    createOrUpdate: (child) => httpClient.post('children', child),
    createOrUpdateWelfare: (childWelfare, childId) => httpClient.post(`children/welfare?childId=${childId}`, childWelfare),
    removeChild: (childId) => httpClient.delete(`children/${childId}`),
    removeWelfare: (childId, welfareId) => httpClient.delete(`children/${childId}/welfare/${welfareId}`),
    findChildManagementsByGroupId: (groupId, mealStartDate, mealEndDate) =>
        httpClient.get(`children/group/${groupId}/child-managements?mealStartDate=${mealStartDate}&mealEndDate=${mealEndDate}`),
    createOrUpdateChildManagement: (childMgmt) => httpClient.post('children/child-management', childMgmt),
    findChildManagementByChildId: (childId) => httpClient.get(`children/${childId}/child-management`),
    updateBlockedRfid: (childId, blocked) => httpClient.post(`children/${childId}/rfid/blocked/${blocked}`),
    findById: (childId) => httpClient.get(`children/${childId}`),
    removeChildFromGroup: (childId) => httpClient.delete(`children/group/${childId}`),
    existsRfid: (rfid) => httpClient.get(`children/exists/rfid/${rfid}`)
};

export default ChildApi;
