import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {UserType} from '../../types/UserType';
import {SET_ACCOUNT, SET_BALANCE, SET_USER, UserActions} from '../action/userActions';

type UserStateType = UserType & {
    isLoggedIn: boolean
    checkedState: boolean
};

const initialState: UserStateType = {
    isLoggedIn: false,
    checkedState: false
};

const userReducer: Reducer<UserStateType, UserActions | InitialAction> = (state = initialState, action = {type: ''}): UserStateType => {
    if (action.type === SET_USER) {
        if (!action.user) {
            return {
                ...initialState,
                checkedState: true
            };
        }

        return {
            ...state,
            ...action.user,
            account: {
                ...state.account,
                ...action.user.account
            },
            isLoggedIn: !!action.user.login && !!action.user.login.id,
            checkedState: true
        };
    } else if (action.type === SET_ACCOUNT) {
        // Only change the account inside the user state:
        if (!action.account) {
            return {
                ...initialState,
                checkedState: true
            };
        }

        return {
            ...state,
            account: {
                ...state.account,
                ...action.account ?? {}
            }
        };
    } else if (action.type === SET_BALANCE) {
        return {
            ...state,
            account: {
                ...state.account,
                balance: action.balance
            }
        };
    }

    return state;
};

export default userReducer;
