import {Reducer} from 'redux';
import {NAVIGATE, PageLeaveActions, UNSAVED_CHANGES} from '../action/pageLeaveActions';
import {InitialAction} from './reducers';

type LeavePageState = {
    nextLocation: Location | null
    hasUnsavedChanges: boolean
}
const initialState: LeavePageState = {nextLocation: null, hasUnsavedChanges: false};

const pageLeaveReducer: Reducer<LeavePageState, PageLeaveActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    if (action.type === NAVIGATE) {
        return {
            ...state,
            nextLocation: action.nextLocation,
            hasUnsavedChanges: false
        };
    } else if (action.type === UNSAVED_CHANGES) {
        return {
            ...state,
            hasUnsavedChanges: action.hasUnsavedChanges
        };
    }

    return state;
};

export default pageLeaveReducer;
