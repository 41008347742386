import isEmail from "validator/es/lib/isEmail";
import {flattenObject} from "./ObjectUtil";

export function checkValidation(validation) {
    const flattened = flattenObject(validation);

    return Object.values(flattened).every(field => !!field);
}

export function validateEmailAddress(emailAddress) {
    return emailAddress && isEmail(emailAddress);
}

export function validatePassword(password, passwordRepeat, required) {
    return (!passwordRepeat && !required) || password;
}

export function validatePasswordRepeat(password, passwordRepeat, required) {
    if (required) {
        return !!passwordRepeat && password === passwordRepeat;
    } else {
        return !password || (password === passwordRepeat);
    }
}


