import React from 'react';

const WalletCreditCardIcon = ({size = 1}) => {
    return (
        <svg width={25 * size} height={24 * size} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M14.3557 2.59534C14.4445 2.48261 14.6098 2.46762 14.7175 2.56254L15.6385
                  3.37473L12.7383 7H14.6592L16.7648 4.36797L18.417 5.82489C18.5186 5.9145
                  18.5304 6.06873 18.4435 6.1727L17.7523 7H19.6965C20.1905 6.27893 20.0778 5.28948
                  19.4091 4.69984L15.7096 1.43749C14.9561 0.77305 13.7991 0.877958 13.1775
                  1.66709L8.9762 7H10.8858L14.3557 2.59534ZM16.25 14C15.8358 14 15.5 14.3358 15.5
                  14.75C15.5 15.1642 15.8358 15.5 16.25 15.5H18.25C18.6642 15.5 19 15.1642 19
                  14.75C19 14.3358 18.6642 14 18.25 14H16.25ZM4.5 7.25C4.5 6.83579 4.83579 6.5
                  5.25 6.5H8.37844L9.57 5H5.25C4.00736 5 3 6.00736 3 7.25V17.75C3 19.5449 4.45507
                  21 6.25 21H18.25C20.0449 21 21.5 19.5449 21.5 17.75V11.25C21.5 9.45507 20.0449
                  8 18.25 8H5.25C4.83579 8 4.5 7.66421 4.5 7.25ZM4.5 17.75V9.37197C4.73458 9.45488
                  4.98702 9.5 5.25 9.5H18.25C19.2165 9.5 20 10.2835 20 11.25V17.75C20 18.7165 19.2165
                  19.5 18.25 19.5H6.25C5.2835 19.5 4.5 18.7165 4.5 17.75Z"
            />
        </svg>

    );
};

export default WalletCreditCardIcon;
