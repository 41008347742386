import {httpClient} from '../../common/api/HttpClient';
import {BalanceWithdrawalType} from '../../types/finance/BalanceWithdrawalType';
import {InstantPayoutRequest} from '../../types/finance/InstantPayoutRequest';

export const withdrawalApi = {
    sendRequest: (withdrawal: BalanceWithdrawalType) => {
        return httpClient.post('balance-withdrawals', withdrawal);
    },
    triggerInstantPayout: (instantPayoutRequests: Array<InstantPayoutRequest>) => httpClient.post('balance-withdrawals/instant-payout', instantPayoutRequests),
    requestWithdrawalWithTask: (withdrawal: BalanceWithdrawalType) => httpClient.post('balance-withdrawals/with-task', withdrawal),
    findForAccount: () => {
        return httpClient.get('balance-withdrawals/with-task');
    },
    delete: (withdrawalId: number) => {
        return httpClient.delete(`balance-withdrawals/with-task/${withdrawalId}`);
    }
};

export default withdrawalApi;
