import React, {useState} from 'react';
import Icon from '../../../common/icons/Icon';
import LeftIcon from '../../../common/icons/LeftIcon';
import RightIcon from '../../../common/icons/RightIcon';
import {addDays, isDateEqual} from '../../../common/util/DateUtil';
import '../../style/SingleOrderMobile.scss';
import ChildSingleOrderWeekSelection from './ChildSingleOrderWeekSelection';
import OrderableChildMeal from './OrderableChildMeal';

function ChildSingleOrderMobile({
                                    menuGroups,
                                    nonOrderableMenuGroups,
                                    tabs,
                                    activeTab,
                                    setActiveTab,
                                    startDate,
                                    meals,
                                    onAdd,
                                    onUpdate,
                                    onRemove,
                                    childWelfare
                                }) {

    const [selectedDay, setSelectedDay] = useState(new Date());

    return (
        <div className="mt-4">
            <div className="row no-gutters">
                <div className="col-3 text-right">
                    {
                        activeTab.id !== 0 && <Icon src={<LeftIcon/>}
                                                    className="icon-primary"
                                                    onClick={() => {
                                                        setActiveTab(tabs[activeTab.id - 1]);
                                                        setSelectedDay(addDays(startDate, -7));
                                                    }}
                        />
                    }
                </div>

                <div className="col-6 d-flex flex-row justify-content-center pt-1 tab-label">
                    {activeTab.label}
                </div>

                {
                    activeTab.id !== 2 && <div className="col-3 text-left">
                        <Icon src={<RightIcon/>}
                              className="icon-primary"
                              onClick={() => {
                                  setActiveTab(tabs[activeTab.id + 1]);
                                  setSelectedDay(addDays(startDate, 7));
                              }}
                        />
                    </div>
                }
                <ChildSingleOrderWeekSelection
                    startDate={startDate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                />
                {
                    meals &&
                    menuGroups.map(menuGroup => {
                            return meals.filter(meal => isDateEqual(meal.mealDate, selectedDay)).map(meal => {
                                    let menu = meal.menus.find(menu => menu.menuGroupId === menuGroup.id);
                                    if (!menu) {
                                        menu = {
                                            empty: true,
                                            date: selectedDay
                                        };
                                    }
                                    return <OrderableChildMeal
                                        key={meal.mealDate + menuGroup.id}
                                        meals={meals}
                                        menu={menu}
                                        menuGroup={menuGroup}
                                        showDate={false}
                                        onAdd={(amount) => onAdd({
                                            ...menu,
                                            amount
                                        })}
                                        onUpdate={onUpdate}
                                        onRemove={() => onRemove(menu)}
                                        childWelfare={childWelfare}
                                    />;
                                }
                            );
                        }
                    )
                }
                {
                    nonOrderableMenuGroups?.map(menuGroup => {
                        return meals.filter(meal => isDateEqual(meal.mealDate, selectedDay)).map(meal => {
                            let menu = meal.menus.find(menu => menu.menuGroupId === menuGroup.id);
                            if (menu?.order?.amount > 0) {
                                menu.orderable = false;
                                menu.menuLimit = 1;
                            } else {
                                menu = {
                                    empty: true,
                                    date: selectedDay,
                                    locked: meal.locked,
                                    closingDayFromCaterer: meal.closingDayFromCaterer,
                                    closingDayFromInstitution: meal.closingDayFromInstitution,
                                    closingDayFromGroup: meal.closingDayFromGroup
                                };
                            }

                            return <OrderableChildMeal
                                key={meal.mealDate + menuGroup.id}
                                meals={meals}
                                menu={menu}
                                menuGroup={menuGroup}
                                showDate={false}
                                onRemove={() => onRemove(menu)}
                                childWelfare={childWelfare}
                            />;
                        })
                    })
                }
            </div>
        </div>
    );
}

export default ChildSingleOrderMobile;
