import React from 'react';
import {useTranslation} from 'react-i18next';

export default function Goodbye() {
    const [t] = useTranslation();

    return (
        <div className="container">
            <h1>{t('Goodbye.GOODBYE')}</h1>
            <div>{t('Goodbye.TEXT')}</div>
            <div className="zack zack-spoon"/>
        </div>
    );
}
