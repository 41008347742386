import React from 'react';
import {CookieConsent} from 'react-cookie-consent';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Cookie from '../../../assets/img/cookie.png';
import {DefaultState} from '../../reducer/reducers';
import '../../style/Consent.scss';
import {denyGA, trackGARole} from '../../util/GoogleAnalyticsUtil';

export const GOOGLE_ANALYTICS_COOKIE = 'GOOGLE_ANALYTIC_COOKIE';

function Consent() {

    const [t] = useTranslation();
    const role = useSelector((state: (DefaultState)) => state.user.login ? state.user.login.role : null);

    return (
        <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText={t('Consent.BUTTON_TEXT')}
            declineButtonText={t('Consent.DECLINE_BUTTON_TEXT')}
            cookieName={GOOGLE_ANALYTICS_COOKIE}
            expires={150}
            onAccept={() => trackGARole(role)}
            onDecline={() => denyGA()}

        >
            <img className="cookie-image" src={Cookie}/>
            <span className="consent-title">{t('Consent.TITLE')}</span>
            <p className="consent-text">
                <Trans i18nKey="Consent.TEXT" components={{1: <a href="/privacy-policy" target="_blank"/>}}/>
            </p>
        </CookieConsent>
    );
}

export default Consent;
