import React from 'react';

const ArrowSortUpIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M6.71965 8.71474L11.2144 4.21969C11.4805 3.95361 11.8968 3.9292 12.1904 4.14661L12.2745 4.21909L16.7794
                  8.71415C17.0726 9.00672 17.0731 9.4816 16.7805 9.77481C16.5145 10.0414 16.0979 10.066 15.8041 9.84849L15.7199
                  9.77596L12.497 6.55911L12.4977 19.2549C12.4977 19.6346 12.2155 19.9484 11.8494 19.9981L11.7477 20.0049C11.368
                  20.0049 11.0542 19.7228 11.0045 19.3567L10.9977 19.2549L10.997 6.55711L7.78035 9.77537C7.51409 10.0416 7.09743
                  10.0659 6.80381 9.84802L6.71969 9.7754C6.45341 9.50914 6.42919 9.09248 6.64704 8.79886L6.71965 8.71474L11.2144
                  4.21969L6.71965 8.71474Z"/>
        </svg>
    );
};

export default ArrowSortUpIcon;
