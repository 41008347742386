import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import FoodRestriction from './FoodRestriction';

function FoodRestrictions({
                              allergyRestrictions,
                              handleRestrictionChange,
                              handleValidationChange,
                              group,
                              showErrors,
                              nutritionClassOptions,
                              isNew
                          }) {
    const [t] = useTranslation();
    const [originalAllergyRestrictions] = useState(allergyRestrictions);

    function addRestriction(restriction) {
        const restrictions = [restriction];
        handleRestrictionChange(restrictions);
    }

    function deleteRestriction(restriction) {

        const restrictions = [...allergyRestrictions];
        const idx = restrictions.findIndex(re => re.nutritionClassId === restriction.nutritionClassId);
        if (idx >= 0) {
            restrictions.splice(idx, 1);
            handleRestrictionChange(restrictions);
        }
    }

    function findRestriction(restriction) {
        return allergyRestrictions.find(allergy => allergy.nutritionClassId === restriction.id);
    }

    if (!group) {
        return <>{t('ChildSettings.GROUP_CODE_NEEDED')}</>;
    }

    const showAttestNote = allergyRestrictions.length !== 0 && allergyRestrictions[0].nutritionClass.isAttestRequired
        && (originalAllergyRestrictions.length === 0 || originalAllergyRestrictions[0].nutritionClassId !== allergyRestrictions[0].nutritionClassId);
    const showUnrestrictedNote = (originalAllergyRestrictions.length !== 0 && allergyRestrictions.length === 0)
        || (allergyRestrictions.length !== 0 && !allergyRestrictions[0].nutritionClass.isAttestRequired
            && (originalAllergyRestrictions.length === 0 || originalAllergyRestrictions[0].nutritionClassId !== allergyRestrictions[0].nutritionClassId));

    return (
        <>
            <div className="row">
                {
                    nutritionClassOptions.map((nutritionClass, index) => {
                        return (
                            <FoodRestriction
                                foodRestriction={findRestriction(nutritionClass)}
                                handleRestrictionChange={addRestriction}
                                handleRestrictionDelete={deleteRestriction}
                                handleValidationChange={handleValidationChange}
                                showErrors={showErrors}
                                hasRestriction={allergyRestrictions && allergyRestrictions.length >= 1}
                                key={`foodrestriction-${index}`}
                                nutritionClass={nutritionClass}
                            />
                        );
                    })
                }
            </div>
            {(showAttestNote || (showUnrestrictedNote && !isNew)) &&
            <div className="alert-danger p-2">
                {
                    showAttestNote &&
                    <Trans i18nKey="ChildSettings.NOTE_ATTEST"
                           components={{b: <b/>}}
                    />
                }
                {
                    showUnrestrictedNote &&
                    <Trans i18nKey="ChildSettings.NOTE_UNRESTRICTED"
                           components={{b: <b/>}}
                    />
                }
            </div>
            }
        </>
    );
}

export default FoodRestrictions;
