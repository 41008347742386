import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import accountApi from '../../../account/api/accountApi';
import BackTo from '../../../common/component/BackTo';
import Card from '../../../common/component/Card';
import CheckIcon from '../../../common/icons/CheckIcon';
import Icon from '../../../common/icons/Icon';
import InfoIcon from '../../../common/icons/InfoIcon';
import WalletCreditCardIcon from '../../../common/icons/WalletCreditCardIcon';
import {DefaultState} from '../../../common/reducer/reducers';
import {showMessage} from '../../../message/action/messageActions';
import {BankTransferInstructions} from '../../../types/finance/BankTransferInstructions';
import paymentProviderIntegrationApi from '../../api/paymentProviderIntegrationApi';
import '../../style/StripePaymentCards.scss';
import {
    MIN_PARENT_PAYMENT_WITH_FEE_AMOUNT,
    MIN_PARENT_PAYMENT_NO_FEE_AMOUNT,
    MAX_PARENT_PAYMENT_WITH_FEE_AMOUNT,
    MAX_PARENT_PAYMENT_NO_FEE_AMOUNT,
    MAX_BALANCE
} from '../../util/PaymentAmountLimits';
import DirectDebitDisabledNotice from '../DirectDebitDisabledNotice';
import StripePayment from '../StripePayment';
import StripePaymentCardNoFee from './StripePaymentCardNoFee';
import StripePaymentCardWithFee from './StripePaymentCardWithFee';

loadStripe.setLoadParameters({advancedFraudSignals: false});

function StripePaymentSelection() {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const catererId = useSelector((state: DefaultState) => state.user.login?.catererId);
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const account = useSelector((state: DefaultState) => state.user.account);
    const hasBankTransferApproval = useMemo(() => account && account.bankTransferApproval, [account]);
    const languages = useSelector((state: DefaultState) => state.i18n.languages);
    const currentLocale = languages.find(language => language.value === currentLanguage)?.locale;
    const [isDirectDebitEnabled, setIsDirectDebitEnabled] = useState(true);
    const financeConfig = useSelector((state: DefaultState) => state.financeConfig.financeConfig);
    const [bankTransferInstructions, setBankTransferInstructions] = useState<BankTransferInstructions>();
    const [bankTransferInstructionsLoadingDone, setBankTransferInstructionsLoadingDone] = useState(false);

    const [feeOption, setFeeOption] = useState<string>();
    const [stripeClientSecret, setStripeClientSecret] = useState<string | undefined>();
    const [paymentAmount, setPaymentAmount] = useState<number>();
    const [isValid, setIsValid] = useState(true);
    const formatter = new Intl.NumberFormat(currentLanguage, {
        style: 'currency',
        currency: 'EUR'
    });
    const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) : undefined;

    useEffect(() => {
        if (account) {
            accountApi.fetchPaymentSettings(account.id).then(response => {
                if (response.data.success) {
                    setIsDirectDebitEnabled(response.data.result.isDirectDebitEnabled);
                }
            });
            paymentProviderIntegrationApi.fetchBankTransferInstructions(account.id).then(response => {
                if (response.data.success && response.data.result) {
                    setBankTransferInstructions(response.data.result);
                }
            })
            .finally(() => setBankTransferInstructionsLoadingDone(true));
        }
    }, [account]);

    const NO_FEE = 'no_fee';
    const WITH_FEE = 'with_fee';
    const PAYMENT_OPTIONS = [{
        id: NO_FEE,
        label: 'Finance.PAYMENT_METHODS.NO_FEE',
        descriptions: [
            {label: 'Finance.PAYMENT_METHODS.NO_FEE_DESCRIPTION_1', markAsInfo: false},
            {label: 'Finance.PAYMENT_METHODS.NO_FEE_DESCRIPTION_3', markAsInfo: false},
            {label: 'Finance.PAYMENT_METHODS.NO_FEE_DESCRIPTION_2', markAsInfo: true}
        ]
    }, {
        id: WITH_FEE,
        label: 'Finance.PAYMENT_METHODS.WITH_FEE',
        descriptions: [
            {label: 'Finance.PAYMENT_METHODS.WITH_FEE_DESCRIPTION_2', markAsInfo: false},
            {label: 'Finance.PAYMENT_METHODS.WITH_FEE_DESCRIPTION_3', markAsInfo: false},
            {
                label: `Finance.PAYMENT_METHODS.${financeConfig.FINANCE_FEE_ACTIVE ? 'WITH_FEE' : 'NO_FEE'}_DESCRIPTION_1`,
                markAsInfo: financeConfig.FINANCE_FEE_ACTIVE
            }
        ]
    }];

    const handlePaymentSent = useCallback(() => {
        dispatch(showMessage('Finance.STRIPE_PAYMENT_SENT'));
        setStripeClientSecret(undefined);
        history.push('/finances');
    }, [dispatch, history]);

    function handleCancel(e: FormEvent) {
        e.preventDefault();
        setStripeClientSecret(undefined);
    }

    const determineAmountErrorMessage = useCallback((amount: number) => {
        const minAmount = feeOption !== NO_FEE ? MIN_PARENT_PAYMENT_WITH_FEE_AMOUNT : MIN_PARENT_PAYMENT_NO_FEE_AMOUNT;
        const maxAmount = feeOption !== NO_FEE ? MAX_PARENT_PAYMENT_WITH_FEE_AMOUNT : MAX_PARENT_PAYMENT_NO_FEE_AMOUNT;
        if (!amount || amount < minAmount || amount > maxAmount) {
            return t('Finance.PAYMENT_INSTRUCTIONS', {
                lower: minAmount,
                upper: maxAmount
            });
        } else if (account?.balance && amount && (amount + account.balance > MAX_BALANCE)) {
            return t('Validation.INVALID_BALANCE');
        } else {
            return null;
        }
    }, [account?.balance, feeOption, t]);

    const validate = useCallback((amount: number) => {
        const newIsValid = determineAmountErrorMessage(amount) == null;
        setIsValid(newIsValid);
        return newIsValid;
    }, [determineAmountErrorMessage]);

    function createPaymentIntent(amount: number) {
        if (validate(amount) && account) {
            if (feeOption !== NO_FEE && financeConfig.FINANCE_FEE_ACTIVE) {
                // Only for display in frontend, backend will calculate this separately:
                paymentProviderIntegrationApi.calculateAmountIncludingFee(amount).then(response => {
                    if (response.data.success) {
                        setPaymentAmount(response.data.result);
                    }
                });
            } else {
                setPaymentAmount(amount);
            }

            const paymentIntentRequest = {
                amount,
                isFeePassedToPayer: feeOption !== NO_FEE,
                accountId: account.id
            };
            paymentProviderIntegrationApi.createPaymentIntent(catererId, paymentIntentRequest).then(res => {
                if (res.data.success) {
                    setStripeClientSecret(res.data.result.clientSecret);
                }
            });
        }
    }

    function getCardContentWhenFeeOptionChosen() {
        if (stripeClientSecret && stripePromise && paymentAmount) {
            return <div className="mt-3 form-group">
                <Card className="mt-3 mb-3" size="small">
                    {t('Credit.AMOUNT')}:&nbsp;
                    <span className="bold-primary-text">{formatter.format(paymentAmount)}</span>
                    <span className="float-right bold-primary-text change-button" onClick={handleCancel}>{t('Button.CHANGE')}</span>
                </Card>
                {/* Without passing the client secret also as key, the Elements component won't update when the payment intent/client secret changes.
                I.e. updates of the amount field would not be applied. https://githubhot.com/repo/stripe/react-stripe-js/issues/246 */}
                <Elements stripe={stripePromise} key={stripeClientSecret} options={{
                    locale: currentLocale,
                    clientSecret: stripeClientSecret
                }}>
                    <StripePayment
                        onPaymentSent={handlePaymentSent}
                        onCancel={handleCancel}
                    />
                </Elements>
            </div>;
        }

        switch (feeOption) {
            case NO_FEE:
                return <StripePaymentCardNoFee
                    isValid={isValid}
                    setIsValid={setIsValid}
                    onCreatePaymentIntent={createPaymentIntent}
                    onDetermineErrorMessage={determineAmountErrorMessage}
                    onCancel={() => setFeeOption(undefined)}
                />;
            case WITH_FEE:
                const hintText = t('Finance.GIROPAY_NO_LONGER_AVAILABLE_HINT');
                return <>
                    {
                        hintText !== 'Finance.GIROPAY_NO_LONGER_AVAILABLE_HINT' && hintText !== '__disabled__' &&
                        <div className="alert alert-info pre-wrap font-weight-bold mb-3"><Icon src={<InfoIcon/>} className="mr-2"/>{hintText}</div>
                    }
                    <StripePaymentCardWithFee
                        onDetermineErrorMessage={determineAmountErrorMessage}
                        onCancel={() => setFeeOption(undefined)}
                        isValid={isValid}
                        setIsValid={setIsValid}
                        onCreatePaymentIntent={createPaymentIntent}
                    />
                </>;
            default:
                return;
        }
    }

    const prepareBankTransfer = useCallback(() => {
        if (account) {
            paymentProviderIntegrationApi.prepareAccountForBankTransfer(account.id).then(response => {
                if (response.data.success && response.data.result.iban) {
                    setBankTransferInstructions(response.data.result);
                }
            });
        }
    }, [account]);

    return (
        <div className="container">
            <div className="center-screen">
                <BackTo path="/finances" labelKey="Finance.TITLE"/>
                <h3 className="mt-4 mb-3"><Icon src={<WalletCreditCardIcon size={2}/>}/> {t('Finance.PAYMENT_TITLE')}</h3>
                {
                    feeOption
                    ?
                        <Card title={t(`Finance.PAYMENT_METHODS.${feeOption === NO_FEE ? 'NO_FEE' : 'WITH_FEE'}`)}>
                            {getCardContentWhenFeeOptionChosen()}
                        </Card>
                    :
                        <div className="d-flex flex-wrap">
                            {
                                (hasBankTransferApproval || financeConfig.FINANCE_ALLOW_BANK_TRANSFER_WITHOUT_APPROVAL) &&
                                <Card className="payment-selection-card" title={t('Finance.BANK_TRANSFER_TITLE')}>
                                    {
                                        bankTransferInstructions &&
                                        <span className="pre-wrap">
                                            <Trans i18nKey="Finance.BANK_TRANSFER_INSTRUCTIONS" values={bankTransferInstructions} components={{b: <b/>}}/>
                                        </span>
                                    }
                                    {
                                        !bankTransferInstructions && bankTransferInstructionsLoadingDone &&
                                        <>
                                            <span>{t('Finance.BANK_TRANSFER_INFO1')}</span>
                                            <button className="btn btn-primary btn-block mt-3" onClick={prepareBankTransfer}>
                                                {t('Finance.BANK_TRANSFER_ACTIVATION_BUTTON')}
                                            </button>
                                        </>
                                    }
                                </Card>
                            }
                            {
                                PAYMENT_OPTIONS.map(option => {
                                    if (option.id === NO_FEE) {
                                        if (financeConfig.FINANCE_SEPA_ACTIVE && !isDirectDebitEnabled) {
                                            return <Card className="payment-selection-card" title={t(option.label)} key={`stripe-payment-card-${option.id}`}>
                                                <DirectDebitDisabledNotice/>
                                            </Card>;
                                        } else if (!financeConfig.FINANCE_SEPA_ACTIVE) {
                                            return <Card className="payment-selection-card" title={t(option.label)} key={`stripe-payment-card-${option.id}`}>
                                                {t('Finance.FINANCE_SEPA_INACTIVE')}
                                                <div className="pt-3">
                                                    {financeConfig.FINANCE_FALLBACK_BANK_DATA}
                                                </div>
                                            </Card>;
                                        }
                                    }

                                    return <Card className="payment-selection-card" title={t(option.label)} key={`stripe-payment-card-${option.id}`}>
                                        {
                                            option.descriptions.map(description =>
                                                <div className="mb-2" key={description.label}>
                                                    {
                                                        description.markAsInfo ?
                                                            <Icon src={<InfoIcon/>} className="description-info-icon"/> :
                                                            <Icon src={<CheckIcon/>} className="description-check-icon"/>
                                                    }
                                                    {t(description.label)}
                                                </div>
                                            )
                                        }
                                        <button className="btn btn-primary btn-block mt-3" onClick={() => setFeeOption(option.id)}>
                                            {t('Button.CHOOSE')}
                                        </button>
                                    </Card>;
                                })
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default StripePaymentSelection;
