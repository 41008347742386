import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import accountApi from '../../account/api/accountApi';
import {changeLanguage} from '../../i18n/action/i18nActions';
import '../style/LanguageSelector.scss';

function LanguageSelector() {
    const dispatch = useDispatch();
    const account = useSelector(state => state.user.account);
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const languages = useSelector(state => state.i18n.languages);

    function handleChange(newLanguage) {
        dispatch(changeLanguage(newLanguage));
        if (account?.id) {
            accountApi.updateLanguage(account.id, newLanguage).then();
        }
    }

    return (
        <ul className="language-selector">
            {
                languages.map(language =>
                    language.value !== currentLanguage &&
                    <li key={language.value}>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                handleChange(language.value);
                            }}>{language.label}</a>
                    </li>
                )
            }
        </ul>
    );
}

export default LanguageSelector;
