import React, {KeyboardEventHandler} from 'react';
import '../style/SearchField.scss';
import TextInput from './form/TextInput';

type SearchFieldProps = {
    placeholder?: string
    searchTerm: string | null
    isPrimarySearchIcon?: boolean
    setSearchTerm: (value: string) => void
    onKeyPress?: KeyboardEventHandler<HTMLInputElement>
}

function SearchField(props: Readonly<SearchFieldProps>) {

    return (
        <TextInput
            id="search"
            placeholder={props.placeholder}
            value={props.searchTerm}
            onChange={props.setSearchTerm}
            required={false}
            isSearch={true}
            isPrimarySearchIcon={props.isPrimarySearchIcon}
            onKeyDown={props.onKeyPress}
        />
    );
}

SearchField.defaultProps = {
    isPrimarySearchIcon: false
} as SearchFieldProps;

export default SearchField;
