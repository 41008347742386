import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Card from '../../../common/component/Card';
import TextInput from '../../../common/component/form/TextInput';
import ArrowRightIcon from '../../../common/icons/ArrowRightIcon';
import Icon from '../../../common/icons/Icon';
import '../../style/StripePaymentCardNoFee.scss';
import {DefaultState} from '../../../common/reducer/reducers';
import {convertAmount, currencyFormatter} from '../../../common/util/CurrencyUtil';
import {callOnEnter} from '../../../common/util/FormUtil';

type StripePaymentCardNoFeeProps = {
    onDetermineErrorMessage: (amount: number) => string;
    onCreatePaymentIntent: (amount: number) => void;
    onCancel: () => void;
    setIsValid: (valid: boolean) => void;
    isValid: boolean;
}

function StripePaymentCardNoFee(props: StripePaymentCardNoFeeProps) {

    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const amountFormatter = useMemo(() => currencyFormatter(currentLanguage, 0), [currentLanguage]);
    const [showParticularAmountInput, setShowParticularAmountInput] = useState<boolean>(false);
    const [particularAmountInput, setParticularAmountInput] = useState<string>();
    const PAYMENT_AMOUNT_OPTIONS = [80, 100, 150, 200];

    const confirmParticularAmount = useCallback(() => {
        props.onCreatePaymentIntent(
            // @ts-ignore
            convertAmount(particularAmountInput, currentLanguage));
    }, [particularAmountInput, currentLanguage, props]);

    return <>
        {t('Finance.PAYMENT_METHOD_HINTS.NO_FEE')}
        <div className="row mt-4">
            {
                PAYMENT_AMOUNT_OPTIONS.map(option => <div className="col-12 col-lg-6 mb-2" key={`amount-${option}`}>
                        <Card className="card-btn" title={amountFormatter.format(option)} onClick={() => props.onCreatePaymentIntent(option)}/>
                    </div>)
            }
            {
                showParticularAmountInput ?
                    <>
                        <div className="col-12 col-lg-6">
                            <TextInput
                                type="text"
                                label={t('Finance.PAYMENT_METHOD_NO_FEE_PARTICULAR_AMOUNT')}
                                value={particularAmountInput}
                                onChange={(value) => {
                                    setParticularAmountInput(value);
                                    props.setIsValid(true);
                                }}
                                onKeyDown={event => callOnEnter(event, confirmParticularAmount)}
                                required={true}
                                isValid={props.isValid}
                                // @ts-ignore
                                errorMessage={props.onDetermineErrorMessage(convertAmount(particularAmountInput, currentLanguage))}
                            />
                        </div>
                        <div className="col-12 col-lg-6 align-self-center">
                            <div className="text-right">
                                <button className="btn btn-secondary mr-2" onClick={props.onCancel}>{t('Button.BACK')}</button>
                                <button className="btn btn-primary" onClick={confirmParticularAmount}>
                                    {t('Button.NEXT')}<Icon className="ml-2" src={<ArrowRightIcon/>}/>
                                </button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-12 col-lg-6 mb-4">
                            <Card
                                className="card-btn"
                                title={t('Finance.PAYMENT_METHOD.NO_FEE_PARTICULAR_AMOUNT')}
                                onClick={() => setShowParticularAmountInput(true)}
                            />
                        </div>
                        <div className="col-12">
                            <button className="btn btn-secondary float-right" onClick={props.onCancel}>{t('Button.BACK')}</button>
                        </div>
                    </>
            }
        </div>
    </>;
}

export default StripePaymentCardNoFee;
