import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DefaultState} from '../reducer/reducers';
import '../style/Price.scss';

interface Props {
    price: number | undefined,
    signed?: boolean,
    displayBig?: boolean,
    textRight?: boolean,
    labelLeft?: string | null,
    hasColor?: boolean,
    isThin?: boolean
}

function Price(props: Readonly<Props>) {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const realPrice = useMemo(() => Object.is(props.price, -0) ? 0 : props.price ?? 0, [props.price]);
    const textRight = useMemo(() => props.textRight ?? true, [props.textRight]);
    const hasColor = useMemo(() => props.hasColor ?? true, [props.hasColor]);

    if (!props.price && props.price !== 0) {
        return null;
    }

    const formatter = new Intl.NumberFormat(currentLanguage, {
        style: 'currency',
        currency: 'EUR'
    });
    const sign = realPrice >= 0 ? '+ ' : '';

    const priceColor = hasColor ? (realPrice >= 0 ? 'credit' : 'debit') : '';
    const fontWeight = props.isThin ? 'thin' : 'bold';
    return (
        <>
            {
                props.labelLeft &&
                <div className="mr-1">
                    {t(props.labelLeft)}
                </div>
            }
            <div className={`${props.displayBig ? 'price-big' : 'price-small'} ${textRight ? 'text-right' : ''} price ${priceColor} ${fontWeight} `}>
                <div>
                    {`${props.signed ? sign : ''}`}
                    {formatter.format(realPrice)}
                </div>
            </div>
        </>
    );
}

export default Price;
