import React, {KeyboardEventHandler, useEffect, useRef, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import '../../style/Checkbox.scss';

interface Props {
    value: boolean | null;
    focusOnLoad?: boolean;
    isValid?: boolean;
    isNullable?: boolean;
    isIndeterminate?: boolean,
    onChange: (value: boolean | null) => void;
    required?: boolean;
    id?: string;
    readonly?: boolean;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    label?: string;
    styling?: string;
}

export default function Checkbox(props: Readonly<Props>) {
    const [value, setValue] = useState(props.value);
    const [isValid, setIsValid] = useState(true);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef?.current && props.isIndeterminate) {
            inputRef.current.indeterminate = true;
        } else if (inputRef?.current) {
            inputRef.current.indeterminate = false;
        }
    }, [props.isIndeterminate]);

    useEffect(() => {
        if (props.focusOnLoad) {
            inputRef?.current?.focus();
        }
    }, [props.focusOnLoad]);

    useUpdateEffect(() => {
        validate();
    }, [value, props.isValid]);

    function handleChange() {
        if (props.isNullable) {
            if (value === true) {
                props.onChange(false);
            } else if (value === false) {
                props.onChange(null);
            } else {
                props.onChange(true);
            }
            return;
        }
        // Hand text input value to given onChange method
        if (props.onChange) {
            props.onChange(!value);
        }
    }

    function validate() {
        if (props.isValid === false) {
            setIsValid(false);
        } else {
            const hasValue = props.value !== undefined && props.value !== null;
            setIsValid(!props.required || hasValue);
        }
    }

    useUpdateEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div className={'form-group form-checkbox ' + (props.styling ? props.styling : '')}>
            <>
                <input
                    id={props.id}
                    type="checkbox"
                    checked={props.isNullable ? value !== null : !!value}
                    readOnly={props.readonly}
                    onKeyDown={props.readonly ? undefined : props.onKeyDown}
                    className={`sr-only ${props.readonly ? 'read-only' : ''} ${isValid ? '' : 'is-invalid'}`}
                    onChange={handleChange}
                    ref={inputRef}
                />
                <label htmlFor={props.id}
                       onClick={props.readonly ? undefined : handleChange}
                       className={props.isNullable && props.value === false ? 'is-nullable-false' : ''}>
                    {props.label}
                </label>
            </>
        </div>
    );
}
