import React, {useEffect, useMemo, useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

function PaymentConfirmationPage() {
    const {search} = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const history = useHistory();

    const [t] = useTranslation();

    // Stripe offers these params on the redirect:
    const resultParams = useMemo(() => {
        return {
            paymentIntent: query.get('payment_intent'),
            paymentIntentClientSecret: query.get('payment_intent_client_secret'),
            redirectStatus: query.get('redirect_status')
        }
    }, [query]);

    const timeoutIdRef = useRef();

    useEffect(() => {
        if (history && resultParams?.redirectStatus === 'succeeded') {
            const timeoutId = setTimeout(() => {
                history.push('/')
            }, 7000);

            timeoutIdRef.current = timeoutId;
        }

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [history, resultParams]);

    return (<div className="container">
        <h1>{t('Parent.STRIPE.PAYMENT_CONFIRMATION.TITLE')}</h1>
        {
            resultParams?.redirectStatus === 'succeeded' &&
            <Trans i18nKey="Parent.STRIPE.PAYMENT_SUCCEEDED" components={{1: <a href="/"/>}}/>
        }
        {
            resultParams?.redirectStatus === 'pending' &&
            t('Parent.STRIPE.PAYMENT_PENDING')
        }
        {
            (!resultParams || resultParams.redirectStatus === 'failed') &&
            <>
                <Trans i18nKey="Parent.STRIPE.PAYMENT_FAILED" values={{ paymentId: resultParams.paymentIntent }} components={{1: <b/>}}/>
            </>
        }
        <div className="mt-4">
            <a href='/' className="mr-4">{t('Parent.BACK_TO_OVERVIEW')}</a>
            <a href='/finances'>{t('Parent.BACK_TO_FINANCE_OVERVIEW')}</a>
        </div>
    </div>);
}

export default PaymentConfirmationPage;
