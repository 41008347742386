import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {UPLOAD_IMAGE_TYPES_ALL} from '../../../common/GlobalConstants';
import Icon from '../../../common/icons/Icon';
import UploadIcon from '../../../common/icons/UploadIcon';

function WelfareUploader({
                             fileName,
                             onChange,
                             showErrors,
                             required
                         }) {
    const inputFile = useRef(null);
    const [t] = useTranslation();

    function openFile(e) {
        e.preventDefault();
        inputFile.current.click();
    }

    return (
        <div className="align-items-center mb-3">
            <input
                className="d-none"
                type="file"
                accept={UPLOAD_IMAGE_TYPES_ALL}
                onChange={value => onChange('file', value)}
                required={required}
                ref={inputFile}
            />
            {
                <a href="#" onClick={openFile} className="d-block btn btn-outline-primary mt-3">
                    {
                        fileName ?
                            fileName
                            :
                            <>
                                <Icon src={<UploadIcon/>} className="mr-2"/>
                                {t('Welfare.UPLOAD')}
                            </>
                    }
                </a>
            }
            {
                showErrors && !fileName && required &&
                <div className="small align-bottom text-danger">
                    {t('Welfare.ERROR')}
                </div>
            }
        </div>
    );
}

export default WelfareUploader;
