import React from 'react';

function Information({
                         header,
                         content
                     }) {

    return (
        <div className="container">
            <h1>{header}</h1>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
    );
}

export default Information;
