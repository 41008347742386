import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Week from '../../../common/component/Week';
import '../../style/Subscription.scss';
import subscriptionApi from '../../api/subscriptionApi';
import {formatDate} from '../../../common/util/DateUtil';
import {useSelector} from 'react-redux';

function ChildSubscriptionView({child}) {
    const [t] = useTranslation();
    const [subscription, setSubscription] = useState({});
    const catererBusinessDays = useSelector(state => state.caterer.timeManagementChild?.businessDays);
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);

    useEffect(() => {
        loadSubscription();
    }, []);

    function loadSubscription() {
        subscriptionApi.findByChildId(child.id).then(response => {
            if (response.data.success && response.data.result) {
                setSubscription(response.data.result);
            }
        });
    }

    return (
        <>
            {
                subscription.id &&
                <div className="bg-light p-2">
                    <div className="text-secondary">
                        {t('Subscription.TITLE')}
                    </div>
                    {
                        subscription.startDate && subscription.endDate &&
                        <div
                            className="small text-muted mb-2">{`${formatDate(currentLanguage, new Date(subscription.startDate))} - ${formatDate(currentLanguage, new Date(subscription.endDate))}`}
                        </div>
                    }
                    <div className="text-center">
                        <Week availableDays={catererBusinessDays} selectedDays={subscription.weekdays}/>
                    </div>
                </div>
            }
        </>
    );
}

export default ChildSubscriptionView;
