import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import OcTable from '../../common/component/table/OcTable';
import DeleteIcon from '../../common/icons/DeleteIcon';
import EditIcon from '../../common/icons/EditIcon';
import Icon from '../../common/icons/Icon';
import {formatDateAndTime} from '../../common/util/DateUtil';
import {TaskStateEnum} from '../../types/enums/TaskStateEnum';
import DocumentRow from './welfare/DocumentRow';
import WelfareState from './welfare/WelfareState';

function WelfareList({
                         childWelfare,
                         onDelete,
                         onEdit
                     }) {
    const [t] = useTranslation();
    const [welfareToDelete, setWelfareToDelete] = useState(null);
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);

    const handleEditWelfare = useCallback((value) => {
        if (onEdit) {
            onEdit(value);
        }
    }, [onEdit]);

    const columns = useMemo(() => {
        return [
            {
                Header: t('Welfare.DEPARTMENT'),
                accessor: 'welfareDepartment',
                Cell: cellProps => cellProps.value ? t(`Welfare.${cellProps.value}`) : null
            },
            {
                Header: t('Welfare.VALID_TO'),
                accessor: 'validTo',
                Cell: cellProps => cellProps.value ? formatDateAndTime(cellProps.value, currentLanguage, true, false) : null
            },
            {
                Header: t('Task.STATE'),
                accessor: 'task',
                Cell: cellProps => cellProps.value ? <WelfareState taskState={cellProps.value.taskState}/> : null
            },
            {
                Header: t('Welfare.DOCUMENTS'),
                id: 'actions',
                Cell: cellProps => <DocumentRow
                    taskId={cellProps.cell.row.original.task ? cellProps.cell.row.original.task.id : null}
                    documentToUpload={cellProps.cell.row.original.file}
                />
            },
            {
                id: 'delete',
                Cell: cellProps => {
                    const welfare = cellProps.cell.row.original;
                    return (
                        <div className="text-right mr-2">
                            {
                                welfare.task?.taskState === TaskStateEnum.OPEN &&
                                <Icon src={<EditIcon/>}
                                      onClick={() => handleEditWelfare(welfare)}
                                      className="icon-primary"
                                      title={t('Button.EDIT')}
                                />
                            }
                            {
                                (welfare.task?.taskState === TaskStateEnum.OPEN || welfare.task?.taskState === TaskStateEnum.REJECTED) &&
                                <Icon src={<DeleteIcon/>}
                                      onClick={() => {
                                          setWelfareToDelete(cellProps.cell.row.original);
                                      }}
                                      className="icon-secondary ml-3"
                                      title={t('Button.DELETE')}
                                />
                            }
                        </div>
                    );
                }
            }
        ];
    }, [currentLanguage, handleEditWelfare, t]);

    function handleDeleteWelfare() {
        if (onDelete) {
            onDelete(welfareToDelete);
            setWelfareToDelete(null);
        }
    }

    return (
        <>
            <div className="mt-3">
                <OcTable
                    withinTab={true}
                    data={childWelfare}
                    columns={columns}
                />
            </div>
            <ConfirmationDialog
                open={!!welfareToDelete}
                title={t('Welfare.DIALOG_TITLE')}
                body={t('Welfare.DELETE_SINGLE_WELFARE')}
                confirmLabel={t('Button.DELETE')}
                cancelLabel={t('Button.CANCEL')}
                onConfirm={handleDeleteWelfare}
                onCancel={() => setWelfareToDelete(null)}
            />
        </>
    );
}

export default WelfareList;

