import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Column} from 'react-table';
import billApi from '../../caterer/bills/api/billApi';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import OcTable from '../../common/component/table/OcTable';
import {DefaultState} from '../../common/reducer/reducers';
import {formatDate, formatDateYearAndMonth} from '../../common/util/DateUtil';
import {fileDownload} from '../../common/util/fileDownloadUtil';
import {showMessage} from '../../message/action/messageActions';
import {BillType} from '../../types/BillType';
import BillTableToolCell from './BillTableToolCell';

type Props = {
    accountName: string,
    bills: Array<BillType>,
    onReloadBills?: () => void,
    catererName: string,
    isParentView?: boolean
}

function isCancelBill(billType: string) {
    return billType.indexOf('CANCEL') >= 0;
}

function AccountBillList({ accountName, bills, catererName, isParentView = true, onReloadBills } : Readonly<Props>) {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const [filteredBills, setFilteredBills] = useState<BillType[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>();
    const [billToUpdate, setBillToUpdate] = useState<BillType>();

    const billYears: number[] = useMemo(() => {
        if (bills.length > 0) {
            const years = Array.from(new Set<number>(bills.map(bill => bill.year)));
            return years.sort((a, b) => (a > b ? -1 : 1));
        }
        return [];
    }, [bills]);

    const applyFilter = useCallback((inputYear: number) => {
        setFilteredBills(bills.filter(bill => bill.year === inputYear));
    }, [bills]);

    useEffect(() => {
        if (billYears.length > 0) {
            setSelectedYear(billYears[0]);
        }
    }, [billYears]);

    useEffect(() => {
        if (bills.length > 0 && selectedYear) {
            applyFilter(selectedYear);
        }
    }, [bills, selectedYear, applyFilter]);

    const createBillDisplayName = useCallback((bill) => {
        const prefix = bill.billType === 'PARENT_CANCEL_BILL' || bill.billType === 'BUT_CANCEL_BILL' ?
            t('Finance.CANCEL_BILL')
            : t('Finance.BILL');
        return `${prefix}_${catererName}_${bill.fromDate}_${accountName}.pdf`;
    }, [accountName, catererName, t]);

    const downloadBill = useCallback((id, filename) => {
        fileDownload({
            url: `billing/documents/${id}`,
            fileName: filename
        });
    }, []);

    const columns: Array<Column<BillType>> = useMemo(() => {
        const result = new Array<Column<BillType>>();
        if (!isParentView) {
            result.push({
                Header: t('BillOverview.SERIAL_NUMBER'),
                accessor: 'serialNumber',
                Cell: props => {
                    const isCancel = isCancelBill(props.row.original.billType);
                    return <div className={isCancel ? 'text-secondary' : ''}>{String(props.value).padStart(6, '0')}</div>;
                }
            });
        }

        result.push(
            {
                Header: t('BillOverview.BILLING_MONTH'),
                accessor: 'fromDate',
                Cell: props => formatDateYearAndMonth(props.value, currentLanguage)
            },
            {
                Header: t('Finance.CREATION_DATE'),
                accessor: 'billDate',
                Cell: props => formatDate(currentLanguage, props.value)
            }
        );

        if (!isParentView) {
            result.push({
                Header: t('BillOverview.BILL_TYPE'),
                accessor: 'billType',
                Cell: props => t('BillOverview.BILL_TYPE_' + props.value)
            });
        }

        result.push(
            {
                Header: t('Finance.BILL'),
                accessor: 'id',
                Cell: props => {
                    const name = createBillDisplayName(props.row.original);
                    return (
                        <a href="#"
                           onClick={(e) => {
                               e.preventDefault();
                               downloadBill(props.value, name);
                           }}>
                            {name}
                        </a>
                    );
                }
            },
            {
                id: 'actions',
                disableSortBy: true,
                disableFilters: true,
                accessor: 'id',
                Cell: props => <BillTableToolCell
                    billId={props.value}
                    billDisplayName={createBillDisplayName(props.row.original)}
                    showAdminIcons={!isParentView}
                    row={props.row}
                    onDownloadBill={downloadBill}
                    onSelectBillToUpdate={setBillToUpdate}
                />
            }
        );

        return result;
    }, [createBillDisplayName, downloadBill, isParentView, currentLanguage, t]);

    const updateBill = useCallback((id) => {
        billApi.rebuildBillById(id).then(response => {
            if (response.data.success) {
                const newBill = response.data.result.find((bill: BillType) => !bill.billType.includes('CANCEL'));
                if (newBill) {
                    const billNumber = `${newBill.year}/${newBill.catererDto.invoiceCircle}/${String(newBill.serialNumber).padStart(6, '0')}`;
                    dispatch(showMessage('BillOverview.UPDATE_SUCCESS', {bill: billNumber}));
                    if (onReloadBills) {
                        onReloadBills();
                    }
                } else {
                    dispatch(showMessage('BillOverview.CANCEL_SUCCESS'));
                }
                setBillToUpdate(undefined);
            }
        });
    }, [dispatch, onReloadBills]);

    return <>
        {
            billYears.length > 1 &&
            billYears.map(year => (
                <button
                    className={`btn ${year === selectedYear ? 'btn-primary' : 'btn-secondary'} mr-2`}
                    key={'yearBtn_' + year}
                    onClick={() => {setSelectedYear(year); applyFilter(year); }}>
                    {year}
                </button>
            ))
        }
        <OcTable
            data={filteredBills}
            columns={columns}
        />

        {
            billToUpdate &&
            <ConfirmationDialog
                open={true}
                title={t('BillOverview.CONFIRM_UPDATE_HEADER')}
                body={<Trans i18nKey={`BillOverview.CONFIRM_${billToUpdate.billType}_UPDATE`}
                             values={{
                                 name: billToUpdate.parentName,
                                 month: formatDateYearAndMonth(billToUpdate.fromDate, currentLanguage)
                             }}
                             components={{
                                 1: <br/>,
                                 2: <strong/>
                             }}
                />}
                confirmLabel={t('Button.UPDATE')}
                onConfirm={() => updateBill(billToUpdate.id)}
                onCancel={() => setBillToUpdate(undefined)}
            />
        }
    </>;
}

export default AccountBillList;
