import React, {useEffect, useState} from 'react';
import {Nav} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useHistory, useRouteMatch} from 'react-router-dom';
import {findAllInstitutions} from '../../../administration/action/administrationActions';
import {InstitutionType} from '../../../types/InstitutionType';
import CloseIcon from '../../icons/CloseIcon';
import GabelEinsLogo from '../../icons/GabelEinsLogo';
import {DefaultState} from '../../reducer/reducers';
import {getFileExtension} from '../../util/FileUtil';
import {setFavicon, setTabTitle} from '../../util/HeaderUtil';

interface MatchParams {
    institutionId: string;
}

function HandoutHeader() {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch<MatchParams>();
    const catererLogo = useSelector((state: DefaultState) => state.caterer?.logo);
    const logoExtension = getFileExtension(catererLogo?.data);
    const companyName = useSelector((state: DefaultState) => state.caterer?.catererName);
    const institutions = useSelector((state: DefaultState) => state.administration?.institutions);
    const institutionId = match && match.params && match.params.institutionId ? parseInt(match.params.institutionId) : null;
    const [currentInstitution, setCurrentInstitution] = useState<InstitutionType | undefined>(undefined);
    const catererIcon = useSelector((state: DefaultState) => state.caterer.icon);
    const iconExtension = getFileExtension(catererIcon?.data);

    useEffect(() => {
        setFavicon(catererIcon, iconExtension);
        setTabTitle(currentInstitution?.companyName, 'Gabel1', t('Handout.HANDOUT_TITLE'));
    });

    useEffect(() => {
        if (institutions.length === 0) {
            dispatch(findAllInstitutions(false));
        }
    }, [dispatch, institutions.length]);

    useEffect(() => {
        if (institutionId) {
            const filteredInstitutions = institutions.filter((inst: InstitutionType) => inst.id === institutionId);
            if (filteredInstitutions.length === 0) {
                return;
            }
            setCurrentInstitution(filteredInstitutions[0]);
        }
    }, [institutions, institutionId]);

    function handleCloseHandout() {
        history.goBack();
    }

    return (
        <>
            <header>
                <Navbar id="header" expand="lg">
                    <Navbar.Brand>
                        <NavLink to="/">
                            {
                                catererLogo?.data ?
                                    <span className="caterer-logo">
                                        <img src={`data:image/${logoExtension};base64,${catererLogo.data}`} alt={companyName}/>
                                    </span>
                                    :
                                    <>
                                        {
                                            catererLogo == undefined || catererLogo?.tried &&
                                            <>
                                                <div className="d-none d-md-flex">
                                                    <GabelEinsLogo/>
                                                </div>
                                                <div className="d-flex d-md-none">
                                                    <GabelEinsLogo size={0.75}/>
                                                </div>
                                            </>
                                        }
                                    </>
                            }
                        </NavLink>
                    </Navbar.Brand>
                    {
                        currentInstitution &&
                        <Nav className="ml-5">
                            <h5 className="font-weight-bold">{currentInstitution.companyName}</h5>
                        </Nav>
                    }
                    <Nav id="navigation" className="ml-lg-auto pt-4 pt-lg-0">
                        <a id="icon-nav-link" href="#" onClick={handleCloseHandout}>
                            <div className="nav-link">
                    <span className="btn btn-secondary nav-text ml-4 ml-lg-0">
                        <span className="mr-2">
                            <CloseIcon/>
                        </span>
                        {t('Navigation.HANDOUT_CLOSE')}
                    </span>
                            </div>
                        </a>
                    </Nav>

                </Navbar>
            </header>
        </>
    );
}

export default HandoutHeader;
