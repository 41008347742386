import React, {ReactNode} from 'react';
import '../style/TextOutput.scss';
import InfoTooltip from './InfoTooltip';

interface Prop {
    label: string,
    text: string,
    hint?: string,
    children?: ReactNode,
    className?: string
}

function TextOutput(props: Prop) {
    return (
        <div className="form-group">
            <label>{props.label} {props.hint &&
                <>
                    <InfoTooltip>{props.hint}</InfoTooltip>
                </>}</label>
            <div className={`ml-3 pre-wrap ${props.className ? props.className : ''}`}>{props.text}{props.children}</div>
        </div>
    );
}

export default TextOutput;
