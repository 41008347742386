import {httpClient} from '../../common/api/HttpClient';

export const userActivityApi = {
    findActivitiesForLoginWithPaging: (loginId: number, pageIndex: number, pageSize: number) => httpClient.get(`user-activities/by-login/${loginId}`, {
        params: {
            pageIndex,
            pageSize
        }
    }),
    findActivitiesForLoginWithFilters: (loginId: number, fromDate: string, toDate: string, subject: string) => httpClient.get(`user-activities/by-login/${loginId}`, {
        params: {
            fromDate,
            toDate,
            subject
        }
    }),
    findInstitutionActivities: (institutionId: number, pageIndex: number, pageSize: number) => httpClient.get(`user-activities/by-institution/${institutionId}`, {
        params: {
            pageIndex,
            pageSize
        }
    })
};

export default userActivityApi;
