import React from 'react';
import '../style/CardArrow.scss';

function CardArrow() {
    return (
        <div className="card-arrow">
            <div className="arrow-inner"/>
        </div>
    );
}

export default CardArrow;
