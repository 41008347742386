import FullWeekdayOption from '../enums/FullWeekdayOption';
import {getWeekday} from './DateUtil';

export function isCatererBusinessDay(date: string|Date, catererBusinessDays: Array<string>) {
    return catererBusinessDays.findIndex(businessDay => businessDay === getWeekday(date)) >= 0;
}

export function isCatererBusinessWeekday(weekday: string, catererBusinessDays: Array<string>) {
    return catererBusinessDays.findIndex(businessDay => businessDay === weekday) >= 0;
}

export function combineBusinessDays(businessDaysA: Array<string>, businessDaysB: Array<string>) {
    const result = new Array<string>();
    Object.values(FullWeekdayOption).forEach(weekday => {
        if (businessDaysA.findIndex(dayA => weekday === dayA) >= 0 || businessDaysB.findIndex(dayB => weekday === dayB) >= 0) {
            result.push(weekday);
        }
    });
    return result;
}
