import {Dispatch} from 'redux';
import {AppThunk} from '../../common/reducer/reducers';

export const SET_BILLING_ADDRESS_FILTER = 'SET_BILLING_ADDRESS_FILTER';

interface SetBillingAddressFilterAction {
    type: typeof SET_BILLING_ADDRESS_FILTER;
    filter: string;
}

export type BillingAddressActions = SetBillingAddressFilterAction

export const setBillingAddressFilter = (filter: string): AppThunk =>
    (dispatch: Dispatch<BillingAddressActions>) => {
        dispatch({
            type: SET_BILLING_ADDRESS_FILTER,
            filter
        });
    };
