import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';
import {LabelParameterType, MessageType} from '../../types/MessageType';

export const SHOW_MESSAGE_SECONDS = 10;
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

interface AddMessageAction {
    type: typeof ADD_MESSAGE;
    message: MessageType;
}

interface ClearMessageAction {
    type: typeof CLEAR_MESSAGES;
}

export type MessageActions = AddMessageAction | ClearMessageAction

export const showMessage = (label: string, labelParameters: LabelParameterType = {}, type: 'success' | 'error' = 'success'): AppThunk => (dispatch: Dispatch<AddMessageAction>) => {
    dispatch({
        type: ADD_MESSAGE,
        message: {
            label,
            labelParameters,
            type
        }
    });
};

export const showError = (label: string, error?: unknown, labelParameters: LabelParameterType = {}): AppThunk => (dispatch: Dispatch<AddMessageAction>) => {
    if (error) {
        console.log(error);
    }
    dispatch({
        type: ADD_MESSAGE,
        message: {
            label,
            labelParameters,
            type: 'error'
        }
    });
};

export const clearMessages = (): AppThunk => (dispatch: Dispatch<ClearMessageAction>) => {
    dispatch({
        type: CLEAR_MESSAGES
    });
};
